import React, { useEffect, useState } from "react"
import { create, InstanceProps } from "react-modal-promise"
import Modal from "@/resources/components/modals/modal"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { useDropzone } from "react-dropzone"
import { Button } from "@/resources/components/buttons/button"

type Props = {
  files: File[]
}

const AttachmentModal: React.FC<InstanceProps<unknown> & Props> = (props) => {
  const { onResolve, onReject, isOpen } = props
  const { t } = useTranslation()
  const [files, setFiles] = useState<File[]>([])

  useEffect(() => {
    setFiles(props.files)
  }, [props.files])

  const dropzone = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "video/mp4": [],
      "video/quicktime": [],
    },
    onDropAccepted: (files) => setFiles(files),
    multiple: true,
  })

  const handleResolve = () => {
    onResolve({
      files,
    })
  }

  return (
    <Modal open={isOpen} onClose={onReject}>
      <Modal.Content size={"w-[800px]"}>
        <div className={"flex flex-col gap-y-4"}>
          <div className={"flex w-full flex-row items-center justify-between"}>
            <span className={"text-lg text-neutral-200"}>
              {t("issues.attachments.title")}
            </span>
            <FontAwesomeIcon
              icon={faTimes}
              className={"cursor-pointer text-white"}
              onClick={onReject}
              size={"lg"}
            />
          </div>
          <div className={"w-full"}>
            <div {...dropzone.getRootProps({ className: "dropzone" })}>
              <input {...dropzone.getInputProps()} />
              <p>{t("dropzone.select_files")}</p>
            </div>
          </div>
          <div className={"flex flex-row flex-wrap gap-4"}>
            {files.map((file, index) =>
              file.type.includes("image") ? (
                <img
                  key={index}
                  alt={""}
                  src={URL.createObjectURL(file)}
                  className={"max-h-[200px] max-w-[200px]"}
                />
              ) : (
                <video
                  key={index}
                  className={"max-h-[200px]"}
                  height={200}
                  controls
                  src={URL.createObjectURL(file)}
                  typeof={file.type}
                />
              ),
            )}
          </div>
          <div className={"flex w-full flex-row-reverse gap-x-4"}>
            <Button variant={"contained"} onClick={handleResolve}>
              {t("buttons.save")}
            </Button>
            <Button
              variant={"contained"}
              onClick={onReject}
              color={"bg-gray-700"}
            >
              {t("buttons.close")}
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export const attachmentModal = create(AttachmentModal)
