import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  ChatMessageFilterInterface,
  GetChatMessageFilterResponseInterface,
  GetChatMessageFiltersResponseInterface,
  SaveChatMessageFilterPropsInterface,
  SearchChatMessageFilterInterface,
  SearchMessageFiltersResponseInterface,
} from "@/interfaces/chatMessageFilter"
import { SaveDataWithId } from "@/types/base"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getMessageFilters: builder.query<
      GetChatMessageFiltersResponseInterface["data"],
      string
    >({
      query: (query) => `/api/panel/message-filters${query}`,
      transformResponse: (response: GetChatMessageFiltersResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }) => ({ type: "MessageFilter", id } as const),
              ),
              { type: "MessageFilter", id: "LIST" },
            ]
          : [{ type: "MessageFilter", id: "LIST" }],
    }),
    searchMessageFilters: builder.query<
      SearchChatMessageFilterInterface[],
      void
    >({
      query: () => `/api/panel/message-filters/search`,
      transformResponse: (response: SearchMessageFiltersResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }) => ({ type: "MessageFilter", id } as const),
              ),
              {
                type: "MessageFilter",
                id: "Search",
              },
            ]
          : [{ type: "MessageFilter", id: "Search" }],
    }),
    getMessageFilter: builder.query<ChatMessageFilterInterface, string>({
      query: (id) => `/api/panel/message-filters/${id}`,
      transformResponse: (response: GetChatMessageFilterResponseInterface) =>
        response.data,
      providesTags: (result, error, id) => [{ type: "MessageFilter", id }],
    }),
    storeMessageFilter: builder.mutation<
      void,
      SaveChatMessageFilterPropsInterface
    >({
      query: (data) => ({
        url: "/api/panel/message-filters",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "MessageFilter", id: "LIST" }],
    }),
    updateMessageFilters: builder.mutation<
      void,
      SaveDataWithId<SaveChatMessageFilterPropsInterface>
    >({
      query: ({ id, ...data }) => ({
        url: `/api/panel/message-filters/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "MessageFilter", id },
        { type: "MessageFilter", id: "LIST" },
      ],
    }),
    deleteMessageFilter: builder.mutation<void, string>({
      query: (id) => ({
        url: `/api/panel/message-filters/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "MessageFilter", id: "LIST" }],
    }),
  }),
})

export const {
  useGetMessageFiltersQuery,
  useGetMessageFilterQuery,
  useStoreMessageFilterMutation,
  useUpdateMessageFiltersMutation,
  useDeleteMessageFilterMutation,
  useSearchMessageFiltersQuery,
} = requests
