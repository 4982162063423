import { useNavigate, useParams } from "react-router-dom"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { BreadCrumbInterface } from "@/interfaces/base"
import { useRedirectWithError } from "@/utils/hooks/useRedirectWithError"
import { useTranslation } from "react-i18next"
import { useHasPermission } from "@/utils/hooks/useHasPermission"
import { Button, ButtonLink } from "@/resources/components/buttons/button"
import Card from "@/resources/components/cards/card"
import { planName } from "@/resources/views/plans/parts/planName"
import { toIntl } from "@/utils/toIntl"
import { formatDate } from "@/utils/formatDate"
import { PermissionEnum } from "@/enums/permission"
import { toast } from "react-toastify"
import {
  useDeleteSubscriptionPlanMutation,
  useGetSubscriptionPlanQuery,
} from "@/connectors/fansbay/requests/subscriptionPlanRequests"

export const SubscriptionPlanDetails = () => {
  const { id } = useParams<{ id: string }>()
  const { data, error } = useGetSubscriptionPlanQuery(id!)
  useRedirectWithError(error, "/error")
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const hasPermission = useHasPermission()
  const [deletePlan] = useDeleteSubscriptionPlanMutation()
  const navigate = useNavigate()

  const handleDeletePlan = () => {
    deletePlan(id!)
      .unwrap()
      .then((response) => {
        toast.success(t("subscription_plans.show.deleted"))
        navigate("/subscription-plans")
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      <div className={"flex flex-row-reverse gap-x-4"}>
        {hasPermission(PermissionEnum.SUBSCRIPTION_PLANS_STORE) && (
          <ButtonLink
            variant={"contained"}
            to={`/subscription-plans/${id}/edit`}
          >
            {t("default_plans.show.edit")}
          </ButtonLink>
        )}
        {hasPermission(PermissionEnum.SUBSCRIPTION_PLANS_DESTROY) && (
          <Button
            variant={"contained"}
            onClick={handleDeletePlan}
            className={"bg-red-600"}
          >
            {t("default_plans.show.delete")}
          </Button>
        )}
      </div>
      {data && (
        <Card>
          <div className={"flex flex-col gap-y-4"}>
            <div className={"flex flex-col gap-2"}>
              <span className={"font-semibold"}>{t("labels.plan_name")}</span>
              <span>{planName(t, data?.duration!, data?.duration_unit!)}</span>
            </div>
            <div className={"flex flex-col gap-2"}>
              <span className={"font-semibold"}>{t("labels.username")}</span>
              <span>{data.user.username}</span>
            </div>
            <div className={"flex flex-col gap-2"}>
              <span className={"font-semibold"}>{t("labels.email")}</span>
              <span>{data.user.email}</span>
            </div>
            <div className={"flex flex-col gap-2"}>
              <span className={"font-semibold"}>{t("labels.duration")}</span>
              <span>{data.duration}</span>
            </div>
            <div className={"flex flex-col gap-2"}>
              <span className={"font-semibold"}>
                {t("labels.duration_unit")}
              </span>
              <span>{t(`duration_units.${data.duration_unit}`)}</span>
            </div>
            <div className={"flex flex-col gap-2"}>
              <span className={"font-semibold"}>
                {t("labels.currency_name")}
              </span>
              <span>{data.user.country.currency.name}</span>
            </div>
            <div className={"flex flex-col gap-2"}>
              <span className={"font-semibold"}>
                {t("labels.currency_code")}
              </span>
              <span>{data.user.country.currency.code}</span>
            </div>
            <div className={"flex flex-col gap-2"}>
              <span className={"font-semibold"}>
                {t("labels.currency_multiplier")}
              </span>
              <span>{data.user.country.currency.multiplier}</span>
            </div>
            <div className={"flex flex-col gap-2"}>
              <span className={"font-semibold"}>
                {t("labels.original_price")}
              </span>
              <span>
                {toIntl(
                  data.original_price,
                  data.user.country.currency.code,
                  language,
                )}
              </span>
            </div>
            <div className={"flex flex-col gap-2"}>
              <span className={"font-semibold"}>{t("labels.price")}</span>
              <span>
                {toIntl(data.price, data.user.country.currency.code, language)}
              </span>
            </div>
            <div className={"flex flex-col gap-2"}>
              <span className={"font-semibold"}>{t("labels.created_at")}</span>
              <span>{formatDate(data.created_at)}</span>
            </div>
            <div className={"flex flex-col gap-2"}>
              <span className={"font-semibold"}>{t("labels.updated_at")}</span>
              <span>{formatDate(data.updated_at)}</span>
            </div>
          </div>
        </Card>
      )}
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "subscription_plans.breadcrumbs.subscription_plans",
    url: "/subscription-plans",
  },
  {
    title: "subscription_plans.breadcrumbs.details",
    url: `/subscription-plans/${id}`,
  },
]
