import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  ChangeStatusOfPaymentPropsInterface,
  GetPaymentResponseInterface,
  GetPaymentsResponseInterface,
  PaymentInterface,
} from "@/interfaces/payment"
import { SaveDataWithId } from "@/types/base"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getPayments: builder.query<GetPaymentsResponseInterface["data"], string>({
      query: (query) => `/api/panel/payments${query}`,
      transformResponse: (response: GetPaymentsResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }) => ({ type: "Payment", id } as const),
              ),
              { type: "Payment", id: "LIST" },
            ]
          : [{ type: "Payment", id: "LIST" }],
    }),
    getPayment: builder.query<PaymentInterface, string>({
      query: (id) => `/api/panel/payments/${id}`,
      transformResponse: (response: GetPaymentResponseInterface) =>
        response.data,
      providesTags: (result, error, id) => [{ type: "Payment", id }],
    }),
    changeStatusOfPayment: builder.mutation<
      void,
      SaveDataWithId<ChangeStatusOfPaymentPropsInterface>
    >({
      query: ({ id, ...body }) => ({
        url: `/api/panel/payments/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Payment", id },
        { type: "Payment", id: "LIST" },
      ],
    }),
  }),
})

export const {
  useGetPaymentsQuery,
  useGetPaymentQuery,
  useChangeStatusOfPaymentMutation,
} = requests
