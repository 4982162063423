import { useTranslation } from "react-i18next"
import React, { useMemo, useState } from "react"
import { BreadCrumbInterface } from "@/interfaces/base"
import Table from "@/resources/views/awardedUsers/parts/table"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import Card from "@/resources/components/cards/card"
import { baseQuery } from "@/utils/query"
import { SearchByUser } from "@/resources/components/inputs/search/searchByUser"
import { SearchBy } from "@/resources/views/plans/parts/search/searchBy"
import {
  useDeleteAwardedUserMutation,
  useMoveDownUserMutation,
  useMoveUpUserMutation,
  useStoreAwardedUserMutation,
  useUpdateAwardedUserMutation,
} from "@/connectors/fansbay/requests/awardedUserRequests"
import { toast } from "react-toastify"
import { editAwardedUser } from "@/resources/views/awardedUsers/edit"
import { Container as ModalContainer } from "react-modal-promise"
import { Button } from "@/resources/components/buttons/button"
import { useHasPermission } from "@/utils/hooks/useHasPermission"
import { PermissionEnum } from "@/enums/permission"
import { createAwardedUser } from "@/resources/views/awardedUsers/create"

export const AwardedUserList = () => {
  const { t } = useTranslation()
  const [user, setUser] = useState<string>()
  const [order, setOrder] = useState<string>("")
  const [moveUp] = useMoveUpUserMutation()
  const [moveDown] = useMoveDownUserMutation()
  const [updateAwardedUser] = useUpdateAwardedUserMutation()
  const [storeAwardedUser] = useStoreAwardedUserMutation()
  const [deleteAwardedUser] = useDeleteAwardedUserMutation()
  const hasPermission = useHasPermission()

  const handleEdit = (id: number) => {
    editAwardedUser({
      id: id,
    }).then((props) => {
      updateAwardedUser({
        id: id,
        ...props,
      })
        .unwrap()
        .then(() => toast.success(t("awarded_users.list.updated")))
        .catch(() => toast.warning(t("awarded_users.list.can_not_be_moved")))
    })
  }

  const handleMoveUp = (id: number) => {
    moveUp(id)
      .unwrap()
      .then(() => toast.success(t("awarded_users.list.moved")))
      .catch(() => toast.warning(t("awarded_users.list.can_not_be_moved")))
  }

  const handleMoveDown = (id: number) => {
    moveDown(id)
      .unwrap()
      .then(() => toast.success(t("awarded_users.list.moved")))
      .catch(() => toast.warning(t("awarded_users.list.can_not_be_moved")))
  }

  const handleCreate = () => {
    createAwardedUser().then((props) => {
      storeAwardedUser(props)
        .unwrap()
        .then(() => toast.success(t("awarded_users.list.created")))
        .catch(() => toast.warning(t("messages.user_already_exists")))
    })
  }

  const handleDelete = (id: number) => {
    deleteAwardedUser(id)
      .unwrap()
      .then(() => toast.success(t("awarded_users.list.deleted")))
  }

  const queryBuilder = useMemo(() => {
    return baseQuery.where("user_id", user).where("order", order)
  }, [user, order])

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <div className={"flex flex-row-reverse"}>
        {hasPermission(PermissionEnum.AWARDED_USERS_STORE) && (
          <Button variant={"contained"} onClick={handleCreate}>
            {t("awarded_users.list.create")}
          </Button>
        )}
      </div>
      <Card noPadding>
        <div
          className={
            "border-b border-gray-800 px-6 py-4 text-l font-medium text-neutral-200"
          }
        >
          <span>{t("awarded_users.list.title")}</span>
        </div>
        <div
          className={"flex flex-col gap-y-4 border-b border-gray-800 px-6 py-4"}
        >
          <div className={"grid gap-x-4 lg:w-1/2 lg:grid-cols-2"}>
            <SearchByUser onChange={(user) => setUser(user)} />
            <SearchBy
              onChange={(value) => setOrder(value)}
              placeholder={t("placeholders.order")}
              value={order}
            />
          </div>
        </div>
        <Table
          onEdit={handleEdit}
          onMoveDown={handleMoveDown}
          onMoveUp={handleMoveUp}
          query={queryBuilder}
          onDelete={handleDelete}
        />
      </Card>
      <ModalContainer />
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "modules.breadcrumbs.users",
    url: "#",
  },
  {
    title: "awarded_users.breadcrumbs.awarded_users",
    url: "/awarded-users",
  },
]
