export enum PermissionEnum {
  OWNER = "owner",
  USERS_SHOW = "index.users",
  USERS_STORE = "store.users",
  USERS_DESTROY = "destroy.users",
  PERMISSIONS_SHOW = "index.permissions",
  ROLES_SHOW = "index.roles",
  ROLES_STORE = "store.roles",
  ROLES_DESTROY = "destroy.roles",
  COUNTRIES_SHOW = "index.countries",
  COUNTRIES_STORE = "store.countries",
  COUNTRIES_DESTROY = "destroy.countries",
  DEFAULT_PLANS_SHOW = "index.default-plans",
  DEFAULT_PLANS_STORE = "store.default-plans",
  DEFAULT_PLANS_DESTROY = "destroy.default-plans",
  SUBSCRIPTION_PLANS_SHOW = "index.subscription-plans",
  SUBSCRIPTION_PLANS_STORE = "store.subscription-plans",
  SUBSCRIPTION_PLANS_DESTROY = "destroy.subscription-plans",
  SUBSCRIPTIONS_SHOW = "index.subscriptions",
  SUBSCRIPTIONS_STORE = "store.subscriptions",
  SUBSCRIPTIONS_DESTROY = "destroy.subscriptions",
  SETTINGS = "settings",
  CURRENCIES_SHOW = "index.currencies",
  CURRENCIES_STORE = "store.currencies",
  CURRENCIES_DESTROY = "destroy.currencies",
  EXCHANGE_RATE_SHOW = "index.exchange-rate",
  EXCHANGE_RATE_STORE = "store.exchange-rate",
  EXCHANGE_RATE_DESTROY = "destroy.exchange-rate",
  WALLETS_SHOW = "index.wallets",
  WALLETS_CHARGE = "charge.wallets",
  WITHDRAWS_SHOW = "index.withdraws",
  WITHDRAWS_STORE = "store.withdraws",
  PAYMENT_PROVIDERS_SHOW = "index.payment-providers",
  PAYMENT_PROVIDERS_STORE = "store.payment-providers",
  PAYMENTS_SHOW = "index.payments",
  PAYMENTS_STORE = "store.payments",
  POSTS_SHOW = "index.posts",
  POSTS_DESTROY = "destroy.posts",
  POST_COMMENTS_SHOW = "index.post-comments",
  POST_COMMENTS_DESTROY = "destroy.post-comments",
  PROVISION_RATES_SHOW = "index.provision-rates",
  PROVISION_RATES_STORE = "store.provision-rates",
  PROVISION_RATES_DESTROY = "destroy.provision-rates",
  TAX_RATES_SHOW = "index.tax-rates",
  TAX_RATES_STORE = "store.tax-rates",
  TAX_RATES_DESTROY = "destroy.tax-rates",
  ISSUES_SHOW = "index.issues",
  ISSUES_STORE = "store.issues",
  ISSUES_DESTROY = "destroy.issues",
  ISSUE_MESSAGES_SHOW = "index.issue-messages",
  ISSUE_MESSAGES_STORE = "store.issue-messages",
  ISSUE_MESSAGES_DESTROY = "destroy.issue-messages",
  USER_GROUPS_SHOW = "index.user-groups",
  USER_GROUPS_STORE = "store.user-groups",
  USER_GROUPS_DESTROY = "destroy.user-groups",
  AWARDED_USERS_SHOW = "index.awarded-users",
  AWARDED_USERS_STORE = "store.awarded-users",
  AWARDED_USERS_DESTROY = "destroy.awarded-users",
  USER_VERIFICATIONS_SHOW = "index.user-verifications",
  USER_VERIFICATIONS_STORE = "store.user-verifications",
  MESSAGE_FILTERS_SHOW = "index.message-filters",
  MESSAGE_FILTERS_STORE = "store.message-filters",
  MESSAGE_FILTERS_DESTROY = "destroy.message-filters",
  CHAT_MESSAGES_SHOW = "index.chat-messages",
  PAGES_SHOW = "index.pages",
  PAGE_CONTENTS_STORE = "store.page-contents",
  PAGE_CONTENTS_DESTROY = "destroy.page-contents",
  AGREEMENTS_SHOW = "index.agreements",
  AGREEMENTS_STORE = "store.agreements",
}
