import { FormInterface } from "@/interfaces/base"
import {
  PageContentInterface,
  SavePageContentPropsInterface,
} from "@/interfaces/page"
import { useTranslation } from "react-i18next"
import { useAssignAttributes, useValidation } from "@hypedevgroup/core"
import SavePageContentsValidation from "@/validations/pages/savePageContentsValidation"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, useForm } from "react-hook-form"
import React, { Fragment, useEffect } from "react"
import Card from "@/resources/components/cards/card"
import { Input } from "@/resources/components/inputs/input"
import Select, { Option } from "@/resources/components/inputs/select"
import { Label } from "@/resources/components/inputs/label"
import Editor from "@/resources/components/inputs/editor"
import { FormHelperText } from "@/resources/components/inputs/formHelperText"
import { Button } from "@/resources/components/buttons/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { toast } from "react-toastify"

type Props = {
  onDelete: (id: number) => void
  allowDelete?: boolean
} & FormInterface<SavePageContentPropsInterface>

export const PageForm = ({ onSubmit, onDelete, data, allowDelete }: Props) => {
  const { t } = useTranslation()
  const { schema, defaultValues } = useValidation(SavePageContentsValidation, t)

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
  } = useForm<SavePageContentPropsInterface>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const dataWatch = watch("data")

  useAssignAttributes(setValue, data)

  const handleChange = (
    key: keyof PageContentInterface,
    value: string,
    index: number,
  ) => {
    const contents = getValues("data")
    let content = contents[index]

    Object.assign(content, {
      [key]: value,
    })

    contents[index] = content
    setValue("data", contents)
  }

  const handleAdd = () => {
    const contents = getValues("data")

    contents.push({
      title: "",
      content: "",
      type: "text",
    })

    setValue("data", contents)
  }

  const handleDelete = (index: number) => {
    const contents = getValues("data")

    if (contents[index].id) {
      onDelete(contents[index].id as number)
    } else {
      contents.splice(index, 1)
      setValue("data", contents)
    }
  }

  useEffect(() => {
    if (errors.data) {
      toast.error(errors.data.message)
    }
  }, [errors.data])

  return (
    <div className={"mb-8 flex flex-col"}>
      <div className={"flex flex-row-reverse pb-6"}>
        {allowDelete && (
          <Button variant={"contained"} onClick={handleAdd}>
            {t("buttons.add")}
          </Button>
        )}
      </div>
      <div
        className={
          "chat flex w-full flex-col overflow-y-auto p-4 lg:max-h-[1000px]"
        }
      >
        <Controller
          render={({ field: { value } }) => (
            <Fragment>
              {value.map((item, index) => (
                <Card key={index} className={"relative"}>
                  {allowDelete && (
                    <div
                      className={"absolute -right-2 -top-3"}
                      onClick={() => handleDelete(index)}
                    >
                      <div
                        className={
                          "flex h-6 w-6 items-center justify-center rounded-full bg-red-600"
                        }
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className={"cursor-pointer text-white"}
                        />
                      </div>
                    </div>
                  )}
                  <div className={"flex flex-col gap-y-4"}>
                    <div className={"flex flex-col gap-y-2 lg:max-w-[400px]"}>
                      <div>
                        <Input
                          onChange={(e) =>
                            handleChange("title", e.currentTarget.value, index)
                          }
                          value={item.title}
                          placeholder={t("placeholders.title")}
                          label={t("labels.title")}
                        />
                      </div>
                      <div>
                        <Select
                          onChange={(_, value) =>
                            handleChange("type", value as string, index)
                          }
                          label={t("labels.type")}
                          value={item.type}
                        >
                          <Option value={"text"}>
                            {t(`pages.content_type.text`)}
                          </Option>
                          <Option value={"collapse"}>
                            {t(`pages.content_type.collapse`)}
                          </Option>
                        </Select>
                      </div>
                    </div>
                    <div>
                      <Label label={t("labels.content")} />
                      <Editor
                        value={item.content}
                        onChange={(value) =>
                          handleChange("content", value as string, index)
                        }
                      />
                      <FormHelperText
                        error={!!errors?.data?.[index]?.content}
                        message={errors?.data?.[index]?.content?.message}
                      />
                    </div>
                  </div>
                </Card>
              ))}
            </Fragment>
          )}
          name="data"
          control={control}
        />
      </div>
      {dataWatch.length > 0 && (
        <div className={"pt-4"}>
          <Button variant={"contained"} onClick={handleSubmit(onSubmit)}>
            {t("buttons.save")}
          </Button>
        </div>
      )}
    </div>
  )
}
