import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  AwardedUserInterface,
  GetAwardedUserResponseInterface,
  GetAwardedUsersResponseInterface,
  StoreAwardedUserPropsInterface,
} from "@/interfaces/awardedUser"
import { SaveDataWithId } from "@/types/base"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getAwardedUsers: builder.query<
      GetAwardedUsersResponseInterface["data"],
      string
    >({
      query: (query) => `/api/panel/awarded-users${query}`,
      transformResponse: (response: GetAwardedUsersResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }) => ({ type: "AwardedUser", id } as const),
              ),
              { type: "AwardedUser", id: "LIST" },
            ]
          : [{ type: "AwardedUser", id: "LIST" }],
    }),
    getAwardedUser: builder.query<AwardedUserInterface, string | number>({
      query: (id) => `/api/panel/awarded-users/${id}`,
      transformResponse: (response: GetAwardedUserResponseInterface) =>
        response.data,
      providesTags: (result, error, id) => [{ type: "AwardedUser", id }],
    }),
    storeAwardedUser: builder.mutation<void, StoreAwardedUserPropsInterface>({
      query: (body) => ({
        url: "/api/panel/awarded-users",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "AwardedUser", id: "LIST" }],
    }),
    moveUpUser: builder.mutation<void, number>({
      query: (id) => ({
        url: `/api/panel/awarded-users/${id}/move-up`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, id) => [
        { type: "AwardedUser", id: "LIST" },
        { type: "AwardedUser", id },
      ],
    }),
    moveDownUser: builder.mutation<void, number>({
      query: (id) => ({
        url: `/api/panel/awarded-users/${id}/move-down`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, id) => [
        { type: "AwardedUser", id: "LIST" },
        { type: "AwardedUser", id },
      ],
    }),
    updateAwardedUser: builder.mutation<
      void,
      SaveDataWithId<{ order: number }>
    >({
      query: ({ id, order }) => ({
        url: `/api/panel/awarded-users/${id}`,
        method: "PUT",
        body: {
          order,
        },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "AwardedUser", id: "LIST" },
        { type: "AwardedUser", id },
      ],
    }),
    deleteAwardedUser: builder.mutation<void, string | number>({
      query: (id) => ({
        method: "DELETE",
        url: `/api/panel/awarded-users/${id}`,
      }),
      invalidatesTags: [{ type: "AwardedUser", id: "LIST" }],
    }),
  }),
})

export const {
  useGetAwardedUserQuery,
  useGetAwardedUsersQuery,
  useStoreAwardedUserMutation,
  useUpdateAwardedUserMutation,
  useMoveDownUserMutation,
  useMoveUpUserMutation,
  useDeleteAwardedUserMutation,
} = requests
