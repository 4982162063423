import React, { useEffect, useMemo, useState } from "react"
import BaseTable from "@/resources/components/table/table"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { TFunction } from "i18next"
import RowAction from "@/resources/components/table/rowAction"
import { format } from "date-fns"
import { ColumnSetting } from "@/resources/components/table/columnSetting"
import { usePagination } from "@hypedevgroup/core"
import { baseQuery } from "@/utils/query"
import { UserGroupInterface } from "@/interfaces/userGroup"
import { useGetUserGroupsQuery } from "@/connectors/fansbay/requests/userGroupRequests"

const columnHelper = createColumnHelper<UserGroupInterface>()

const columns = (t: TFunction, locale: string) => [
  columnHelper.accessor("name", {
    header: t("labels.name"),
    id: "name",
  }),
  columnHelper.accessor("filters", {
    header: t("labels.filters_count"),
    id: "filters",
    cell: (cell) => cell.getValue().length,
  }),
  columnHelper.accessor("created_at", {
    header: t("labels.created_at"),
    cell: (row) => format(new Date(row.getValue()), "dd.MM.yyyy HH:mm"),
  }),
  columnHelper.display({
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => <RowAction url={`/user-groups/${row.original.id}`} />,
  }),
]

const Table = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const [data, setData] = useState<UserGroupInterface[]>([])
  const [pagination, setPagination] = usePagination()

  const query = useMemo(
    () => baseQuery.limit(pagination.pageSize).page(pagination.pageIndex),
    [pagination],
  )
  const { data: apiData } = useGetUserGroupsQuery(query.url())

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const table = useReactTable({
    columns: columns(t, language),
    data: data,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  return (
    <div className={"flex flex-col"}>
      <div className={"flex flex-row-reverse px-8 py-4"}>
        <ColumnSetting table={table} />
      </div>
      <BaseTable pagination={apiData?.pagination} table={table} />
    </div>
  )
}

export default Table
