import { useTranslation } from "react-i18next"
import { useMemo, useState } from "react"
import { BreadCrumbInterface } from "@/interfaces/base"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import Card from "@/resources/components/cards/card"
import { baseQuery } from "@/utils/query"
import Search from "@/resources/components/inputs/search"
import Table from "@/resources/views/posts/parts/table"

export const PostList = () => {
  const { t } = useTranslation()
  const [query, setQuery] = useState<string>()

  const queryBuilder = useMemo(() => {
    return baseQuery.where("search", query)
  }, [query])

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <Card noPadding>
        <div
          className={
            "border-b border-gray-800 px-6 py-4 text-l font-medium text-neutral-200"
          }
        >
          <span>{t("posts.list.title")}</span>
        </div>
        <div
          className={"flex flex-col gap-y-4 border-b border-gray-800 px-6 py-4"}
        >
          <div className={"flex w-full gap-x-4"}>
            <Search onChange={(value) => setQuery(value)} />
          </div>
        </div>
        <Table query={queryBuilder} />
      </Card>
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "modules.breadcrumbs.content",
    url: "#",
  },
  {
    title: "posts.breadcrumbs.posts",
    url: "/posts",
  },
]
