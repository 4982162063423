import { TFunction } from "i18next"
import * as yup from "yup"
import { SaveExchangeRatePropsInterface } from "@/interfaces/exchangeRate"

export default class SaveExchangeRateValidation {
  static rules(
    t: TFunction,
  ): yup.ObjectSchema<SaveExchangeRatePropsInterface, yup.AnyObject> {
    return yup.object().shape({
      from_id: yup
        .number()
        .required(t("validations.field_is_required"))
        .notOneOf([0], t("validations.field_is_required")),
      to_id: yup
        .number()
        .required(t("validations.field_is_required"))
        .notOneOf([0], t("validations.field_is_required"))
        .notOneOf(
          [yup.ref("from_id")],
          t("validations.currency_must_be_different"),
        ),
      rate: yup
        .number()
        .typeError(t("validations.field_is_required"))
        .required(t("validations.field_is_required"))
        .min(0, t("validations.min_value", { value: 0 })),
    })
  }

  static defaultValues(): SaveExchangeRatePropsInterface {
    return {
      from_id: 0,
      to_id: 0,
      rate: "",
    }
  }
}
