import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  DefaultPlanInterface,
  GetDefaultPlanResponseInterface,
  GetDefaultPlansResponseInterface,
  SaveDefaultPlanInterface,
} from "@/interfaces/defaultPlan"
import { SaveDataWithId } from "@/types/base"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getPlans: builder.query<GetDefaultPlansResponseInterface["data"], string>({
      query: (params) => `/api/panel/default-plans${params}`,
      transformResponse: (response: GetDefaultPlansResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }) => ({ type: "DefaultPlan", id } as const),
              ),
              { type: "DefaultPlan", id: "LIST" },
            ]
          : [{ type: "DefaultPlan", id: "LIST" }],
    }),
    getPlan: builder.query<DefaultPlanInterface, string>({
      query: (id) => `/api/panel/default-plans/${id}`,
      transformResponse: (response: GetDefaultPlanResponseInterface) =>
        response.data,
      providesTags: (result, error, id) => [{ type: "DefaultPlan", id: id }],
    }),
    storePlan: builder.mutation<void, SaveDefaultPlanInterface>({
      query: (body) => ({
        url: "/api/panel/default-plans",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "DefaultPlan", id: "LIST" }],
    }),
    updatePlan: builder.mutation<
      void,
      SaveDataWithId<SaveDefaultPlanInterface>
    >({
      query: ({ id, ...body }) => ({
        url: `/api/panel/default-plans/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (response, error, { id }) => [
        { type: "DefaultPlan", id: "LIST" },
        { type: "DefaultPlan", id },
      ],
    }),
    deletePlan: builder.mutation<void, string>({
      query: (id) => ({
        url: `/api/panel/default-plans/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "DefaultPlan", id: "LIST" }],
    }),
  }),
})

export const {
  useDeletePlanMutation,
  useGetPlanQuery,
  useGetPlansQuery,
  useStorePlanMutation,
  useUpdatePlanMutation,
} = requests
