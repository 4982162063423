import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  GetPaymentProviderResponseInterface,
  GetPaymentProvidersResponseInterface,
  PaymentProviderInterface,
  SearchPaymentProvider,
  SearchPaymentProviderResponseInterface,
  UpdatePaymentProviderPropsInterface,
} from "@/interfaces/paymentProvider"
import { SaveDataWithId } from "@/types/base"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentProviders: builder.query<
      GetPaymentProvidersResponseInterface["data"],
      string
    >({
      query: (query) => `/api/panel/payment-providers${query}`,
      transformResponse: (response: GetPaymentProvidersResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }) => ({ type: "PaymentProvider", id } as const),
              ),
              {
                type: "PaymentProvider",
                id: "LIST",
              },
            ]
          : [
              {
                type: "PaymentProvider",
                id: "LIST",
              },
            ],
    }),
    getPaymentProvider: builder.query<PaymentProviderInterface, string>({
      query: (id) => `/api/panel/payment-providers/${id}`,
      transformResponse: (response: GetPaymentProviderResponseInterface) =>
        response.data,
      providesTags: (result, error, id) => [
        { type: "PaymentProvider", id: id },
      ],
    }),
    searchPaymentProviders: builder.query<SearchPaymentProvider[], void>({
      query: (id) => "/api/panel/payment-providers/search",
      transformResponse: (response: SearchPaymentProviderResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }) => ({ type: "PaymentProvider", id } as const),
              ),
              { type: "PaymentProvider", id: "SEARCH" },
            ]
          : [{ type: "PaymentProvider", id: "SEARCH" }],
    }),
    updatePaymentProvider: builder.mutation<
      void,
      SaveDataWithId<UpdatePaymentProviderPropsInterface>
    >({
      query: ({ id, ...body }) => ({
        url: `/api/panel/payment-providers/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "PaymentProvider", id: "LIST" },
        { type: "PaymentProvider", id },
      ],
    }),
  }),
})

export const {
  useGetPaymentProvidersQuery,
  useGetPaymentProviderQuery,
  useUpdatePaymentProviderMutation,
  useSearchPaymentProvidersQuery,
} = requests
