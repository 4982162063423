import { useTranslation } from "react-i18next"
import React, { useEffect, useMemo, useState } from "react"
import { IssueMessageInterface } from "@/interfaces/issueMessage"
import InfiniteScroll from "react-infinite-scroll-component"
import {
  useGetIssueMessagesQuery,
  useSendMessageMutation,
} from "@/connectors/fansbay/requests/issueMessageRequests"
import { usePagination } from "@hypedevgroup/core"
import { baseQuery } from "@/utils/query"
import { IssueMessage } from "@/resources/views/issues/issueMessages/parts/message"
import { Input } from "@/resources/components/inputs/input"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFile, faPlay } from "@fortawesome/free-solid-svg-icons"
import { Container as ModalContainer } from "react-modal-promise"
import { attachmentModal } from "@/resources/views/issues/issueMessages/parts/attachments"
import { toast } from "react-toastify"
import _ from "lodash"
import { Loading } from "@/resources/components/loading"
import { useHasPermission } from "@/utils/hooks/useHasPermission"
import { PermissionEnum } from "@/enums/permission"

type Props = {
  issueId: string
  authorId: number
}

export const IssueMessages = (props: Props) => {
  const { t } = useTranslation()
  const [messages, setMessages] = useState<IssueMessageInterface[]>([])
  const [pagination, setPagination] = usePagination()
  const [hasMore, setHasMore] = useState<boolean>(false)
  const [message, setMessage] = useState<string>("")
  const [files, setFiles] = useState<File[]>([])
  const [storeMessage, { isLoading }] = useSendMessageMutation()
  const hasPermission = useHasPermission()

  const query = useMemo(() => {
    return baseQuery.limit(pagination.pageSize).page(pagination.pageIndex)
  }, [pagination])

  const { data } = useGetIssueMessagesQuery({
    issue_id: props.issueId,
    query: query.url(),
  })

  useEffect(() => {
    if (data) {
      setMessages((messages) =>
        _.sortBy(_.uniqBy([...messages, ...data.data], "id"), "id").reverse(),
      )

      if (data.pagination.total_pages !== pagination.pageIndex) {
        setHasMore(true)
      } else {
        setHasMore(false)
      }
    }
  }, [data, pagination.pageIndex])

  const fetchMoreMessages = () => {
    setPagination((prevState) => ({
      ...prevState,
      pageIndex: prevState.pageIndex + 1,
    }))
  }

  const onAttachmentsClick = () => {
    attachmentModal({
      files: files,
    }).then((value: { files: Array<File> }) => {
      setFiles(value.files)
    })
  }

  const handleSendMessage = () => {
    const data = new FormData()

    if (files.length === 0 && message.length === 0) {
      toast.error(t("messages.file_or_message_empty"))
      return
    }

    if (files.length > 0) {
      files.forEach((file) => data.append("attachments[]", file, file.name))
    }

    if (message.length > 0) {
      data.append("content", message)
    }

    storeMessage({
      issue_id: props.issueId,
      data: data,
    })
      .unwrap()
      .then(() => {
        setFiles([])
        setMessage("")
        toast.success(t("messages.message_send"))
      })
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault()
      handleSendMessage()
    }
  }

  return (
    <div className={"flex flex-col gap-y-4"}>
      <div
        className={
          "chat flex h-[800px] w-full flex-col-reverse overflow-auto scroll-smooth"
        }
        id={"scrollableDiv"}
      >
        <InfiniteScroll
          next={fetchMoreMessages}
          inverse
          hasMore={hasMore}
          loader={<div>Loading...</div>}
          dataLength={messages.length}
          scrollableTarget={"scrollableDiv"}
          className={"flex flex-col-reverse gap-y-5"}
        >
          <div className={"flex flex-col-reverse gap-y-4"}>
            {messages.map((message, index) => (
              <IssueMessage
                key={index}
                message={message}
                authorId={props.authorId}
              />
            ))}
          </div>
        </InfiniteScroll>
      </div>
      {hasPermission(PermissionEnum.ISSUE_MESSAGES_STORE) && (
        <div
          className={
            "flex w-full flex-row items-center gap-8 rounded-md border border-gray-800 bg-gray-900 px-4 py-4"
          }
        >
          <Input
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder={t("placeholders.enter_message")}
          />
          <FontAwesomeIcon
            icon={faFile}
            className={"cursor-pointer text-neutral-200"}
            size={"lg"}
            onClick={onAttachmentsClick}
          />
          {(message || files.length > 0) && (
            <FontAwesomeIcon
              onClick={() => handleSendMessage()}
              icon={faPlay}
              className={"cursor-pointer text-neutral-200"}
            />
          )}
        </div>
      )}
      <ModalContainer />
      <Loading isLoading={isLoading} />
    </div>
  )
}
