import React, { useEffect, useState } from "react"
import BaseTable from "@/resources/components/table/table"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { TFunction } from "i18next"
import { ColumnSetting } from "@/resources/components/table/columnSetting"
import RowAction from "@/resources/components/table/rowAction"
import { IndexPageInterface } from "@/interfaces/page"
import { useGetPagesQuery } from "@/connectors/fansbay/requests/pageRequests"

const columnHelper = createColumnHelper<IndexPageInterface>()

const columns = (t: TFunction) => [
  columnHelper.accessor("name", {
    id: "name",
    header: t("labels.name"),
    cell: (cell) => <span>{t(`pages.name.${cell.getValue()}`)}</span>,
  }),
  columnHelper.display({
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => <RowAction url={`/pages/${row.original.id}/edit`} />,
  }),
]

const Table = () => {
  const { t } = useTranslation()
  const [data, setData] = useState<IndexPageInterface[]>([])
  const { data: apiData } = useGetPagesQuery()

  useEffect(() => {
    if (apiData) {
      setData(apiData)
    }
  }, [apiData])

  const table = useReactTable({
    columns: columns(t),
    data: data,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  return (
    <div className={"flex flex-col"}>
      <div className={"flex flex-row-reverse px-8 py-4"}>
        <ColumnSetting table={table} />
      </div>
      <BaseTable table={table} />
    </div>
  )
}

export default Table
