import { TFunction } from "i18next"
import * as yup from "yup"
import { UpdateSubscriptionPropsInterface } from "@/interfaces/subscription"
import { SubscriptionStatusEnum } from "@/enums/subscriptionStatus"

export default class UpdateSubscriptionValidation {
  static rules(
    t: TFunction,
  ): yup.ObjectSchema<UpdateSubscriptionPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      duration: yup
        .number()
        .typeError(t("validations.field_is_required"))
        .required(t("validations.field_is_required"))
        .min(1, t("validations.min_value", { value: 1 })),
      current_period_start: yup
        .date()
        .typeError(t("validations.field_is_required"))
        .required(t("validations.field_is_required"))
        .max(
          yup.ref("current_period_end"),
          t("validations.date_cannot_be_later_than", {
            field: t("labels.current_period_end"),
          }),
        ),
      current_period_end: yup
        .date()
        .typeError(t("validations.field_is_required"))
        .required(t("validations.field_is_required"))
        .min(
          yup.ref("current_period_start"),
          t("validations.date_cannot_be_earlier_than", {
            field: t("labels.current_period_start"),
          }),
        ),
      status: yup
        .mixed<SubscriptionStatusEnum>()
        .required(t("validations.field_is_required")),
    })
  }

  static defaultValues(): UpdateSubscriptionPropsInterface {
    return {
      current_period_end: "",
      current_period_start: "",
      duration: "",
      status: SubscriptionStatusEnum.ACTIVE,
    }
  }
}
