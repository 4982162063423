import Select, { Option } from "@/resources/components/inputs/select"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { useSearchRolesQuery } from "@/connectors/fansbay/requests/roleRequests"

type Props = {
  onChange: (value?: string) => void
}

const SearchByRole = (props: Props) => {
  const { onChange } = props
  const { t } = useTranslation()
  const { data } = useSearchRolesQuery()

  const _onRoleChange = (value?: string) => {
    if (value === "0") {
      onChange(undefined)
    } else {
      onChange(value)
    }
  }

  const onRoleChange = _.debounce(_onRoleChange, 500)

  return (
    <Select
      defaultValue={0}
      onChange={(_, value) => onRoleChange(value as string)}
    >
      <Option value={0}>{t("labels.role")}</Option>
      {data?.map((role) => (
        <Option value={role.id} key={role.id}>
          {role.name}
        </Option>
      ))}
    </Select>
  )
}

export default SearchByRole
