import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import {
  useDeleteIssueMutation,
  useGetIssueQuery,
  useUpdateIssueMutation,
} from "@/connectors/fansbay/requests/issueRequests"
import { useRedirectWithError } from "@/utils/hooks/useRedirectWithError"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import React, { Fragment } from "react"
import { BreadCrumbInterface } from "@/interfaces/base"
import { PermissionEnum } from "@/enums/permission"
import { Button } from "@/resources/components/buttons/button"
import { useHasPermission } from "@/utils/hooks/useHasPermission"
import Card from "@/resources/components/cards/card"
import { Label } from "@/resources/components/inputs/label"
import { formatDate } from "@/utils/formatDate"
import { IssueMessages } from "@/resources/views/issues/issueMessages"
import { Container as ModalContainer } from "react-modal-promise"
import { issueChangeStatus } from "@/resources/views/issues/edit"
import { toast } from "react-toastify"
import { confirmModal } from "@/resources/components/confirm"

export const IssueDetails = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { data, error } = useGetIssueQuery(id!)
  const hasPermission = useHasPermission()
  const [updateIssue] = useUpdateIssueMutation()
  const [deleteIssue] = useDeleteIssueMutation()
  const navigate = useNavigate()

  useRedirectWithError(error, "/errors")

  const handleDeleteIssue = () => {
    confirmModal({
      text: t("messages.confirm_delete"),
      type: "danger",
    }).then(() =>
      deleteIssue(id!)
        .unwrap()
        .then(() => {
          toast.success(t("issues.details.deleted"))
          navigate("/issues")
        }),
    )
  }

  const handleChangeStatus = () => {
    issueChangeStatus({
      status: data?.status!,
    }).then((props) => {
      if (props.status !== data?.status || props.supporter_id) {
        updateIssue({
          id: id!,
          ...props,
        })
          .unwrap()
          .then(() => {
            toast.success(t("issues.details.updated"))
          })
      }
    })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      <div className={"flex justify-end gap-x-4"}>
        {hasPermission(PermissionEnum.ISSUES_DESTROY) && (
          <Button
            onClick={handleDeleteIssue}
            variant={"contained"}
            className={"bg-red-600"}
          >
            {t("issues.details.delete")}
          </Button>
        )}
        {hasPermission(PermissionEnum.ISSUES_STORE) && (
          <Button variant={"contained"} onClick={handleChangeStatus}>
            {t("issues.details.edit")}
          </Button>
        )}
      </div>
      {data && (
        <Fragment>
          <Card>
            <div className={"flex flex-col gap-4"}>
              <span className={"text-xl font-medium text-neutral-200"}>
                {t("issues.details.title")}
              </span>
              <div className={"flex flex-col gap-16 py-4 lg:flex-row"}>
                <div>
                  <Label label={t("labels.user")} />
                  <div className={"flex flex-col"}>
                    <span className={"text-s font-medium text-neutral-200"}>
                      {data.user.username}
                    </span>
                    <span className={"text-s font-medium text-gray-25"}>
                      {data.user.email}
                    </span>
                  </div>
                </div>
                <div>
                  <Label label={t("labels.supporter")} />
                  {data.supporter ? (
                    <div className={"flex flex-col"}>
                      <span className={"text-s font-medium text-neutral-200"}>
                        {data.supporter.username}
                      </span>
                      <span className={"text-s font-medium text-gray-25"}>
                        {data.supporter.email}
                      </span>
                    </div>
                  ) : (
                    <span>{t("issues.no_supporter")}</span>
                  )}
                </div>
                <div>
                  <Label label={t("labels.issue_type")} />
                  <span>{t(`issues.type.${data.type}`)}</span>
                </div>
                <div>
                  <Label label={t("labels.status")} />
                  <span>{t(`issues.status.${data.status}`)}</span>
                </div>
                <div>
                  <Label label={t("labels.created_at")} />
                  <span>{formatDate(data.created_at)}</span>
                </div>
              </div>
            </div>
          </Card>
          {hasPermission(PermissionEnum.ISSUE_MESSAGES_SHOW) && (
            <IssueMessages issueId={id!} authorId={data.user.id} />
          )}
          <ModalContainer />
        </Fragment>
      )}
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "modules.breadcrumbs.support",
    url: "#",
  },
  {
    title: "issues.breadcrumbs.issues",
    url: "/issues",
  },
  {
    title: "issues.breadcrumbs.details",
    url: `/issues/${id}`,
  },
]
