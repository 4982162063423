import React, { useEffect, useMemo, useState } from "react"
import BaseTable from "@/resources/components/table/table"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { TFunction } from "i18next"
import { ColumnSetting } from "@/resources/components/table/columnSetting"
import { Query, usePagination } from "@hypedevgroup/core"
import RowAction from "@/resources/components/table/rowAction"
import { AwardedUserInterface } from "@/interfaces/awardedUser"
import { useGetAwardedUsersQuery } from "@/connectors/fansbay/requests/awardedUserRequests"
import { formatDate } from "@/utils/formatDate"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowDownLong,
  faArrowUpLong,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"

const columnHelper = createColumnHelper<AwardedUserInterface>()

type ColumnsProps = {
  t: TFunction
} & Omit<Props, "query">

const columns = ({
  t,
  onEdit,
  onMoveDown,
  onMoveUp,
  onDelete,
}: ColumnsProps) => [
  columnHelper.display({
    id: "username",
    header: t("labels.username"),
    cell: ({ row }) => (
      <div className={"flex flex-col"}>
        <span className={"text-s font-medium text-neutral-200"}>
          {row.original.user.username}
        </span>
        <span className={"text-s font-medium text-gray-25"}>
          {row.original.user.email}
        </span>
      </div>
    ),
  }),
  columnHelper.accessor("order", {
    id: "order",
    header: t("labels.order"),
  }),
  columnHelper.accessor("created_at", {
    header: t("labels.date_of_registration"),
    cell: (row) => formatDate(row.getValue()),
  }),
  columnHelper.display({
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => (
      <div className={"flex flex-row items-center gap-x-2"}>
        {row.original.order !== 1 && (
          <FontAwesomeIcon
            icon={faArrowUpLong}
            className={"cursor-pointer text-green-600"}
            onClick={() => onMoveUp(row.original.id)}
          />
        )}
        <FontAwesomeIcon
          icon={faArrowDownLong}
          className={"cursor-pointer text-red-600"}
          onClick={() => onMoveDown(row.original.id)}
        />
        <FontAwesomeIcon
          icon={faTimes}
          size={"lg"}
          className={"cursor-pointer text-red-600"}
          onClick={() => onDelete(row.original.id)}
        />
        <RowAction onClick={() => onEdit(row.original.id)} />
      </div>
    ),
  }),
]

type Props = {
  query: Query
  onMoveUp: (id: number) => void
  onMoveDown: (id: number) => void
  onEdit: (id: number) => void
  onDelete: (id: number) => void
}

const Table = ({ query: baseQuery, ...rest }: Props) => {
  const { t } = useTranslation()
  const [data, setData] = useState<AwardedUserInterface[]>([])
  const [pagination, setPagination] = usePagination()
  const query = useMemo(
    () => baseQuery.limit(pagination.pageSize).page(pagination.pageIndex),
    [baseQuery, pagination.pageIndex, pagination.pageSize],
  )
  const { data: apiData } = useGetAwardedUsersQuery(query.url())

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const table = useReactTable({
    columns: columns({
      t,
      ...rest,
    }),
    data: data,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  return (
    <div className={"flex flex-col"}>
      <div className={"flex flex-row-reverse px-8 py-4"}>
        <ColumnSetting table={table} />
      </div>
      <BaseTable pagination={apiData?.pagination} table={table} />
    </div>
  )
}

export default Table
