import { useNavigate, useParams } from "react-router-dom"
import {
  useGetRoleQuery,
  useUpdateRoleMutation,
} from "@/connectors/fansbay/requests/roleRequests"
import { useRedirectWithError } from "@/utils/hooks/useRedirectWithError"
import { BreadCrumbInterface } from "@/interfaces/base"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { RoleForm } from "@/resources/views/roles/parts/form"
import { useState } from "react"
import { BackendValidationErrorInterface } from "@hypedevgroup/core"
import { SaveRoleInterface } from "@/interfaces/role"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"

export const RoleEdit = () => {
  const { id } = useParams<{ id: string }>()
  const { data, error } = useGetRoleQuery(id as string)
  const [update] = useUpdateRoleMutation()
  const [errors, setErrors] =
    useState<BackendValidationErrorInterface<SaveRoleInterface>>()
  const navigate = useNavigate()
  const { t } = useTranslation()

  useRedirectWithError(error, "/error")

  const handleSubmit = (data: SaveRoleInterface) => {
    update({
      ...data,
      id: id as string,
    })
      .unwrap()
      .then(() => {
        toast.success(t("roles.edit.success"))
        navigate("/roles")
      })
      .catch((error) => {
        if (error.status === 422) {
          setErrors(error.data)
        }
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      <RoleForm
        data={
          data && {
            name: data.name,
            permissions: data.permissions.map((permission) => permission.id),
          }
        }
        onSubmit={handleSubmit}
        errors={errors}
      />
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "roles.breadcrumbs.module",
    url: "#",
  },
  {
    title: "roles.breadcrumbs.roles",
    url: "/roles",
  },
  {
    title: "roles.breadcrumbs.edit",
    url: `/roles/${id}/edit`,
  },
]
