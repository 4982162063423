import React, { useEffect, useMemo, useState } from "react"
import BaseTable from "@/resources/components/table/table"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { TFunction } from "i18next"
import RowAction from "@/resources/components/table/rowAction"
import { format } from "date-fns"
import { ColumnSetting } from "@/resources/components/table/columnSetting"
import { Query, usePagination } from "@hypedevgroup/core"
import { toIntl } from "@/utils/toIntl"
import { WithdrawInterface } from "@/interfaces/withdraw"
import { useGetWithdrawsQuery } from "@/connectors/fansbay/requests/withdrawRequests"

const columnHelper = createColumnHelper<WithdrawInterface>()

const columns = (t: TFunction, locale: string) => [
  columnHelper.display({
    id: "username",
    header: t("labels.username"),
    cell: ({ row }) => (
      <div className={"flex flex-col"}>
        <span className={"text-s font-medium text-neutral-200"}>
          {row.original.user.username}
        </span>
        <span className={"text-s font-medium text-gray-25"}>
          {row.original.user.email}
        </span>
      </div>
    ),
  }),
  columnHelper.accessor("currency.name", {
    header: t("labels.currency"),
    id: "duration",
  }),
  columnHelper.accessor("status", {
    header: t("labels.status"),
    id: "status",
    cell: (row) => t(`withdraws.statuses.${row.getValue()}`),
  }),
  columnHelper.accessor(
    (row) => toIntl(row.amount, row.currency.code, locale),
    {
      header: t("labels.amount"),
      id: "amount",
    },
  ),
  columnHelper.accessor("withdraw_at", {
    header: t("labels.withdraw_at"),
    cell: (row) =>
      row.getValue()
        ? format(new Date(row.getValue()!), "dd.MM.yyyy HH:mm")
        : "",
  }),
  columnHelper.accessor("created_at", {
    header: t("labels.created_at"),
    cell: (row) => format(new Date(row.getValue()), "dd.MM.yyyy HH:mm"),
  }),
  columnHelper.display({
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => <RowAction url={`/withdraws/${row.original.id}`} />,
  }),
]

const Table = (props: { query: Query }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const [data, setData] = useState<WithdrawInterface[]>([])
  const [pagination, setPagination] = usePagination()

  const query = useMemo(
    () => props.query.limit(pagination.pageSize).page(pagination.pageIndex),
    [pagination, props.query],
  )
  const { data: apiData } = useGetWithdrawsQuery(query.url())

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const table = useReactTable({
    columns: columns(t, language),
    data: data,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  return (
    <div className={"flex flex-col"}>
      <div className={"flex flex-row-reverse px-8 py-4"}>
        <ColumnSetting table={table} />
      </div>
      <BaseTable pagination={apiData?.pagination} table={table} />
    </div>
  )
}

export default Table
