import { Fragment, useEffect } from "react"
import Divider from "@/resources/components/layouts/auth/divider"
import { useTranslation } from "react-i18next"
import { Controller, useForm } from "react-hook-form"
import { ResetPasswordPropsInterface } from "@/interfaces/auth"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormControl } from "@mui/base"
import { Input } from "@/resources/components/inputs/input"
import { FormHelperText } from "@/resources/components/inputs/formHelperText"
import { Button } from "@/resources/components/buttons/button"
import { useValidation } from "@hypedevgroup/core"
import { useAppDispatch } from "@/app/hooks"
import { resetPasswordRequestMutation } from "@/connectors/fansbay/requests/authRequests"
import { useLocation, useNavigate } from "react-router-dom"
import ResetPasswordValidation from "@/validations/auth/resetPasswordValidation"

export const ResetPassword = () => {
  const { t } = useTranslation()
  const { schema, defaultValues } = useValidation(ResetPasswordValidation, t)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ResetPasswordPropsInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const onSubmit = (data: ResetPasswordPropsInterface) => {
    dispatch(resetPasswordRequestMutation(data))
      .unwrap()
      .then(() => {
        navigate("/dashboard")
      })
      .catch(() => {})
  }

  useEffect(() => {
    const { state } = location

    if (!state.email) {
      navigate("/login")
    } else {
      setValue("email", state.email)
    }
  }, [location, navigate, setValue])

  return (
    <Fragment>
      <Divider title={t("auth.reset_password.title")} />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={"flex flex-col gap-y-6"}
      >
        <Controller
          control={control}
          name={"password"}
          render={({ field }) => (
            <FormControl {...field} error={!!errors.password}>
              <Input
                label={t("labels.password")}
                type={"password"}
                placeholder={t("placeholders.password")}
              />
              <FormHelperText message={errors.password?.message} />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name={"password_confirmation"}
          render={({ field }) => (
            <FormControl {...field} error={!!errors.password_confirmation}>
              <Input
                label={t("labels.password_confirmation")}
                type={"password"}
                placeholder={t("placeholders.password_confirmation")}
              />
              <FormHelperText message={errors.password_confirmation?.message} />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name={"code"}
          render={({ field }) => (
            <FormControl {...field} error={!!errors.code}>
              <Input
                label={t("labels.code")}
                type={"number"}
                placeholder={t("placeholders.code")}
              />
              <FormHelperText message={errors.code?.message} />
            </FormControl>
          )}
        />
        <Button type={"submit"} size={"large"} variant={"contained"}>
          {t("auth.reset_password.button")}
        </Button>
      </form>
    </Fragment>
  )
}
