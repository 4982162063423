import React from "react"

type Props = {
  label: string
}

export const Label = ({ label }: Props) => {
  return (
    <div className={"font-s pb-[6px] text-neutral-200"}>
      <label className="my-2 text-neutral-200">{label}</label>
    </div>
  )
}
