import { FormInterface } from "@/interfaces/base"
import {
  SaveSubscriptionPlanPropsInterface,
  SubscriptionPlanInterface,
} from "@/interfaces/subscriptionPlan"
import { useTranslation } from "react-i18next"
import { useAssignAttributes, useValidation } from "@hypedevgroup/core"
import SaveSubscriptionPlanValidation from "@/validations/subscriptionPlans/saveSubscriptionPlanValidation"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useEffect } from "react"
import Card from "@/resources/components/cards/card"
import { SearchByUser } from "@/resources/components/inputs/search/searchByUser"
import { Label } from "@/resources/components/inputs/label"
import { FormHelperText } from "@/resources/components/inputs/formHelperText"
import { FormControl } from "@mui/base"
import { Input } from "@/resources/components/inputs/input"
import { Button } from "@/resources/components/buttons/button"

export const SubscriptionPlanForm = ({
  errors: formErrors,
  data,
  onSubmit,
  additionalData,
}: FormInterface<
  SaveSubscriptionPlanPropsInterface,
  SubscriptionPlanInterface
>) => {
  const { t } = useTranslation()
  const { schema, defaultValues, resolveValidationErrors } = useValidation(
    SaveSubscriptionPlanValidation,
    t,
  )

  const {
    formState: { errors },
    control,
    setValue,
    handleSubmit,
    setError,
  } = useForm<SaveSubscriptionPlanPropsInterface>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  })

  useAssignAttributes(setValue, data)

  useEffect(() => {
    if (formErrors) {
      const errors = resolveValidationErrors(formErrors)

      for (const [key, value] of Object.entries(errors)) {
        setError(key as keyof SaveSubscriptionPlanPropsInterface, {
          type: "manual",
          message: value,
        })
      }
    }
  }, [formErrors])

  return (
    <div className={"mb-8 flex flex-col gap-8"}>
      <Card className={"flex w-full"}>
        <div className={"flex w-full flex-col gap-16 md:flex-row"}>
          <div className={"md:w-[150px]"}>
            <span>{t("forms.basic_data")}</span>
          </div>
          <div className={"w-full md:w-[400px]"}>
            <div className={"flex flex-col gap-y-8"}>
              <Controller
                render={({ field }) => (
                  <div>
                    <Label label={t("labels.username")} />
                    <SearchByUser
                      value={
                        additionalData
                          ? {
                              value: field.value.toString(),
                              label: additionalData.user.username,
                            }
                          : undefined
                      }
                      onChange={field.onChange}
                    />
                    <FormHelperText
                      message={errors.user_id?.message}
                      error={!!errors.user_id}
                    />
                  </div>
                )}
                name={"user_id"}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <FormControl {...field} error={!!errors.duration}>
                    <Input
                      type={"number"}
                      placeholder={t("placeholders.duration")}
                      label={t("labels.duration")}
                    />
                    <FormHelperText message={errors.duration?.message} />
                  </FormControl>
                )}
                name={"duration"}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <FormControl {...field} error={!!errors.price}>
                    <Input
                      type={"number"}
                      placeholder={t("placeholders.price")}
                      label={t("labels.price")}
                    />
                    <FormHelperText message={errors.price?.message} />
                  </FormControl>
                )}
                name={"price"}
                control={control}
              />
            </div>
          </div>
        </div>
      </Card>
      <div>
        <Button variant={"contained"} onClick={handleSubmit(onSubmit)}>
          {t("buttons.save")}
        </Button>
      </div>
    </div>
  )
}
