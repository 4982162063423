import { z } from "zod"
import { StoreUserProps, UpdateUserProps } from "@/interfaces/user"

export const convertData = (
  data: StoreUserProps | UpdateUserProps,
): FormData => {
  const formData = new FormData()

  Object.keys(data).forEach((key) => {
    const dataKey = key as keyof (StoreUserProps | UpdateUserProps)

    const fileSchema = z.instanceof(Blob).safeParse(data[dataKey])

    if (fileSchema.success) {
      formData.append(dataKey, fileSchema.data, fileSchema.data.name)
      return
    }

    const booleanSchema = z.boolean().safeParse(data[dataKey])

    if (booleanSchema.success) {
      formData.set(dataKey, booleanSchema.data ? "1" : "0")
      return
    }

    const valueSchema = z
      .union([z.string(), z.number(), z.date()])
      .safeParse(data[dataKey])

    if (valueSchema.success) {
      if (dataKey === "verified_at") {
        formData.set(dataKey, new Date(valueSchema.data).toISOString())
      } else {
        formData.set(dataKey, valueSchema.data.toString())
      }
    }
  })

  return formData
}
