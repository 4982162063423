import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  CurrencyInterface,
  GetCurrenciesResponseInterface,
  GetCurrencyResponseInterface,
  SaveCurrencyPropsInterface,
  SearchCurrenciesResponseInterface,
} from "@/interfaces/currency"
import { SaveDataWithId } from "@/types/base"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    searchCurrencies: builder.query<CurrencyInterface[], string | void>({
      query: () => `/api/panel/currencies/search`,
      transformResponse: (response: SearchCurrenciesResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Currency", id } as const)),
              { type: "Currency", id: "Search" },
            ]
          : [{ type: "Currency", id: "Search" }],
    }),
    getCurrencies: builder.query<
      GetCurrenciesResponseInterface["data"],
      string
    >({
      query: (query) => `/api/panel/currencies${query}`,
      transformResponse: (response: GetCurrenciesResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }) => ({ type: "Currency", id } as const),
              ),
              { type: "Currency", id: "LIST" },
            ]
          : [{ type: "Currency", id: "LIST" }],
    }),
    getCurrency: builder.query<CurrencyInterface, string>({
      query: (id) => `/api/panel/currencies/${id}`,
      transformResponse: (response: GetCurrencyResponseInterface) =>
        response.data,
      providesTags: (result, error, id) => [{ type: "Currency", id }],
    }),
    storeCurrency: builder.mutation<void, SaveCurrencyPropsInterface>({
      query: (body) => ({
        url: `/api/panel/currencies`,
        method: "POST",
        body,
      }),
      invalidatesTags: [
        { type: "Currency", id: "LIST" },
        { type: "Currency", id: "Search" },
      ],
    }),
    updateCurrency: builder.mutation<
      void,
      SaveDataWithId<SaveCurrencyPropsInterface>
    >({
      query: ({ id, ...body }) => ({
        url: `/api/panel/currencies/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (response, error, { id }) => [
        { type: "Currency", id: "LIST" },
        { type: "Currency", id: "Search" },
        { type: "Currency", id },
      ],
    }),
    deleteCurrency: builder.mutation<void, string>({
      query: (id) => ({
        url: `/api/panel/currencies/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        { type: "Currency", id: "LIST" },
        { type: "Currency", id: "Search" },
      ],
    }),
  }),
})

export const {
  useSearchCurrenciesQuery,
  useGetCurrenciesQuery,
  useGetCurrencyQuery,
  useStoreCurrencyMutation,
  useUpdateCurrencyMutation,
  useDeleteCurrencyMutation,
  useLazySearchCurrenciesQuery,
} = requests
