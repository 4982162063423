import { useTranslation } from "react-i18next"
import { useMemo, useState } from "react"
import { BreadCrumbInterface } from "@/interfaces/base"
import Table from "@/resources/views/users/parts/table"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import Card from "@/resources/components/cards/card"
import { baseQuery } from "@/utils/query"
import SearchByRole from "@/resources/views/users/parts/searchByRole"
import SearchByVerification from "@/resources/views/users/parts/searchByVerification"
import Search from "@/resources/components/inputs/search"
import { ButtonLink } from "@/resources/components/buttons/button"

const UserList = () => {
  const { t } = useTranslation()
  const [query, setQuery] = useState<string>()
  const [role, setRole] = useState<string>()
  const [verification, setVerification] = useState<string>()

  const queryBuilder = useMemo(() => {
    return baseQuery
      .where("search", query)
      .where("role_id", role)
      .where("verified", verification)
  }, [query, role, verification])

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <div className={"flex flex-row-reverse"}>
        <ButtonLink variant={"contained"} to={"/users/create"}>
          {t("users.list.create")}
        </ButtonLink>
      </div>
      <Card noPadding>
        <div
          className={
            "border-b border-gray-800 px-6 py-4 text-l font-medium text-neutral-200"
          }
        >
          <span>{t("users.list.title")}</span>
        </div>
        <div
          className={"flex flex-col gap-y-4 border-b border-gray-800 px-6 py-4"}
        >
          <div className={"flex w-full gap-x-4"}>
            <Search onChange={(value) => setQuery(value)} />
            <SearchByRole onChange={(value) => setRole(value)} />
            <SearchByVerification
              onChange={(value) => setVerification(value)}
            />
          </div>
        </div>
        <Table query={queryBuilder} />
      </Card>
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "users.breadcrumbs.module",
    url: "#",
  },
  {
    title: "users.breadcrumbs.users",
    url: "/users",
  },
]

export default UserList
