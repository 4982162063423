import * as yup from "yup"
import { ForgotPasswordPropsInterface } from "@/interfaces/auth"
import { TFunction } from "i18next"

class ForgotPasswordValidation {
  static defaultValues(): ForgotPasswordPropsInterface {
    return {
      email: "",
    }
  }

  static rules(
    t: TFunction,
  ): yup.ObjectSchema<ForgotPasswordPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      email: yup
        .string()
        .email(t("validations.field_must_be_email"))
        .required(t("validations.field_is_required")),
    })
  }
}

export default ForgotPasswordValidation
