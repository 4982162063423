import { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { SerializedError } from "@reduxjs/toolkit"
import { useEffect } from "react"
import { isType } from "@hypedevgroup/core"
import { useNavigate } from "react-router-dom"

export const useRedirectWithError = (
  error: FetchBaseQueryError | SerializedError | undefined,
  to: string,
) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (error && isType<FetchBaseQueryError>(error)) {
      if (error.status === 404) {
        navigate(to, {
          state: {
            error: error.data,
            status: error.status,
          },
        })
      }
    }
  }, [error, navigate, to])
}
