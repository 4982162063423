import { Select2OptionInterface } from "@/interfaces/base"
import _ from "lodash"
import AsyncSelect from "@/resources/components/inputs/asyncSelect"
import { useTranslation } from "react-i18next"
import { useEffect, useMemo, useState } from "react"
import { Query } from "@hypedevgroup/core"
import { useLazySearchCurrenciesQuery } from "@/connectors/fansbay/requests/currencyRequests"

type Props = {
  onChange: (value: string | undefined) => void
  value?: Select2OptionInterface
}

export const SearchByCurrency = (props: Props) => {
  const [searchCurrencies] = useLazySearchCurrenciesQuery()
  const { t } = useTranslation()
  const query = useMemo(() => new Query(), [])
  const [defaultCurrencies, setDefaultCurrencies] = useState<
    Select2OptionInterface[]
  >([])

  useEffect(() => {
    if (props.value) {
      setDefaultCurrencies([props.value])
    }
  }, [props.value])

  const _getCurrencies = (
    value: string,
    callback: (options: Select2OptionInterface[]) => void,
  ) => {
    searchCurrencies(query.where("name", value).url())
      .unwrap()
      .then((response) => {
        const options = response.map((currency) => ({
          label: currency.name,
          value: currency.id.toString(),
        }))

        callback(options)
      })
  }

  const getCurrencies = _.debounce(_getCurrencies, 500)

  return (
    <AsyncSelect
      onChange={(value) => props.onChange(value?.value)}
      loadOptions={getCurrencies}
      defaultOptions={defaultCurrencies}
      value={props.value}
      placeholder={t("placeholders.type_to_search_currencies")}
      cacheOptions
      noOptionsMessage={() => t("messages.no_currencies_found")}
      isClearable
    />
  )
}
