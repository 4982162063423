import { TFunction } from "i18next"
import * as yup from "yup"
import { StoreSubscriptionPropsInterface } from "@/interfaces/subscription"

export default class StoreSubscriptionValidation {
  static rules(
    t: TFunction,
  ): yup.ObjectSchema<StoreSubscriptionPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      user_id: yup.number().required(t("validations.field_is_required")),
      subscription_plan_id: yup
        .number()
        .required(t("validations.field_is_required"))
        .notOneOf([0], t("validations.field_is_required")),
    })
  }

  static defaultValues(): StoreSubscriptionPropsInterface {
    return {
      user_id: 0,
      subscription_plan_id: 0,
    }
  }
}
