import BreadCrumbs from "@/resources/components/breadCrumbs"
import { useTranslation } from "react-i18next"
import { SaveDefaultPlanInterface } from "@/interfaces/defaultPlan"
import { DefaultPlansForm } from "@/resources/views/defaultPlans/parts/form"
import { BreadCrumbInterface } from "@/interfaces/base"
import { useStorePlanMutation } from "@/connectors/fansbay/requests/defaultPlanRequests"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { BackendValidationErrorInterface } from "@hypedevgroup/core"

export const DefaultPlanCreate = () => {
  const { t } = useTranslation()
  const [storePlan] = useStorePlanMutation()
  const navigate = useNavigate()
  const [errors, setErrors] =
    useState<BackendValidationErrorInterface<SaveDefaultPlanInterface>>()

  const handleSubmit = (data: SaveDefaultPlanInterface) => {
    storePlan(data)
      .unwrap()
      .then(() => {
        toast.success(t("default_plans.create.success"))
        navigate("/default-plans")
      })
      .catch((error) => {
        if (error.status === 422) {
          setErrors(error.data)
        }
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <DefaultPlansForm errors={errors} onSubmit={handleSubmit} />
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "default_plans.breadcrumbs.default_plans",
    url: "/default-plans",
  },
  {
    title: "default_plans.breadcrumbs.create",
    url: "/default-plans/create",
  },
]
