import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { PermissionEnum } from "@/enums/permission"
import { Button, ButtonLink } from "@/resources/components/buttons/button"
import { useHasPermission } from "@/utils/hooks/useHasPermission"
import { BreadCrumbInterface } from "@/interfaces/base"
import Card from "@/resources/components/cards/card"
import { planName } from "@/resources/views/plans/parts/planName"
import {
  useDeleteSubscriptionMutation,
  useGetSubscriptionQuery,
} from "@/connectors/fansbay/requests/subscriptionRequests"
import { Label } from "@/resources/components/inputs/label"
import React from "react"
import { formatDate } from "@/utils/formatDate"
import { toast } from "react-toastify"
import { toIntl } from "@/utils/toIntl"
import { useRedirectWithError } from "@/utils/hooks/useRedirectWithError"

export const SubscriptionDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { data, error } = useGetSubscriptionQuery(id!)
  const hasPermission = useHasPermission()
  const [deleteSubscription] = useDeleteSubscriptionMutation()
  const navigate = useNavigate()

  useRedirectWithError(error, "/errors")

  const handleDeleteSubscription = () => {
    deleteSubscription(id!)
      .unwrap()
      .then(() => {
        toast.success(t("subscriptions.details.deleted"))
        navigate("/subscriptions")
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      <div className={"flex flex-row-reverse gap-x-4"}>
        {hasPermission(PermissionEnum.SUBSCRIPTIONS_STORE) && (
          <ButtonLink variant={"contained"} to={`/subscriptions/${id}/edit`}>
            {t("subscriptions.details.edit")}
          </ButtonLink>
        )}
        {hasPermission(PermissionEnum.SUBSCRIPTIONS_DESTROY) && (
          <Button
            variant={"contained"}
            onClick={handleDeleteSubscription}
            className={"bg-red-600"}
          >
            {t("subscriptions.details.delete")}
          </Button>
        )}
      </div>
      {data && (
        <Card>
          <div className={"flex flex-col gap-y-4"}>
            <span className={"text-xl font-medium text-neutral-200"}>
              {t("subscriptions.details.title")}
            </span>
            <div className={"flex flex-col flex-wrap gap-16 py-4 lg:flex-row"}>
              <div>
                <Label label={t("labels.plan_name")} />
                <span>{planName(t, data.duration, data.duration_unit)}</span>
              </div>
              <div>
                <Label label={t("labels.status")} />
                <span>{t(`subscriptions.statuses.${data.status}`)}</span>
              </div>
              <div>
                <Label label={t("labels.subscriber")} />
                <div className={"flex flex-col"}>
                  <span className={"text-s font-medium text-neutral-200"}>
                    {data.user.username}
                  </span>
                  <span className={"text-s font-medium text-gray-25"}>
                    {data.user.email}
                  </span>
                </div>
              </div>
              <div>
                <Label label={t("labels.subscribed_person")} />
                <div className={"flex flex-col"}>
                  <span className={"text-s font-medium text-neutral-200"}>
                    {data.subscribable.username}
                  </span>
                  <span className={"text-s font-medium text-gray-25"}>
                    {data.subscribable.email}
                  </span>
                </div>
              </div>
              <div>
                <Label label={t("labels.period")} />
                <span>{`${formatDate(data.current_period_start)} - ${formatDate(
                  data.current_period_end,
                )}`}</span>
              </div>
              <div>
                <Label label={t("labels.amount")} />
                <span>
                  {toIntl(
                    data.payment.amount,
                    data.payment.currency.code,
                    language,
                  )}
                </span>
              </div>
              <div>
                <Label label={t("labels.payment_date")} />
                <span>
                  {data.payment.payment_date &&
                    formatDate(data.payment.payment_date)}
                </span>
              </div>
              <div>
                <Label label={t("labels.external_payment_number")} />
                <span>{data.payment.external_id}</span>
              </div>
            </div>
          </div>
        </Card>
      )}
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "subscriptions.breadcrumbs.subscriptions",
    url: "/subscriptions",
  },
  {
    title: "subscriptions.breadcrumbs.details",
    url: `/subscriptions/${id}`,
  },
]
