import { BreadCrumbInterface } from "@/interfaces/base"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { useTranslation } from "react-i18next"
import { ButtonLink } from "@/resources/components/buttons/button"
import Card from "@/resources/components/cards/card"
import Table from "@/resources/views/currencies/parts/table"

export const CurrencyList = () => {
  const { t } = useTranslation()

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <div className={"flex flex-row-reverse"}>
        <ButtonLink to={"/currencies/create"} variant={"contained"}>
          {t("currencies.list.create")}
        </ButtonLink>
      </div>
      <Card noPadding>
        <div
          className={
            "border-b border-gray-800 px-6 py-4 text-l font-medium text-neutral-200"
          }
        >
          <span>{t("currencies.list.title")}</span>
        </div>
        <Table />
      </Card>
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "currencies.breadcrumbs.currencies",
    url: "/roles",
  },
]
