import React, { useEffect, useMemo, useState } from "react"
import BaseTable from "@/resources/components/table/table"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { TFunction } from "i18next"
import { ColumnSetting } from "@/resources/components/table/columnSetting"
import { usePagination } from "@hypedevgroup/core"
import { baseQuery } from "@/utils/query"
import { ExchangeRateInterface } from "@/interfaces/exchangeRate"
import RowAction from "@/resources/components/table/rowAction"
import { useGetExchangeRatesQuery } from "@/connectors/fansbay/requests/exchangeRateRequests"
import { format } from "date-fns"

const columnHelper = createColumnHelper<ExchangeRateInterface>()

const columns = (t: TFunction, onRecordClick: (id: number) => void) => [
  columnHelper.display({
    id: "from_currency",
    header: t("labels.from_currency"),
    cell: ({ row }) => (
      <div className={"flex flex-col"}>
        <span className={"text-s font-medium text-neutral-200"}>
          {row.original.from.name}
        </span>
        <span className={"text-s font-medium text-gray-25"}>
          {row.original.from.code}
        </span>
      </div>
    ),
  }),
  columnHelper.display({
    id: "to_currency",
    header: t("labels.to_currency"),
    cell: ({ row }) => (
      <div className={"flex flex-col"}>
        <span className={"text-s font-medium text-neutral-200"}>
          {row.original.to.name}
        </span>
        <span className={"text-s font-medium text-gray-25"}>
          {row.original.to.code}
        </span>
      </div>
    ),
  }),
  columnHelper.accessor("rate", {
    header: t("labels.rate"),
    id: "rate",
  }),
  columnHelper.accessor("created_at", {
    header: t("labels.created_at"),
    id: "created_at",
    cell: (row) => format(new Date(row.getValue()), "dd.MM.yyyy HH:mm"),
  }),
  columnHelper.display({
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => (
      <RowAction as={"button"} onClick={() => onRecordClick(row.original.id)} />
    ),
  }),
]

type Props = {
  onRecordClick: (id: number) => void
}

const Table = ({ onRecordClick }: Props) => {
  const { t } = useTranslation()
  const [data, setData] = useState<ExchangeRateInterface[]>([])
  const [pagination, setPagination] = usePagination()
  const query = useMemo(
    () => baseQuery.limit(pagination.pageSize).page(pagination.pageIndex),
    [pagination],
  )
  const { data: apiData } = useGetExchangeRatesQuery(query.url())

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const table = useReactTable({
    columns: columns(t, onRecordClick),
    data: data,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  return (
    <div className={"flex flex-col"}>
      <div className={"flex flex-row-reverse px-8 py-4"}>
        <ColumnSetting table={table} />
      </div>
      <BaseTable pagination={apiData?.pagination} table={table} />
    </div>
  )
}

export default Table
