export enum StatisticResourceEnum {
  USERS = "users",
  USER_VERIFICATIONS = "user_verifications",
  POSTS = "posts",
  POST_ATTACHMENTS = "post_attachments",
  LIKES = "likes",
  SUBSCRIPTIONS = "subscriptions",
  PAYMENTS = "payments",
  TOTAL_EARNED = "total_earned",
}
