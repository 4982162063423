import { TFunction } from "i18next"
import * as yup from "yup"
import { ChangeStatusOfUserVerificationPropsInterface } from "@/interfaces/userVerification"
import { UserVerificationStatusEnum } from "@/enums/userVerificationStatus"

export default class ChangeStatusValidation {
  static rules(
    t: TFunction,
  ): yup.ObjectSchema<
    ChangeStatusOfUserVerificationPropsInterface,
    yup.AnyObject
  > {
    return yup.object().shape({
      status: yup
        .mixed<UserVerificationStatusEnum>()
        .required(t("validations.field_is_required")),
      note: yup.string().nullable(),
    })
  }

  static defaultValues(): ChangeStatusOfUserVerificationPropsInterface {
    return {
      status: UserVerificationStatusEnum.PROCESSING,
      note: "",
    }
  }
}
