import { create, InstanceProps } from "react-modal-promise"
import React, { Fragment, useEffect } from "react"
import Modal from "@/resources/components/modals/modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"
import { useValidation } from "@hypedevgroup/core"
import UpdateAwardedUserValidation from "@/validations/awardedUsers/updateAwardedUserValidation"
import { Controller, useForm } from "react-hook-form"
import { UpdateAwardedUserPropsInterface } from "@/interfaces/awardedUser"
import { yupResolver } from "@hookform/resolvers/yup"
import { useGetAwardedUserQuery } from "@/connectors/fansbay/requests/awardedUserRequests"
import { SearchByUser } from "@/resources/components/inputs/search/searchByUser"
import { FormControl } from "@mui/base"
import { Input } from "@/resources/components/inputs/input"
import { FormHelperText } from "@/resources/components/inputs/formHelperText"
import { Button } from "@/resources/components/buttons/button"
import { Label } from "@/resources/components/inputs/label"

const AwardedUserEdit: React.FC<InstanceProps<unknown> & { id: number }> = (
  props,
) => {
  const { isOpen, id, onReject, onResolve } = props
  const { t } = useTranslation()
  const { data } = useGetAwardedUserQuery(id)
  const { schema, defaultValues } = useValidation(
    UpdateAwardedUserValidation,
    t,
  )

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<UpdateAwardedUserPropsInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (data) {
      setValue("order", data.order)
    }
  }, [data, setValue])

  const onSubmit = (data: UpdateAwardedUserPropsInterface) => {
    onResolve(data)
  }

  return (
    <Modal onClose={onReject} open={isOpen}>
      <Modal.Content>
        {data && (
          <Fragment>
            <div
              className={"flex w-full flex-row items-center justify-between"}
            >
              <span className={"text-lg font-semibold text-white"}>
                {t("awarded_users.edit.title")}
              </span>
              <FontAwesomeIcon
                onClick={onReject}
                icon={faTimes}
                className={"cursor-pointer text-white"}
              />
            </div>
            <div className={"flex flex-col gap-y-4 py-4"}>
              <div>
                <Label label={t("labels.user")} />
                <SearchByUser
                  disabled
                  onChange={() => {}}
                  value={{
                    label: data.user.username,
                    value: data.user.id.toString(),
                  }}
                />
              </div>
              <Controller
                render={({ field }) => (
                  <FormControl {...field} error={!!errors.order}>
                    <Input
                      placeholder={t("placeholders.order")}
                      label={t("labels.order")}
                    />
                    <FormHelperText message={errors?.order?.message} />
                  </FormControl>
                )}
                name={"order"}
                control={control}
              />
              <div className={"flex w-full pt-4"}>
                <Button
                  variant={"contained"}
                  className={"w-full bg-green-900"}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("buttons.save")}
                </Button>
              </div>
            </div>
          </Fragment>
        )}
      </Modal.Content>
    </Modal>
  )
}

export const editAwardedUser = create(AwardedUserEdit)
