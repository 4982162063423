import { TFunction } from "i18next"
import * as yup from "yup"
import { UpdateStatusPropsInterface } from "@/interfaces/withdraw"
import { WithdrawStatusEnum } from "@/enums/widthdrawStatus"

export default class UpdateWithdrawStatusValidation {
  static rules(
    t: TFunction,
  ): yup.ObjectSchema<UpdateStatusPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      status: yup
        .mixed<WithdrawStatusEnum>()
        .required(t("validations.field_is_required")),
      status_message: yup.string().nullable(),
    })
  }

  static defaultValues(): UpdateStatusPropsInterface {
    return {
      status: WithdrawStatusEnum.PENDING,
      status_message: "",
    }
  }
}
