import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  GetRoleResponseInterface,
  GetRolesResponseInterface,
  RoleInterface,
  SaveRoleInterface,
} from "@/interfaces/role"
import { SaveDataWithId } from "@/types/base"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query<RoleInterface[], void>({
      query: () => "/api/panel/roles",
      transformResponse: (response: GetRolesResponseInterface) => response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Role", id } as const)),
              { type: "Role", id: "LIST" },
            ]
          : [{ type: "Role", id: "LIST" }],
    }),
    searchRoles: builder.query<RoleInterface[], void>({
      query: () => "/api/panel/roles/search",
      transformResponse: (response: GetRolesResponseInterface) => response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Role", id } as const)),
              { type: "Role", id: "SEARCH" },
            ]
          : [{ type: "Role", id: "SEARCH" }],
    }),
    storeRole: builder.mutation<void, SaveRoleInterface>({
      query: (body) => ({
        url: "/api/panel/roles",
        method: "POST",
        body,
      }),
      invalidatesTags: [
        {
          type: "Role",
          id: "LIST",
        },
        {
          type: "Role",
          id: "SEARCH",
        },
      ],
    }),
    getRole: builder.query<RoleInterface, number | string>({
      query: (id) => `/api/panel/roles/${id}`,
      transformResponse: (response: GetRoleResponseInterface) => response.data,
      providesTags: (result, error, id) => [{ type: "Role", id }],
    }),
    updateRole: builder.mutation<void, SaveDataWithId<SaveRoleInterface>>({
      query: ({ id, ...body }) => ({
        url: `/api/panel/roles/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Role", id },
        { type: "Role", id: "LIST" },
        { type: "Role", id: "SEARCH" },
      ],
    }),
    deleteRole: builder.mutation<void, string>({
      query: (id) => ({
        url: `/api/panel/roles/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        { type: "Role", id: "LIST" },
        { type: "Role", id: "SEARCH" },
      ],
    }),
  }),
})

export const {
  useGetRolesQuery,
  useSearchRolesQuery,
  useStoreRoleMutation,
  useGetRoleQuery,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = requests
