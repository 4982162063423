import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  GetCommentsPropsInterface,
  GetCommentsResponseInterface,
} from "@/interfaces/comment"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getComments: builder.query<
      GetCommentsResponseInterface["data"],
      GetCommentsPropsInterface
    >({
      query: ({ postId, query }) =>
        `/api/panel/posts/${postId}/comments?${query}`,
      transformResponse: (response: GetCommentsResponseInterface) =>
        response.data,
      providesTags: (results) =>
        results
          ? [
              ...results.data.map(
                ({ id }) => ({ type: "Comment", id } as const),
              ),
              { type: "Comment", id: "LIST" },
            ]
          : [{ type: "Comment", id: "LIST" }],
    }),
    deleteComment: builder.mutation<void, { postId: string; id: string }>({
      query: ({ postId, id }) => ({
        url: `/api/panel/posts/${postId}/comments/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Comment", id: "LIST" }],
    }),
  }),
})

export const { useDeleteCommentMutation, useGetCommentsQuery } = requests
