import { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { BreadCrumbInterface } from "@/interfaces/base"
import { StoreAgreementPropsInterface } from "@/interfaces/agreement"
import { Form } from "./partials"
import { useStoreAgreementMutation } from "@/connectors/fansbay"
import { serialize } from "object-to-formdata"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const AgreementCreate = (): ReactNode => {
  const { t } = useTranslation()
  const [storeAgreement] = useStoreAgreementMutation()
  const navigate = useNavigate()

  const handleSubmit = async (data: StoreAgreementPropsInterface) => {
    try {
      await storeAgreement(
        serialize(data, {
          nullsAsUndefineds: true,
          booleansAsIntegers: true,
          indices: true,
        }),
      ).unwrap()
      toast.success(t("agreements.create.success"))
      navigate("/agreements")
    } catch (error) {
      toast.error(t("alerts.500"))
    }
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <Form onSubmit={handleSubmit} />
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "modules.breadcrumbs.content",
    url: "#",
  },
  {
    title: "agreements.breadcrumbs.agreements",
    url: "/agreements",
  },
  {
    title: "agreements.breadcrumbs.create",
    url: "/agreements/create",
  },
]

export { AgreementCreate }
