import React, { useEffect, useMemo, useState } from "react"
import BaseTable from "@/resources/components/table/table"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { TFunction } from "i18next"
import { format } from "date-fns"
import { ColumnSetting } from "@/resources/components/table/columnSetting"
import { usePagination } from "@hypedevgroup/core"
import { CommentInterface } from "@/interfaces/comment"
import { baseQuery } from "@/utils/query"
import {
  useDeleteCommentMutation,
  useGetCommentsQuery,
} from "@/connectors/fansbay/requests/commentRequests"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { useHasPermission } from "@/utils/hooks/useHasPermission"
import { PermissionEnum } from "@/enums/permission"
import { toast } from "react-toastify"

const columnHelper = createColumnHelper<CommentInterface>()

const columns = (t: TFunction, onDelete: (id: string) => void) => [
  columnHelper.display({
    id: "username",
    header: t("labels.username"),
    cell: ({ row }) => (
      <div className={"flex flex-col"}>
        <span className={"text-s font-medium text-neutral-200"}>
          {row.original.user.username}
        </span>
        <span className={"text-s font-medium text-gray-25"}>
          {row.original.user.email}
        </span>
      </div>
    ),
  }),
  columnHelper.display({
    id: "content",
    header: t("labels.content"),
    cell: ({ row }) => (
      <div className={"max-w-[200px] overflow-x-auto"}>
        {row.original.content}
      </div>
    ),
  }),
  columnHelper.accessor("created_at", {
    header: t("labels.created_at"),
    cell: (row) => format(new Date(row.getValue()), "dd.MM.yyyy HH:mm"),
  }),
  columnHelper.display({
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => (
      <FontAwesomeIcon
        className={"cursor-pointer"}
        icon={faTrash}
        onClick={() => onDelete(row.original.id.toString())}
      />
    ),
  }),
]

const Table = (props: { postId: string }) => {
  const { t } = useTranslation()
  const [data, setData] = useState<CommentInterface[]>([])
  const [pagination, setPagination] = usePagination()
  const [deleteComment] = useDeleteCommentMutation()
  const hasPermission = useHasPermission()

  const query = useMemo(
    () => baseQuery.limit(pagination.pageSize).page(pagination.pageIndex),
    [pagination],
  )

  const { data: apiData } = useGetCommentsQuery({
    postId: props.postId,
    query: query.url(),
  })

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const onDelete = (id: string) => {
    deleteComment({
      id,
      postId: props.postId,
    })
      .unwrap()
      .then(() => {
        toast.success(t("posts.details.comments.deleted"))
      })
  }

  const table = useReactTable({
    columns: columns(t, onDelete),
    data: data,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      columnVisibility: {
        actions: hasPermission(PermissionEnum.POST_COMMENTS_DESTROY),
      },
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  return (
    <div className={"flex flex-col"}>
      <div className={"flex flex-row-reverse px-8 py-4"}>
        <ColumnSetting table={table} />
      </div>
      <BaseTable pagination={apiData?.pagination} table={table} />
    </div>
  )
}

export default Table
