import { useTranslation } from "react-i18next"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { ButtonLink } from "@/resources/components/buttons/button"
import Card from "@/resources/components/cards/card"
import { BreadCrumbInterface } from "@/interfaces/base"
import Table from "@/resources/views/defaultPlans/parts/table"

export const DefaultPlanList = () => {
  const { t } = useTranslation()

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <div className={"flex flex-row-reverse"}>
        <ButtonLink variant={"contained"} to={"/default-plans/create"}>
          {t("default_plans.list.create")}
        </ButtonLink>
      </div>
      <Card noPadding>
        <div
          className={
            "border-b border-gray-800 px-6 py-4 text-l font-medium text-neutral-200"
          }
        >
          <span>{t("default_plans.list.title")}</span>
        </div>
        <Table />
      </Card>
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "default_plans.breadcrumbs.default_plans",
    url: "/default-plans",
  },
]
