import { FormInterface } from "@/interfaces/base"
import { useTranslation } from "react-i18next"
import React from "react"
import Card from "@/resources/components/cards/card"
import { useAssignAttributes, useValidation } from "@hypedevgroup/core"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormControl } from "@mui/base"
import { Input } from "@/resources/components/inputs/input"
import { FormHelperText } from "@/resources/components/inputs/formHelperText"
import { Button } from "@/resources/components/buttons/button"
import SaveCurrencyValidation from "@/validations/currencies/saveCurrencyValidation"
import { SaveCurrencyPropsInterface } from "@/interfaces/currency"

export const CurrencyForm = ({
  onSubmit,
  data,
  errors: formErrors,
}: FormInterface<SaveCurrencyPropsInterface>) => {
  const { t } = useTranslation()
  const { schema, defaultValues } = useValidation(SaveCurrencyValidation, t)

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<SaveCurrencyPropsInterface>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  })

  useAssignAttributes(setValue, data)

  return (
    <div className={"mb-8 flex flex-col gap-8"}>
      <Card className={"flex w-full"}>
        <div className={"flex w-full flex-col gap-16 md:flex-row"}>
          <div className={"md:w-[150px]"}>
            <span>{t("forms.basic_data")}</span>
          </div>
          <div className={"w-full md:w-[400px]"}>
            <div className={"flex flex-col gap-y-8"}>
              <Controller
                render={({ field }) => (
                  <FormControl {...field} error={!!errors.name}>
                    <Input
                      placeholder={t("placeholders.currency_name")}
                      label={t("labels.currency_name")}
                    />
                    <FormHelperText message={errors.name?.message} />
                  </FormControl>
                )}
                name={"name"}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <FormControl {...field} error={!!errors.code}>
                    <Input
                      placeholder={t("placeholders.currency_code")}
                      label={t("labels.currency_code")}
                    />
                    <FormHelperText message={errors.code?.message} />
                  </FormControl>
                )}
                control={control}
                name={"code"}
              />
              <Controller
                render={({ field }) => (
                  <FormControl {...field} error={!!errors.symbol}>
                    <Input
                      placeholder={t("placeholders.currency_symbol")}
                      label={t("labels.currency_symbol")}
                    />
                    <FormHelperText message={errors.symbol?.message} />
                  </FormControl>
                )}
                control={control}
                name={"symbol"}
              />
              <Controller
                render={({ field }) => (
                  <FormControl {...field} error={!!errors.multiplier}>
                    <Input
                      type={"number"}
                      placeholder={t("placeholders.currency_multiplier")}
                      label={t("labels.currency_multiplier")}
                    />
                    <FormHelperText message={errors.multiplier?.message} />
                  </FormControl>
                )}
                name={"multiplier"}
                control={control}
              />
            </div>
          </div>
        </div>
      </Card>
      <div>
        <Button variant={"contained"} onClick={handleSubmit(onSubmit)}>
          {t("buttons.save")}
        </Button>
      </div>
    </div>
  )
}
