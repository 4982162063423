import React, { useEffect, useMemo, useState } from "react"
import BaseTable from "@/resources/components/table/table"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { UserInterface } from "@/interfaces/user"
import { TFunction } from "i18next"
import { Query, usePagination } from "@hypedevgroup/core"
import { useGetUsersQuery } from "@/connectors/fansbay/requests/userRequests"
import RowAction from "@/resources/components/table/rowAction"
import { format } from "date-fns"
import { Badge } from "@/resources/components/badge"
import { toIntWithWallet } from "@/utils/toIntl"
import { ColumnSetting } from "@/resources/components/table/columnSetting"

type Props = {
  query: Query
}

const columnHelper = createColumnHelper<UserInterface>()

const columns = (t: TFunction) => [
  columnHelper.display({
    id: "username",
    header: t("labels.username"),
    cell: ({ row }) => (
      <div className={"flex flex-col"}>
        <span className={"text-s font-medium text-neutral-200"}>
          {row.original.username}
        </span>
        <span className={"text-s font-medium text-gray-25"}>
          {row.original.email}
        </span>
      </div>
    ),
  }),
  columnHelper.accessor("created_at", {
    header: t("labels.date_of_registration"),
    cell: (row) => format(new Date(row.getValue()), "dd.MM.yyyy HH:mm"),
  }),
  columnHelper.accessor("role.name", {
    header: t("labels.role"),
  }),
  columnHelper.display({
    id: "email_verified",
    header: t("labels.email_verified"),
    cell: ({ row }) => (
      <Badge
        color={row.original.email_verified_at ? "bg-green-500" : "bg-gray-800"}
        textColor={"text-neutral-200"}
      >
        {row.original.email_verified_at ? t("labels.yes") : t("labels.no")}
      </Badge>
    ),
  }),
  columnHelper.accessor((row) => toIntWithWallet(row.wallet), {
    header: t("labels.balance"),
  }),
  columnHelper.display({
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => <RowAction url={`/users/${row.original.id}`} />,
  }),
]

const Table = (props: Props) => {
  const { t } = useTranslation()
  const [data, setData] = useState<UserInterface[]>([])
  const [pagination, setPagination] = usePagination()

  const query = useMemo(
    () => props.query.page(pagination.pageIndex).limit(pagination.pageSize),
    [pagination.pageIndex, pagination.pageSize, props.query],
  )

  const { data: apiData } = useGetUsersQuery(query.url())

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const table = useReactTable({
    columns: columns(t),
    data: data,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  return (
    <div className={"flex flex-col"}>
      <div className={"flex flex-row-reverse px-8 py-4"}>
        <ColumnSetting table={table} />
      </div>
      <BaseTable table={table} pagination={apiData?.pagination} />
    </div>
  )
}

export default Table
