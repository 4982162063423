import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { RootState } from "@/app/store"

const url = import.meta.env.VITE_FANSBAY_API_URL

const FansbayConnector = createApi({
  tagTypes: [
    "User",
    "Role",
    "Country",
    "Permission",
    "DefaultPlan",
    "Currency",
    "SubscriptionPlan",
    "ExchangeRate",
    "Subscription",
    "PaymentProvider",
    "Post",
    "Comment",
    "Withdraw",
    "TaxRate",
    "ProvisionRate",
    "Issue",
    "IssueMessage",
    "UserGroup",
    "UserVerification",
    "AwardedUser",
    "MessageFilter",
    "ChatMessage",
    "Page",
    "UserWallet",
    "Setting",
    "Payment",
    "Statistics",
    "Agreement",
  ],
  reducerPath: "notConnector",
  baseQuery: fetchBaseQuery({
    baseUrl: url,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token

      headers.set("Accept", "application/json")

      if (headers.get("Content-Type") === "multipart/form-data") {
        headers.delete("Content-Type")
      } else {
        headers.set("Content-Type", "application/json")
      }

      if (token) {
        headers.set("Authorization", `Bearer ${token}`)
      }

      return headers
    },
  }),
  endpoints: (builder) => ({}),
})

export default FansbayConnector
