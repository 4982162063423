import { useTranslation } from "react-i18next"
import { useSearchCountriesQuery } from "@/connectors/fansbay/requests/countryRequests"
import { BreadCrumbInterface } from "@/interfaces/base"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import Card from "@/resources/components/cards/card"
import { useParams } from "react-router-dom"
import { TransferList } from "@/resources/components/transferList/transferList"
import {
  useGetPaymentProviderQuery,
  useUpdatePaymentProviderMutation,
} from "@/connectors/fansbay/requests/paymentProviderRequests"
import { CountryInterface } from "@/interfaces/country"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Button } from "@/resources/components/buttons/button"

export const PaymentProviderEdit = () => {
  const { t } = useTranslation()
  const { data: countries = [] } = useSearchCountriesQuery()
  const { id } = useParams<{ id: string }>()
  const { data } = useGetPaymentProviderQuery(id!)
  const [selectedCountries, setSelectedCountries] = useState<
    CountryInterface[]
  >([])
  const [updatePaymentProvider] = useUpdatePaymentProviderMutation()

  useEffect(() => {
    if (data) {
      setSelectedCountries(data.countries)
    }
  }, [data])

  const onChange = (countries: CountryInterface[]) => {
    setSelectedCountries(countries)
  }

  const handleUpdate = () => {
    updatePaymentProvider({
      id: id!,
      countries: selectedCountries.map((country) => country.id),
    })
      .unwrap()
      .then(() => {
        toast.success(t("payment_providers.edit.updated"))
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      <Card noPadding>
        <div
          className={
            "border-b border-gray-800 px-6 py-4 text-l font-medium text-neutral-200"
          }
        >
          <span>{t("payment_providers.edit.title")}</span>
        </div>
        <div>
          <TransferList
            list={countries}
            selected={selectedCountries}
            onChange={onChange}
            titles={[
              "payment_providers.edit.available_countries",
              "payment_providers.edit.selected_countries",
            ]}
            render={(item) => item.name}
            indexKey={"id"}
          />
        </div>
      </Card>
      <div>
        <Button variant={"contained"} onClick={handleUpdate}>
          {t("buttons.save")}
        </Button>
      </div>
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "payment_providers.breadcrumbs.payment_providers",
    url: "/payment-providers",
  },
  {
    title: "payment_providers.breadcrumbs.edit",
    url: `/payment-providers/${id}/edit`,
  },
]
