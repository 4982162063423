import { useParams } from "react-router-dom"
import { useGetChatMessageQuery } from "@/connectors/fansbay/requests/chatMessageRequests"
import { useTranslation } from "react-i18next"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import React, { Fragment } from "react"
import { BreadCrumbInterface } from "@/interfaces/base"
import Card from "@/resources/components/cards/card"
import { Label } from "@/resources/components/inputs/label"
import { UserAvatar } from "@/resources/components/userAvatar"
import { Badge } from "@/resources/components/badge"
import { formatDate } from "@/utils/formatDate"

export const ChatMessageDetails = () => {
  const { id } = useParams<{ id: string }>()
  const { data } = useGetChatMessageQuery(id!)
  const { t } = useTranslation()

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      {data && (
        <Fragment>
          <Card>
            <div className={"flex flex-col gap-4"}>
              <span className={"text-xl font-medium text-neutral-200"}>
                {t("chat_messages.details.title")}
              </span>
              <div className={"flex flex-col gap-16 py-4 lg:flex-row"}>
                <div>
                  <Label label={t("labels.sender")} />
                  <div className={"flex flex-row items-center gap-x-2"}>
                    <UserAvatar url={data.details.sender.avatar_thumb} />
                    <span>{data.details.sender.username}</span>
                  </div>
                </div>
                <div>
                  <Label label={t("labels.receiver")} />
                  <div className={"flex flex-row items-center gap-x-2"}>
                    <UserAvatar url={data.details.receiver.avatar_thumb} />
                    <span>{data.details.receiver.username}</span>
                  </div>
                </div>
                <div>
                  <Label label={t("labels.is_paid")} />
                  <Badge
                    color={
                      data.details.is_paid ? "bg-green-500" : "bg-gray-800"
                    }
                    textColor={"text-neutral-200"}
                  >
                    {data.details.is_paid ? t("labels.yes") : t("labels.no")}
                  </Badge>
                </div>
                <div>
                  <Label label={t("labels.read_at")} />
                  {data.details.read_at && (
                    <span>{formatDate(data.details.read_at)}</span>
                  )}
                </div>
                <div>
                  <Label label={t("labels.created_at")} />
                  <span>{formatDate(data.details.created_at)}</span>
                </div>
              </div>
              <div>
                <Label label={t("labels.message")} />
                <p>{data.details.message}</p>
              </div>
            </div>
          </Card>
          <span className={"text-xl font-medium text-neutral-200"}>
            {t("chat_messages.details.replies")}
          </span>
          <Card>
            <div className={"flex flex-col gap-y-4"}>
              {data.messages.map((message) => (
                <div className={"flex flex-col"} key={message.id}>
                  <span className={"text-lg font-medium text-neutral-200"}>
                    {message.sender.username}
                  </span>
                  <p>{message.message}</p>
                </div>
              ))}
            </div>
          </Card>
        </Fragment>
      )}
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "modules.breadcrumbs.content",
    url: "#",
  },
  {
    title: "chat_messages.breadcrumbs.messages",
    url: "/messages",
  },
  {
    title: "chat_messages.breadcrumbs.details",
    url: `/messages/${id}`,
  },
]
