import { useNavigate, useParams } from "react-router-dom"
import {
  useGetPlanQuery,
  useUpdatePlanMutation,
} from "@/connectors/fansbay/requests/defaultPlanRequests"
import { useRedirectWithError } from "@/utils/hooks/useRedirectWithError"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { BreadCrumbInterface } from "@/interfaces/base"
import { DefaultPlansForm } from "@/resources/views/defaultPlans/parts/form"
import { SaveDefaultPlanInterface } from "@/interfaces/defaultPlan"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"

export const DefaultPlanEdit = () => {
  const { id } = useParams<{ id: string }>()
  const { data, error } = useGetPlanQuery(id!)
  useRedirectWithError(error, "/error")
  const { t } = useTranslation()
  const [updatePlan] = useUpdatePlanMutation()
  const navigate = useNavigate()

  const handleSubmit = (data: SaveDefaultPlanInterface) => {
    updatePlan({
      ...data,
      id: id!,
    })
      .unwrap()
      .then(() => {
        navigate("/default-plans")
        toast.success(t("default_plans.edit.success"))
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      <DefaultPlansForm onSubmit={handleSubmit} data={data} />
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "default_plans.breadcrumbs.default_plans",
    url: "/default-plans",
  },
  {
    title: "default_plans.breadcrumbs.edit",
    url: `/default-plans/${id}/edit`,
  },
]
