import { create, InstanceProps } from "react-modal-promise"
import React, { Fragment, useEffect } from "react"
import Modal from "@/resources/components/modals/modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"
import { useValidation } from "@hypedevgroup/core"
import ChangeStatusValidation from "@/validations/issues/changeStatusValidation"
import { Controller, useForm } from "react-hook-form"
import { UpdateIssuePropsInterface } from "@/interfaces/issue"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormControl } from "@mui/base"
import Select, { Option } from "@/resources/components/inputs/select"
import { FormHelperText } from "@/resources/components/inputs/formHelperText"
import { IssueStatusEnum } from "@/enums/issueStatus"
import { Label } from "@/resources/components/inputs/label"
import { SearchByUser } from "@/resources/components/inputs/search/searchByUser"
import { Input } from "@/resources/components/inputs/input"
import { Button } from "@/resources/components/buttons/button"
import _ from "lodash"
import { PermissionEnum } from "@/enums/permission"

type Props = {
  status: IssueStatusEnum
}

const IssueChangeStatus: React.FC<InstanceProps<unknown> & Props> = (props) => {
  const { isOpen, onReject, onResolve, status } = props
  const { t } = useTranslation()
  const { schema, defaultValues } = useValidation(ChangeStatusValidation, t)

  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<UpdateIssuePropsInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    setValue("status", status)
  }, [setValue, status])

  const onSubmit = (data: UpdateIssuePropsInterface) => {
    onResolve(_.pickBy(data))
  }

  return (
    <Modal open={isOpen} onClose={onReject}>
      <Modal.Content>
        <Fragment>
          <div className={"flex w-full flex-row items-center justify-between"}>
            <span className={"text-lg font-semibold text-white"}>
              {t("issues.edit.title")}
            </span>
            <FontAwesomeIcon
              onClick={onReject}
              icon={faTimes}
              className={"cursor-pointer text-white"}
            />
          </div>
          <div className={"flex flex-col gap-y-4 py-4"}>
            <Controller
              render={({ field }) => (
                <FormControl error={!!errors.status}>
                  <Select
                    fullWidth
                    label={t("labels.status")}
                    {...field}
                    onChange={(_, value) => field.onChange(value)}
                  >
                    <Option
                      disabled={[
                        IssueStatusEnum.PROCESSING,
                        IssueStatusEnum.CLOSED,
                      ].includes(field.value)}
                      value={IssueStatusEnum.PENDING}
                    >
                      {t(`issues.status.${IssueStatusEnum.PENDING}`)}
                    </Option>
                    <Option
                      disabled={field.value === IssueStatusEnum.CLOSED}
                      value={IssueStatusEnum.PROCESSING}
                    >
                      {t(`issues.status.${IssueStatusEnum.PROCESSING}`)}
                    </Option>
                    <Option value={IssueStatusEnum.CLOSED}>
                      {t(`issues.status.${IssueStatusEnum.CLOSED}`)}
                    </Option>
                  </Select>
                  <FormHelperText message={errors.status?.message} />
                </FormControl>
              )}
              name={"status"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl error={!!errors.supporter_id}>
                  <Label label={t("labels.supporter")} />
                  <SearchByUser
                    onChange={field.onChange}
                    permission={PermissionEnum.ISSUES_SHOW}
                  />
                  <FormHelperText message={errors.supporter_id?.message} />
                </FormControl>
              )}
              name={"supporter_id"}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <FormControl {...field} error={!!errors.message}>
                  <Input
                    multiline
                    placeholder={t("placeholders.enter_message")}
                    label={t("labels.message")}
                  />
                  <FormHelperText message={errors.message?.message} />
                </FormControl>
              )}
              name={"message"}
              control={control}
            />
            <div className={"flex w-full pt-4"}>
              <Button
                variant={"contained"}
                className={"w-full bg-green-900"}
                onClick={handleSubmit(onSubmit)}
              >
                {t("buttons.save")}
              </Button>
            </div>
          </div>
        </Fragment>
      </Modal.Content>
    </Modal>
  )
}

export const issueChangeStatus = create(IssueChangeStatus)
