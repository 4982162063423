import { FC, useState } from "react"
import { create, InstanceProps } from "react-modal-promise"
import Modal from "@/resources/components/modals/modal"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { ExchangeRateForm } from "@/resources/views/exchangeRates/parts/form"
import { SaveExchangeRatePropsInterface } from "@/interfaces/exchangeRate"
import { useStoreExchangeRateMutation } from "@/connectors/fansbay/requests/exchangeRateRequests"
import { BackendValidationErrorInterface } from "@hypedevgroup/core"

const ExchangeRateCreate: FC<InstanceProps<unknown>> = (props) => {
  const { onResolve, onReject, isOpen } = props
  const { t } = useTranslation()
  const [saveExchangeRate] = useStoreExchangeRateMutation()
  const [errors, setErrors] =
    useState<BackendValidationErrorInterface<SaveExchangeRatePropsInterface>>()

  const handleSubmit = (data: SaveExchangeRatePropsInterface) => {
    saveExchangeRate(data)
      .unwrap()
      .then(() => {
        onResolve()
      })
      .catch((error) => {
        if (error.status === 422) {
          setErrors(error.data)
        }
      })
  }

  return (
    <Modal open={isOpen} onClose={onReject}>
      <Modal.Content size={"w-[600px]"}>
        <div className={"flex flex-col gap-y-4"}>
          <div className={"flex items-center justify-between"}>
            <span className={"text-l font-semibold text-neutral-200"}>
              {t("exchange_rates.create.title")}
            </span>
            <FontAwesomeIcon
              icon={faTimes}
              onClick={onReject}
              className={"cursor-pointer"}
            />
          </div>
          <ExchangeRateForm errors={errors} onSubmit={handleSubmit} />
        </div>
      </Modal.Content>
    </Modal>
  )
}

export const exchangeRateCreateModal = create(ExchangeRateCreate)
