import { useTranslation } from "react-i18next"
import {
  useGetSubscriptionPlanQuery,
  useUpdateSubscriptionPlanMutation,
} from "@/connectors/fansbay/requests/subscriptionPlanRequests"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { SaveSubscriptionPlanPropsInterface } from "@/interfaces/subscriptionPlan"
import { SubscriptionPlanForm } from "@/resources/views/plans/parts/form"
import { BreadCrumbInterface } from "@/interfaces/base"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import { useRedirectWithError } from "@/utils/hooks/useRedirectWithError"
import { useState } from "react"
import { BackendValidationErrorInterface } from "@hypedevgroup/core"

export const SubscriptionPlanEdit = () => {
  const [updatePlan] = useUpdateSubscriptionPlanMutation()
  const { id } = useParams<{ id: string }>()
  const { data, error } = useGetSubscriptionPlanQuery(id!)
  const navigate = useNavigate()
  useRedirectWithError(error, "/error")
  const { t } = useTranslation()
  const [errors, setErrors] =
    useState<
      BackendValidationErrorInterface<SaveSubscriptionPlanPropsInterface>
    >()

  const handleSubmit = (data: SaveSubscriptionPlanPropsInterface) => {
    updatePlan({
      ...data,
      id: id!,
    })
      .unwrap()
      .then(() => {
        toast.success(t("subscription_plans.edit.success"))
        navigate("/subscription-plans")
      })
      .catch((error) => {
        if (error.status === 422) {
          setErrors(error.data)
        }
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      <SubscriptionPlanForm
        errors={errors}
        additionalData={data}
        data={data}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "subscription_plans.breadcrumbs.subscription_plans",
    url: "/subscription-plans",
  },
  {
    title: "subscription_plans.breadcrumbs.edit",
    url: `/subscription-plans/${id}/edit`,
  },
]
