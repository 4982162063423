import { FormInterface } from "@/interfaces/base"
import { useTranslation } from "react-i18next"
import { useAssignAttributes, useValidation } from "@hypedevgroup/core"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useEffect } from "react"
import Card from "@/resources/components/cards/card"
import { FormControl } from "@mui/base"
import { Input } from "@/resources/components/inputs/input"
import { FormHelperText } from "@/resources/components/inputs/formHelperText"
import Select, { Option } from "@/resources/components/inputs/select"
import { useSearchCountriesQuery } from "@/connectors/fansbay/requests/countryRequests"
import { Checkbox } from "@/resources/components/inputs/checkbox"
import { Label } from "@/resources/components/inputs/label"
import { Button } from "@/resources/components/buttons/button"
import { SaveProvisionRatePropsInterface } from "@/interfaces/provisionRate"
import SaveProvisionRateValidation from "@/validations/provisionRates/saveProvisionRateValidation"
import { useSearchPaymentProvidersQuery } from "@/connectors/fansbay/requests/paymentProviderRequests"

export const ProvisionRateForm = ({
  data,
  errors: formErrors,
  onSubmit,
}: FormInterface<SaveProvisionRatePropsInterface>) => {
  const { t } = useTranslation()
  const { schema, defaultValues, resolveValidationErrors } = useValidation(
    SaveProvisionRateValidation,
    t,
  )
  const { data: countries = [] } = useSearchCountriesQuery()
  const { data: providers = [] } = useSearchPaymentProvidersQuery()

  const {
    setValue,
    setError,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<SaveProvisionRatePropsInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  useAssignAttributes(setValue, data)

  useEffect(() => {
    if (formErrors) {
      const errors = resolveValidationErrors(formErrors)
      Object.keys(errors).forEach((key) => {
        const errorKey = key as keyof SaveProvisionRatePropsInterface

        setError(errorKey, {
          type: "manual",
          message: errors[errorKey],
        })
      })
    }
  }, [formErrors, setError])

  return (
    <div className={"mb-8 flex flex-col gap-8"}>
      <Card className={"flex w-full"}>
        <div className={"flex w-full flex-col gap-16 md:flex-row"}>
          <div className={"md:w-[150px]"}>
            <span>{t("forms.basic_data")}</span>
          </div>
          <div className={"w-full md:w-[400px]"}>
            <div className={"flex flex-col gap-y-8"}>
              <Controller
                render={({ field: { onChange, ...rest } }) => (
                  <FormControl error={!!errors.country_id}>
                    <Select
                      label={t("labels.country")}
                      fullWidth
                      onChange={(_, value) => onChange(value)}
                      {...rest}
                    >
                      <Option value={0}>
                        {t("default_options.select_country")}
                      </Option>
                      {countries.map((country) => (
                        <Option key={country.id} value={country.id}>
                          {country.name}
                        </Option>
                      ))}
                    </Select>
                    <FormHelperText message={errors.country_id?.message} />
                  </FormControl>
                )}
                name={"country_id"}
                control={control}
              />
              <Controller
                render={({ field: { onChange, ...rest } }) => (
                  <FormControl error={!!errors.payment_provider_id}>
                    <Select
                      label={t("labels.provider_name")}
                      fullWidth
                      onChange={(_, value) => onChange(value)}
                      {...rest}
                    >
                      <Option value={0}>
                        {t("default_options.select_provider")}
                      </Option>
                      {providers.map((provider) => (
                        <Option key={provider.id} value={provider.id}>
                          {provider.name}
                        </Option>
                      ))}
                    </Select>
                    <FormHelperText
                      message={errors.payment_provider_id?.message}
                    />
                  </FormControl>
                )}
                name={"payment_provider_id"}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <FormControl {...field} error={!!errors.rate}>
                    <Input
                      label={t("labels.value")}
                      endAdornment={<span>%</span>}
                      type={"number"}
                      placeholder={t("placeholders.enter_value")}
                    />
                    <FormHelperText message={errors.rate?.message} />
                  </FormControl>
                )}
                name={"rate"}
                control={control}
              />
              <Controller
                render={({ field: { onChange, ...rest } }) => (
                  <FormControl error={!!errors.type}>
                    <Select
                      label={t("labels.provision_type")}
                      fullWidth
                      onChange={(_, value) => onChange(value)}
                      {...rest}
                    >
                      <Option value={"internal"}>
                        {t("provision_rates.type.internal")}
                      </Option>
                      <Option value={"external"}>
                        {t("provision_rates.type.external")}
                      </Option>
                    </Select>
                    <FormHelperText message={errors.type?.message} />
                  </FormControl>
                )}
                name={"type"}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <FormControl error={!!errors.active}>
                    <Label label={t("labels.active")} />
                    <Checkbox
                      checked={field.value}
                      onChange={(e) => field.onChange(e.currentTarget.checked)}
                    />
                    <FormHelperText message={errors.active?.message} />
                  </FormControl>
                )}
                name={"active"}
                control={control}
              />
            </div>
          </div>
        </div>
      </Card>
      <div>
        <Button variant={"contained"} onClick={handleSubmit(onSubmit)}>
          {t("buttons.save")}
        </Button>
      </div>
    </div>
  )
}
