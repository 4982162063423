import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  GetSubscriptionPlanResponseInterface,
  GetSubscriptionPlansResponseInterface,
  SaveSubscriptionPlanPropsInterface,
  SearchSubscriptionPlan,
  SearchSubscriptionPlansInterface,
  SubscriptionPlanInterface,
} from "@/interfaces/subscriptionPlan"
import { SaveDataWithId } from "@/types/base"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getSubscriptionPlans: builder.query<
      GetSubscriptionPlansResponseInterface["data"],
      string
    >({
      query: (query) => `/api/panel/subscription-plans${query}`,
      transformResponse: (response: GetSubscriptionPlansResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }) => ({ type: "SubscriptionPlan", id } as const),
              ),
              { type: "SubscriptionPlan", id: "LIST" },
            ]
          : [{ type: "SubscriptionPlan", id: "LIST" }],
    }),
    getSubscriptionPlan: builder.query<SubscriptionPlanInterface, string>({
      query: (id) => `/api/panel/subscription-plans/${id}`,
      transformResponse: (response: GetSubscriptionPlanResponseInterface) =>
        response.data,
      providesTags: (result, error, id) => [{ type: "SubscriptionPlan", id }],
    }),
    storeSubscriptionPlan: builder.mutation<
      void,
      SaveSubscriptionPlanPropsInterface
    >({
      query: (body) => ({
        url: `/api/panel/subscription-plans`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "SubscriptionPlan", id: "LIST" }],
    }),
    updateSubscriptionPlan: builder.mutation<
      void,
      SaveDataWithId<SaveSubscriptionPlanPropsInterface>
    >({
      query: ({ id, ...body }) => ({
        url: `/api/panel/subscription-plans/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "SubscriptionPlan", id },
        { type: "SubscriptionPlan", id: "LIST" },
      ],
    }),
    deleteSubscriptionPlan: builder.mutation<void, string>({
      query: (id) => ({
        url: `/api/panel/subscription-plans/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "SubscriptionPlan", id: "LIST" }],
    }),
    searchSubscriptionPlans: builder.query<SearchSubscriptionPlan[], string>({
      query: (query) => `/api/panel/subscription-plans/search${query}`,
      transformResponse: (response: SearchSubscriptionPlansInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }) => ({ type: "SubscriptionPlan", id } as const),
              ),
              { type: "SubscriptionPlan", id: "Search" },
            ]
          : [{ type: "SubscriptionPlan", id: "Search" }],
    }),
  }),
})

export const {
  useDeleteSubscriptionPlanMutation,
  useGetSubscriptionPlanQuery,
  useGetSubscriptionPlansQuery,
  useStoreSubscriptionPlanMutation,
  useUpdateSubscriptionPlanMutation,
  useLazySearchSubscriptionPlansQuery,
} = requests
