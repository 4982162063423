import React, {
  ForwardedRef,
  forwardRef,
  Fragment,
  useEffect,
  useState,
} from "react"
import {
  Option as BaseOption,
  OptionProps,
  Select as BaseSelect,
  SelectProps,
  SelectRootSlotProps,
} from "@mui/base"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import classNames from "classnames"
import _ from "lodash"

type Props<TValue extends string | number, Multiple extends boolean> = {
  label?: string
  fullWidth?: boolean
} & SelectProps<TValue, Multiple>

const Select = forwardRef(function Select<
  TValue extends string | number,
  Multiple extends boolean,
>(props: Props<TValue, Multiple>, ref: ForwardedRef<HTMLButtonElement>) {
  const { label, fullWidth, ...rest } = props
  const id = _.uniqueId("select_")
  const [width, setWidth] = useState("320px")

  const buttonClassName = classNames(
    "flex flex-row justify-between py-1.5 px-3 items-center bg-gray-800 border border-gray-700 rounded md:min-w-[320px] min-h-[42px] transition-all duration-200 ease-in-out",
    {
      "w-full": fullWidth,
    },
  )

  useEffect(() => {
    const button = document.getElementById(id)

    if (button) {
      setWidth(`${button.offsetWidth}px`)
    }
  }, [id])

  const listBoxClassName = classNames(
    `bg-gray-800 border border-gray-700 text-m text-neutral-200 shadow-sm mt-2 rounded overflow-auto max-h-[300px]`,
  )

  return (
    <Fragment>
      {label && (
        <div className={"font-s pb-[6px] text-neutral-200"}>
          <label>{label}</label>
        </div>
      )}
      <BaseSelect
        slotProps={{
          root: {
            className: buttonClassName,
            id: id,
          },
          listbox: {
            className: listBoxClassName,
            style: {
              width,
            },
          },
          popper: {
            className: "z-[9999]",
          },
        }}
        ref={ref}
        slots={{
          root: Button,
        }}
        {...rest}
      />
    </Fragment>
  )
})

const Button = forwardRef(function Button<
  TValue extends {},
  Multiple extends boolean,
>(
  props: SelectRootSlotProps<TValue, Multiple>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const { ownerState, ...other } = props

  return (
    <button type={"button"} {...other} ref={ref}>
      {other.children}
      <FontAwesomeIcon className={"text-neutral-200"} icon={faChevronDown} />
    </button>
  )
})

export const Option = (
  props: OptionProps<string | number | boolean | null>,
) => {
  const classes = classNames(
    props.className,
    "cursor-pointer hover:bg-gray-700 p-2",
    {
      "bg-gray-900": props.disabled,
    },
  )

  return <BaseOption {...props} className={classes} />
}

export default Select
