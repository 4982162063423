import React from "react"
import {
  Input as BaseInput,
  InputProps,
  useFormControlContext,
} from "@mui/base"
import classNames from "classnames"
import { Label } from "@/resources/components/inputs/label"

type Props = InputProps & {
  error?: boolean
  label?: string
  fullWidth?: boolean
  size?: "xl"
}

export const Input = React.forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { error, fullWidth, label, size, ...rest } = props
    const formControlContext = useFormControlContext()

    const rootClassNames = classNames(
      "bg-gray-800 flex items-center border border-gray-700 rounded-md focus:ring-1 focus:ring-primary focus:border-transparent px-4 py-2",
      {
        "w-full": fullWidth,
        "border-red-500": formControlContext?.error || error,
        "h-[60px]": size === "xl",
      },
    )
    const inputClassName = classNames(
      "flex-grow md:max-w-full text-neutral-200 pr-1 bg-gray-800 outline-none placeholder-gray-25",
    )

    return (
      <div className={"w-full"}>
        {props.label && <Label label={props.label} />}
        <BaseInput
          ref={ref}
          {...rest}
          slotProps={{
            root: {
              className: rootClassNames,
            },
            input: {
              className: inputClassName,
            },
          }}
        />
      </div>
    )
  },
)
