import UserForm from "@/resources/views/users/parts/form"
import { BreadCrumbInterface } from "@/interfaces/base"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { useTranslation } from "react-i18next"
import { useStoreUserMutation } from "@/connectors/fansbay/requests/userRequests"
import { useState } from "react"
import { BackendValidationErrorInterface } from "@hypedevgroup/core"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { convertData } from "@/resources/views/users/utils"
import { StoreUserProps, UpdateUserProps } from "@/interfaces/user"
import StoreUserValidation from "@/validations/users/storeUserValidation"

const UserCreate = () => {
  const { t } = useTranslation()
  const [storeUser] = useStoreUserMutation()
  const navigate = useNavigate()
  const [errors, setErrors] =
    useState<BackendValidationErrorInterface<StoreUserProps>>()

  const handleSubmit = (data: StoreUserProps | UpdateUserProps) => {
    const formData = convertData(data)

    storeUser(formData)
      .unwrap()
      .then(() => {
        toast.success(t("users.create.success"))
        navigate("/users")
      })
      .catch((error) => {
        if (error.status === 422) {
          setErrors(error.data)
        }
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <UserForm
        validationClass={new StoreUserValidation()}
        errors={errors}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "users.breadcrumbs.module",
    url: "#",
  },
  {
    title: "users.breadcrumbs.users",
    url: "/users",
  },
  {
    title: "users.breadcrumbs.create",
    url: "/users/create",
  },
]

export default UserCreate
