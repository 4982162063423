import { TFunction } from "i18next"
import * as yup from "yup"
import {
  ProvisionRateType,
  SaveProvisionRatePropsInterface,
} from "@/interfaces/provisionRate"

export default class SaveProvisionRateValidation {
  static rules(
    t: TFunction,
  ): yup.ObjectSchema<SaveProvisionRatePropsInterface, yup.AnyObject> {
    return yup.object().shape({
      rate: yup
        .number()
        .typeError(t("validations.field_is_required"))
        .required(t("validations.field_is_required"))
        .min(0.01, t("validations.min_value", { value: 0.01 }))
        .max(100, t("validations.max_value", { value: 100 })),
      country_id: yup
        .number()
        .required(t("validations.field_is_required"))
        .notOneOf([0], t("validations.field_is_required")),
      active: yup.boolean().required(t("validations.field_is_required")),
      payment_provider_id: yup
        .number()
        .required(t("validations.field_is_required"))
        .notOneOf([0], t("validations.field_is_required")),
      type: yup
        .mixed<ProvisionRateType>()
        .required()
        .oneOf(["internal", "external"]),
    })
  }

  static defaultValues(): SaveProvisionRatePropsInterface {
    return {
      rate: "",
      country_id: 0,
      payment_provider_id: 0,
      active: true,
      type: "internal",
    }
  }
}
