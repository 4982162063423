import BreadCrumbs from "@/resources/components/breadCrumbs"
import { ButtonLink } from "@/resources/components/buttons/button"
import Card from "@/resources/components/cards/card"
import { BreadCrumbInterface } from "@/interfaces/base"
import { useTranslation } from "react-i18next"
import Table from "@/resources/views/taxRates/parts/table"
import { useHasPermission } from "@/utils/hooks/useHasPermission"
import { PermissionEnum } from "@/enums/permission"

export const TaxRateList = () => {
  const { t } = useTranslation()
  const hasPermission = useHasPermission()

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <div className={"flex flex-row-reverse gap-x-4"}>
        {hasPermission(PermissionEnum.TAX_RATES_STORE) && (
          <ButtonLink variant={"contained"} to={"/tax-rates/create"}>
            {t("tax_rates.list.create")}
          </ButtonLink>
        )}
      </div>
      <Card noPadding>
        <div
          className={
            "border-b border-gray-800 px-6 py-4 text-l font-medium text-neutral-200"
          }
        >
          <span>{t("tax_rates.list.title")}</span>
        </div>
        <Table />
      </Card>
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "tax_rates.breadcrumbs.tax_rates",
    url: "/tax-rates",
  },
]
