import { LightboxPropsInterface } from "@/resources/components/lightbox/lightbox.types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import React, { Fragment } from "react"
import { Container as ModalContainer } from "react-modal-promise"
import { lightboxModal } from "@/resources/components/lightbox/modal"

export const Lightbox = <T extends object>(
  props: LightboxPropsInterface<T>,
) => {
  const { images, type, renderImage, renderThumbnail, onDelete } = props

  const onImageClick = (image: T) => {
    lightboxModal({ image: renderImage(image) })
  }

  return (
    <Fragment>
      <div className={"flex flex-col gap-6 lg:flex-row"}>
        {images.map((image, index) => (
          <div className={"relative"} key={index}>
            {onDelete && (
              <div
                className={"absolute -right-3 -top-3 z-50 cursor-pointer"}
                onClick={() => onDelete(image)}
              >
                <div>
                  <div
                    className={
                      "flex h-6 w-6 items-center justify-center rounded-full bg-red-600"
                    }
                  >
                    <FontAwesomeIcon icon={faTimes} className={"text-white"} />
                  </div>
                </div>
              </div>
            )}
            {type(image) === "image" ? (
              <figure
                className={"max-h-[300px] max-w-[300px] cursor-pointer"}
                style={{
                  background: "black",
                }}
                onClick={() => onImageClick(image)}
              >
                <img
                  className={
                    "max-h-[300px] max-w-[300px] transition-all hover:opacity-50"
                  }
                  src={renderThumbnail(image)}
                  alt={""}
                />
              </figure>
            ) : (
              <video
                src={renderImage(image)}
                controls
                controlsList={"nodownload"}
                className={"max-w-[300px]"}
              />
            )}
          </div>
        ))}
      </div>
      <ModalContainer />
    </Fragment>
  )
}
