import { TFunction } from "i18next"
import * as yup from "yup"
import {
  PageContentType,
  SavePageContentPropsInterface,
} from "@/interfaces/page"

export default class SavePageContentsValidation {
  static rules(
    t: TFunction,
  ): yup.ObjectSchema<SavePageContentPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      data: yup
        .array()
        .of(
          yup.object().shape({
            title: yup.string().nullable(),
            content: yup.string().required(t("validations.field_is_required")),
            type: yup
              .mixed<PageContentType>()
              .oneOf(["text", "collapse"])
              .required(t("validations.field_is_required")),
            id: yup.number().nullable(),
          }),
        )
        .required(t("validations.field_is_required"))
        .min(1, t("validations.field_is_required")),
    })
  }

  static defaultValues(): SavePageContentPropsInterface {
    return {
      data: [],
    }
  }
}
