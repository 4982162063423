import { SaveRoleInterface } from "@/interfaces/role"
import { useValidation } from "@hypedevgroup/core"
import SaveRoleValidation from "@/validations/roles/saveRoleValidation"
import { useTranslation } from "react-i18next"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { Fragment, useEffect } from "react"
import Card from "@/resources/components/cards/card"
import { FormControl } from "@mui/base"
import { Input } from "@/resources/components/inputs/input"
import { FormHelperText } from "@/resources/components/inputs/formHelperText"
import { Button } from "@/resources/components/buttons/button"
import { FormInterface } from "@/interfaces/base"
import { useSearchPermissionsQuery } from "@/connectors/fansbay/requests/permissionRequests"
import { Checkbox } from "@/resources/components/inputs/checkbox"

export const RoleForm = ({
  onSubmit,
  data,
  errors: formErrors,
}: FormInterface<SaveRoleInterface>) => {
  const { t } = useTranslation()
  const { schema, defaultValues, resolveValidationErrors } = useValidation(
    SaveRoleValidation,
    t,
  )
  const { data: permissions = [] } = useSearchPermissionsQuery()

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    getValues,
  } = useForm<SaveRoleInterface>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  })

  useEffect(() => {
    if (formErrors) {
      const errors = resolveValidationErrors(formErrors)

      Object.keys(errors).forEach((key) => {
        const keyName = key as keyof SaveRoleInterface

        setError(keyName, {
          type: "manual",
          message: errors[keyName],
        })
      })
    }
  }, [formErrors, resolveValidationErrors, setError])

  useEffect(() => {
    if (data) {
      Object.keys(data).forEach((key) => {
        const keyName = key as keyof SaveRoleInterface
        setValue(keyName, data[keyName])
      })
    }
  }, [data, setValue])

  const handleChangePermissions = (permission: number) => {
    const permissions = getValues("permissions")

    if (permissions.includes(permission)) {
      setValue(
        "permissions",
        permissions.filter((item) => item !== permission),
      )
    } else {
      setValue("permissions", [...permissions, permission])
    }
  }

  return (
    <div className={"mb-8 flex flex-col gap-8"}>
      <Card className={"flex w-full"}>
        <div className={"flex w-full flex-col gap-16 md:flex-row"}>
          <div className={"md:w-[150px]"}>
            <span>{t("forms.basic_data")}</span>
          </div>
          <div className={"w-full md:w-[400px]"}>
            <div className={"flex flex-col gap-y-8"}>
              <Controller
                render={({ field }) => (
                  <FormControl {...field} error={!!errors.name}>
                    <Input
                      label={t("labels.role_name")}
                      placeholder={t("placeholders.role_name")}
                    />
                    <FormHelperText message={errors.name?.message} />
                  </FormControl>
                )}
                name={"name"}
                control={control}
              />
              <Controller
                name={"permissions"}
                control={control}
                render={({ field }) => (
                  <Fragment>
                    {permissions.map((permission) => (
                      <Checkbox
                        key={permission.id}
                        label={permission.name_translated}
                        checked={field.value.includes(permission.id)}
                        onChange={() => handleChangePermissions(permission.id)}
                      />
                    ))}
                  </Fragment>
                )}
              />
            </div>
          </div>
        </div>
      </Card>
      <div>
        <Button variant={"contained"} onClick={handleSubmit(onSubmit)}>
          {t("buttons.save")}
        </Button>
      </div>
    </div>
  )
}
