import { TFunction } from "i18next"

export const planName = (
  t: TFunction,
  duration: number,
  durationUnit: string,
) =>
  `${t("plans.plan_name", { duration: duration })} ${t(
    `plans.duration_unit.${durationUnit}`,
    { count: duration },
  )}`
