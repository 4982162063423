import { useAppSelector } from "@/app/hooks"
import { getIsAuthorized } from "@/states/authState"
import { Navigate, Outlet } from "react-router-dom"
import Layout from "@/resources/components/layouts/auth/layout"

const AuthRoutes = () => {
  const isAuthorized = useAppSelector(getIsAuthorized)

  return isAuthorized ? (
    <Navigate to={"/dashboard"} />
  ) : (
    <Layout>
      <Outlet />
    </Layout>
  )
}

export default AuthRoutes
