import { create, InstanceProps } from "react-modal-promise"
import Modal from "@/resources/components/modals/modal"
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons"
import React, { useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@/resources/components/buttons/button"
import { useTranslation } from "react-i18next"

type Props = {
  type: "danger" | "info" | "success"
  text: string
}

const Confirm: React.FC<InstanceProps<unknown> & Props> = (props) => {
  const { isOpen, onReject, onResolve } = props
  const { t } = useTranslation()
  const { icon, color } = useRef(iconType(props.type)).current

  return (
    <Modal open={isOpen} onClose={onReject}>
      <Modal.Content>
        <div className={"flex flex-col items-center gap-y-4"}>
          <div>
            <FontAwesomeIcon icon={icon} className={color} size={"3x"} />
          </div>
          <span className={"text-xl font-semibold"}>{props.text}</span>
          <div className={"flex flex-row gap-x-2 pt-6"}>
            <Button
              variant={"contained"}
              className={"bg-red-600"}
              onClick={onReject}
            >
              {t("buttons.cancel")}
            </Button>
            <Button
              variant={"contained"}
              className={"bg-green-600"}
              onClick={onResolve}
            >
              {t("buttons.confirm")}
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}

const iconType = (
  type: "danger" | "info" | "success",
): { icon: IconDefinition; color: string } => {
  switch (type) {
    case "danger":
      return { icon: faCircleExclamation, color: "text-red-600" }
    case "info":
      return { icon: faCircleInfo, color: "text-primary" }
    case "success":
      return { icon: faCircleCheck, color: "text-green-600" }
  }
}

export const confirmModal = create(Confirm)
