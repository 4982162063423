import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import { SaveDataWithId } from "@/types/base"
import {
  GetProvisionRateResponseInterface,
  GetProvisionRatesResponseInterface,
  ProvisionRateInterface,
  SaveProvisionRatePropsInterface,
} from "@/interfaces/provisionRate"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getProvisionRates: builder.query<
      GetProvisionRatesResponseInterface["data"],
      string
    >({
      query: (query) => `/api/panel/provision-rates${query}`,
      transformResponse: (response: GetProvisionRatesResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }) => ({ type: "ProvisionRate", id } as const),
              ),
              { type: "ProvisionRate", id: "LIST" },
            ]
          : [{ type: "ProvisionRate", id: "LIST" }],
    }),
    getProvisionRate: builder.query<ProvisionRateInterface, string>({
      query: (id) => `/api/panel/provision-rates/${id}`,
      transformResponse: (response: GetProvisionRateResponseInterface) =>
        response.data,
      providesTags: (response, error, id) => [{ type: "ProvisionRate", id }],
    }),
    storeProvisionRate: builder.mutation<void, SaveProvisionRatePropsInterface>(
      {
        query: (body) => ({
          method: "POST",
          url: "/api/panel/provision-rates",
          body,
        }),
        invalidatesTags: [{ type: "ProvisionRate", id: "LIST" }],
      },
    ),
    updateProvisionRate: builder.mutation<
      void,
      SaveDataWithId<SaveProvisionRatePropsInterface>
    >({
      query: ({ id, ...body }) => ({
        method: "PUT",
        url: `/api/panel/provision-rates/${id}`,
        body,
      }),
      invalidatesTags: (response, error, { id }) => [
        { type: "ProvisionRate", id },
        { type: "ProvisionRate", id: "LIST" },
      ],
    }),
    deleteProvisionRate: builder.mutation<void, string>({
      query: (id) => ({
        method: "DELETE",
        url: `/api/panel/provision-rates/${id}`,
      }),
    }),
  }),
})

export const {
  useGetProvisionRateQuery,
  useGetProvisionRatesQuery,
  useStoreProvisionRateMutation,
  useUpdateProvisionRateMutation,
  useDeleteProvisionRateMutation,
} = requests
