import React from "react"
import { Input } from "@/resources/components/inputs/input"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

type Props = {
  onChange: (value: string) => void
  placeholder: string
  value: string | undefined | number
}

export const SearchByDate = (props: Props) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.value)
  }

  return (
    <Input
      type={"date"}
      value={props.value}
      onChange={onChange}
      placeholder={props.placeholder}
      endAdornment={
        <FontAwesomeIcon
          icon={faTimes}
          className={"cursor-pointer"}
          onClick={() => props.onChange("")}
        />
      }
    />
  )
}
