import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  GetPostResponseInterface,
  GetPostsResponseInterface,
  PostInterface,
} from "@/interfaces/post"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getPosts: builder.query<GetPostsResponseInterface["data"], string>({
      query: (query) => `/api/panel/posts${query}`,
      transformResponse: (response: GetPostsResponseInterface) => response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: "Post", id } as const)),
              { type: "Post", id: "LIST" },
            ]
          : [{ type: "Post", id: "LIST" }],
    }),
    getPost: builder.query<PostInterface, string>({
      query: (id) => `/api/panel/posts/${id}`,
      transformResponse: (response: GetPostResponseInterface) => response.data,
      providesTags: (result, error, id) => [{ type: "Post", id: id }],
    }),
    deletePost: builder.mutation<void, string>({
      query: (id) => ({
        url: `/api/panel/posts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "Post", id: "LIST" }],
    }),
  }),
})

export const { useGetPostsQuery, useGetPostQuery, useDeletePostMutation } =
  requests
