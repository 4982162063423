import { useTranslation } from "react-i18next"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { ButtonLink } from "@/resources/components/buttons/button"
import Card from "@/resources/components/cards/card"
import { BreadCrumbInterface } from "@/interfaces/base"
import Table from "@/resources/views/plans/parts/table"
import { baseQuery } from "@/utils/query"
import { Search } from "@/resources/views/plans/parts/search/search"
import { useMemo, useState } from "react"

export type SearchParameters = {
  user_id: number | string | undefined
  price: number | string | undefined
  duration: number | string | undefined
}

export const SubscriptionPlanList = () => {
  const { t } = useTranslation()
  const [searchParameters, setSearchParameters] = useState<SearchParameters>()
  const query = useMemo(() => {
    return baseQuery
      .where("user_id", searchParameters?.user_id)
      .where("price", searchParameters?.price)
      .where("duration", searchParameters?.duration)
  }, [searchParameters])

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <div className={"flex flex-row-reverse"}>
        <ButtonLink variant={"contained"} to={"/subscription-plans/create"}>
          {t("default_plans.list.create")}
        </ButtonLink>
      </div>
      <Card noPadding>
        <div
          className={
            "border-b border-gray-800 px-6 py-4 text-l font-medium text-neutral-200"
          }
        >
          <span>{t("subscription_plans.list.title")}</span>
        </div>
        <div
          className={"flex flex-col gap-y-4 border-b border-gray-800 px-6 py-4"}
        >
          <Search
            onUpdateParameters={(parameters) => setSearchParameters(parameters)}
          />
        </div>
        <Table query={query} />
      </Card>
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "subscription_plans.breadcrumbs.subscription_plans",
    url: "/subscription-plans",
  },
]
