import { useNavigate, useParams } from "react-router-dom"
import { useRedirectWithError } from "@/utils/hooks/useRedirectWithError"
import { BreadCrumbInterface } from "@/interfaces/base"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import {
  useGetUserQuery,
  useUpdateUserMutation,
} from "@/connectors/fansbay/requests/userRequests"
import { convertData } from "@/resources/views/users/utils"
import UserForm from "@/resources/views/users/parts/form"
import { useEffect, useState } from "react"
import { BackendValidationErrorInterface } from "@hypedevgroup/core"
import { UpdateUserValidation } from "@/validations/users/updateUserValidation"
import { StoreUserProps, UpdateUserProps } from "@/interfaces/user"
import { format } from "date-fns"

export const UserEdit = () => {
  const { id } = useParams<{ id: string }>()
  const { data, error } = useGetUserQuery(id!)
  const [update] = useUpdateUserMutation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [errors, setErrors] =
    useState<BackendValidationErrorInterface<UpdateUserProps>>()
  const [formData, setFormData] = useState<UpdateUserProps>()

  useRedirectWithError(error, "/error")

  useEffect(() => {
    if (data) {
      const {
        avatar,
        cover,
        bio,
        role_id,
        email_verified_at,
        date_of_birth,
        ...rest
      } = data

      setFormData({
        ...rest,
        bio: bio?.toString(),
        role_id: role_id ? role_id : 0,
        date_of_birth: date_of_birth
          ? format(new Date(date_of_birth), "yyyy-MM-dd")
          : "",
      })
    }
  }, [data])

  const handleSubmit = (data: UpdateUserProps | StoreUserProps) => {
    const formData = convertData(data)
    formData.append("_method", "PUT")

    update({
      data: formData,
      id: id as string,
    })
      .unwrap()
      .then(() => {
        toast.success(t("users.edit.success"))
        navigate("/users")
      })
      .catch((error) => {
        if (error.status === 422) {
          setErrors(error.data)
        }
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      <UserForm
        validationClass={new UpdateUserValidation()}
        errors={errors}
        additionalData={data}
        data={formData}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "modules.breadcrumbs.setting",
    url: "#",
  },
  {
    title: "user_groups.breadcrumbs.user_groups",
    url: "/user-groups",
  },
  {
    title: "user_groups.breadcrumbs.edit",
    url: `/user-groups/${id}/edit`,
  },
]
