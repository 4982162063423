import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import React from "react"

export const Loading = ({ isLoading = true }: { isLoading: boolean }) => {
  if (isLoading) {
    return (
      <div
        className={
          "absolute left-0 top-0 z-[9999] h-screen w-screen backdrop-blur-lg"
        }
      >
        <div className={"flex h-full items-center justify-center"}>
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            className={"text-white"}
            size={"2xl"}
          />
        </div>
      </div>
    )
  }

  return <></>
}
