import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import {
  ListType,
  TransferListProps,
  TransferListState,
} from "@/resources/components/transferList/transferList.types"
import { Checkbox } from "@/resources/components/inputs/checkbox"
import { Button } from "@/resources/components/buttons/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons"
import _ from "lodash"
import { useBreakpoint } from "@hypedevgroup/core"

export const TransferList = <T extends {}>({
  list: sourceList,
  selected,
  onChange,
  titles,
  render,
  indexKey,
}: TransferListProps<T>) => {
  const [
    listTitle = "transfer_list.list_title",
    selectedTitle = "transfer_list.selected_title",
  ] = titles
  const [list, setList] = useState<TransferListState<T>[]>([])
  const [selectedList, setSelectedList] = useState<TransferListState<T>[]>([])
  const isBreakpoint = useBreakpoint()

  useEffect(() => {
    setList(
      sourceList
        .map((element) => ({
          ...element,
          _selected: false,
        }))
        .filter(
          (element) =>
            selected.findIndex(
              (item) => item[indexKey] === element[indexKey],
            ) === -1,
        ),
    )
  }, [selected, sourceList])

  useEffect(() => {
    setSelectedList(
      selected.map((element) => ({ ...element, _selected: false })),
    )
  }, [selected])

  const handleChange = (element: TransferListState<T>) => {
    const newList = list.map((item) => {
      if (item === element) {
        item._selected = !item._selected
      }
      return item
    })
    setList(newList)
  }

  const handleChangeSelected = (element: TransferListState<T>) => {
    const newList = selectedList.map((item) => {
      if (item === element) {
        item._selected = !item._selected
      }
      return item
    })
    setSelectedList(newList)
  }

  const handleTransferToSelected = () => {
    const selected = [
      ...list
        .filter((element) => element._selected)
        .map((element) => ({ ...element, _selected: false })),
      ...selectedList,
    ]

    setList(
      list
        .filter((element) => !element._selected)
        .map((element) => ({ ...element, _selected: false })),
    )
    setSelectedList(selected)
    onChange(selected)
  }

  const handleTransferToAvailable = () => {
    const availableList = [
      ...selectedList
        .filter((element) => element._selected)
        .map((element) => ({ ...element, _selected: false })),
      ...list,
    ]

    const selected = selectedList
      .filter((element) => !element._selected)
      .map((element) => ({ ...element, _selected: false }))

    setSelectedList(selected)

    setList(availableList)
    onChange(selected)
  }

  const handleSelectAllInSelected = () => {
    setSelectedList(
      selectedList.map((element) => ({ ...element, _selected: true })),
    )
  }

  const handleSelectAllInAvailable = () => {
    setList(list.map((element) => ({ ...element, _selected: true })))
  }

  return (
    <div className={"flex flex-col gap-4 lg:flex-row"}>
      <List
        length={list.length}
        title={listTitle}
        onSelectedAll={handleSelectAllInAvailable}
        checkedAll={list.length === _.countBy(list, "_selected").true}
      >
        <div
          className={"flex max-h-[300px] flex-col gap-2 overflow-y-auto p-4"}
        >
          {list.map((element, index) => (
            <div className={"flex gap-2"} key={index}>
              <Checkbox
                checked={element._selected}
                onChange={() => handleChange(element)}
              />
              <span>{render(element)}</span>
            </div>
          ))}
        </div>
      </List>
      <div className={"flex flex-col justify-center gap-4 px-4 lg:px-0"}>
        <Button variant={"contained"} onClick={handleTransferToSelected}>
          <FontAwesomeIcon
            icon={isBreakpoint("lg") ? faChevronRight : faChevronDown}
          />
        </Button>
        <Button variant={"contained"} onClick={handleTransferToAvailable}>
          <FontAwesomeIcon
            icon={isBreakpoint("lg") ? faChevronLeft : faChevronUp}
          />
        </Button>
      </div>
      <List
        length={selectedList.length}
        title={selectedTitle}
        onSelectedAll={handleSelectAllInSelected}
        checkedAll={
          selectedList.length === _.countBy(selectedList, "_selected").true
        }
      >
        <div
          className={"flex max-h-[300px] flex-col gap-2 overflow-y-auto p-4"}
        >
          {selectedList.map((element, index) => (
            <div className={"flex gap-2"} key={index}>
              <Checkbox
                checked={element._selected}
                onChange={() => handleChangeSelected(element)}
              />
              <span>{render(element)}</span>
            </div>
          ))}
        </div>
      </List>
    </div>
  )
}

const List = ({
  onSelectedAll,
  children,
  title,
  length,
  checkedAll,
}: ListType) => {
  const { t } = useTranslation()

  return (
    <div className={"m-4 flex flex-col rounded border border-gray-700"}>
      <div
        className={"flex justify-between gap-4 border-b border-gray-700 p-4"}
      >
        <div className={"flex gap-2"}>
          <Checkbox
            checked={checkedAll}
            className={"select-all"}
            onChange={onSelectedAll}
          />
          <span>{t(title)}</span>
        </div>
        <span>{t("transfer_list.items", { count: length })}</span>
      </div>
      {children}
    </div>
  )
}
