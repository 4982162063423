import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  CountryInterface,
  SearchCountriesResponseInterface,
} from "@/interfaces/country"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    searchCountries: builder.query<CountryInterface[], void>({
      query: () => "/api/panel/countries/search",
      transformResponse: (response: SearchCountriesResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Country", id } as const)),
              { type: "Country", id: "SEARCH" },
            ]
          : [{ type: "Country", id: "SEARCH" }],
    }),
  }),
})

export const { useSearchCountriesQuery } = requests
