import { useEffect, useState } from "react"
import { SearchParameters } from "@/resources/views/payments"
import { SearchByUser } from "@/resources/components/inputs/search/searchByUser"
import { PaymentTypeEnum } from "@/enums/paymentType"
import { PaymentStatusEnum } from "@/enums/paymentStatus"
import { SearchByStatus } from "@/resources/views/payments/parts/search/searchByStatus"
import { SearchByType } from "@/resources/views/payments/parts/search/searchByType"
import { SearchByCurrency } from "@/resources/components/inputs/search/searchByCurrency"

type Props = {
  onUpdateParameters: (parameters: SearchParameters) => void
}

export const Search = (props: Props) => {
  const [userId, setUserId] = useState<number | string | undefined>()
  const [type, setType] = useState<PaymentTypeEnum>()
  const [status, setStatus] = useState<PaymentStatusEnum>()
  const [currencyId, setCurrencyId] = useState<number | string | undefined>()

  useEffect(() => {
    props.onUpdateParameters({
      user_id: userId,
      currency_id: currencyId,
      type,
      status,
    })
  }, [userId, currencyId, status, type])

  return (
    <div className={"flex w-full"}>
      <div className={"grid gap-x-4 md:grid-cols-4"}>
        <SearchByUser onChange={(value) => setUserId(value)} />
        <SearchByStatus onChange={(value) => setStatus(value)} />
        <SearchByType onChange={(value) => setType(value)} />
        <SearchByCurrency onChange={(value) => setCurrencyId(value)} />
      </div>
    </div>
  )
}
