import React, { useEffect, useMemo, useState } from "react"
import BaseTable from "@/resources/components/table/table"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { TFunction } from "i18next"
import { ColumnSetting } from "@/resources/components/table/columnSetting"
import { Query, usePagination } from "@hypedevgroup/core"
import RowAction from "@/resources/components/table/rowAction"
import { ChatMessageInterface } from "@/interfaces/chatMessage"
import { useGetChatMessagesQuery } from "@/connectors/fansbay/requests/chatMessageRequests"

const columnHelper = createColumnHelper<ChatMessageInterface>()

const columns = (t: TFunction) => [
  columnHelper.accessor("sender.username", {
    id: "sender",
    header: t("labels.sender"),
  }),
  columnHelper.accessor("receiver.username", {
    id: "receiver",
    header: t("labels.receiver"),
  }),
  columnHelper.accessor("human_date", {
    id: "human_date",
    header: t("labels.human_date"),
  }),
  columnHelper.display({
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => <RowAction url={`/messages/${row.original.id}`} />,
  }),
]

const Table = ({ query: baseQuery }: { query: Query }) => {
  const { t } = useTranslation()
  const [data, setData] = useState<ChatMessageInterface[]>([])
  const [pagination, setPagination] = usePagination()
  const query = useMemo(
    () => baseQuery.limit(pagination.pageSize).page(pagination.pageIndex),
    [baseQuery, pagination.pageIndex, pagination.pageSize],
  )
  const { data: apiData } = useGetChatMessagesQuery(query.url())

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const table = useReactTable({
    columns: columns(t),
    data: data,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  return (
    <div className={"flex flex-col"}>
      <div className={"flex flex-row-reverse px-8 py-4"}>
        <ColumnSetting table={table} />
      </div>
      <BaseTable pagination={apiData?.pagination} table={table} />
    </div>
  )
}

export default Table
