import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  AgreementInterface,
  GetAgreementResponseInterface,
  GetAgreementsResponseInterface,
} from "@/interfaces/agreement"

export const {
  useStoreAgreementMutation,
  useGetAgreementsQuery,
  useDeleteAgreementMutation,
  useGetAgreementQuery,
  useUpdateAgreementMutation,
} = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getAgreements: builder.query<
      GetAgreementsResponseInterface["data"],
      string
    >({
      query: (query) => `/api/panel/agreements${query}`,
      transformResponse: (response: GetAgreementsResponseInterface) =>
        response.data,
      providesTags: [{ type: "Agreement", id: "LIST" }],
    }),
    storeAgreement: builder.mutation<void, FormData>({
      query: (data) => ({
        url: "/api/panel/agreements",
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: [{ type: "Agreement", id: "LIST" }],
    }),
    deleteAgreement: builder.mutation<void, number>({
      query: (id) => ({
        url: `/api/panel/agreements/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Agreement", id: "LIST" }],
    }),
    getAgreement: builder.query<AgreementInterface, string | number>({
      query: (id) => `/api/panel/agreements/${id}`,
      transformResponse: (response: GetAgreementResponseInterface) =>
        response.data,
      providesTags: (_, error, id) => [{ type: "Agreement", id: id }],
    }),
    updateAgreement: builder.mutation<
      void,
      { id: number | string; data: FormData }
    >({
      query: ({ id, data }) => ({
        url: `/api/panel/agreements/${id}`,
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: (_, error, { id }) => [
        { type: "Agreement", id: id },
        { type: "Agreement", id: "LIST" },
      ],
    }),
  }),
})
