import { flexRender, Table as TableType } from "@tanstack/react-table"
import { PaginationInterface } from "@/interfaces/base"
import { Pagination } from "@/resources/components/table/pagination"

type Props<T> = {
  table: TableType<T>
  pagination?: PaginationInterface
}

const Table = <T extends {}>(props: Props<T>) => {
  const { table, pagination } = props

  return (
    <div className={"relative overflow-x-auto"}>
      <table className={"w-full table-auto text-left"}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className={"border-b border-b-gray-700"}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    className={
                      "bg-gray-800 px-8 py-4 text-xs font-medium text-gray-25"
                    }
                    key={header.id}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </div>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody className={"text-s font-medium text-gray-700"}>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id} className={"border-b border-b-gray-800"}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      key={cell.id}
                      className={"px-8 py-6 text-s text-gray-25"}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {pagination && (
        <div className={"px-8 py-6"}>
          <Pagination
            onPageChange={table.setPageIndex}
            totalPages={pagination.total_pages}
          />
        </div>
      )}
    </div>
  )
}

export default Table
