import {
  getAvailableStatuses,
  WithdrawStatusEnum,
} from "@/enums/widthdrawStatus"
import React, { FC, useEffect } from "react"
import { create, InstanceProps } from "react-modal-promise"
import Modal from "@/resources/components/modals/modal"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { useValidation } from "@hypedevgroup/core"
import UpdateWithdrawStatusValidation from "@/validations/withdraws/updateWithdrawStatusValidation"
import { Controller, useForm } from "react-hook-form"
import { UpdateStatusPropsInterface } from "@/interfaces/withdraw"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormControl } from "@mui/base"
import Select, { Option } from "@/resources/components/inputs/select"
import { Input } from "@/resources/components/inputs/input"
import { Button } from "@/resources/components/buttons/button"
import { useUpdateWithdrawMutation } from "@/connectors/fansbay/requests/withdrawRequests"

type Props = {
  id: string
  status: WithdrawStatusEnum
}

const WithdrawEdit: FC<InstanceProps<unknown> & Props> = (props) => {
  const { status, id, isOpen, onReject, onResolve } = props
  const { t } = useTranslation()
  const { schema, defaultValues } = useValidation(
    UpdateWithdrawStatusValidation,
    t,
  )
  const [updateStatus] = useUpdateWithdrawMutation()

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setValue,
  } = useForm<UpdateStatusPropsInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    setValue("status", status)
  }, [status, setValue])

  const watchStatus = watch("status")

  const onSubmit = (data: UpdateStatusPropsInterface) => {
    if (data.status !== status) {
      updateStatus({
        ...data,
        id,
      })
        .unwrap()
        .then(() => {
          onResolve()
        })
    }

    onResolve()
  }

  return (
    <Modal open={isOpen} onClose={onReject}>
      <Modal.Content>
        <div className={"flex flex-col gap-y-4"}>
          <div className={"flex items-center justify-between"}>
            <span className={"text-xl font-medium text-neutral-200"}>
              {t("withdraws.edit.title")}
            </span>
            <FontAwesomeIcon
              icon={faTimes}
              className={"cursor-pointer"}
              onClick={onReject}
              size={"lg"}
            />
          </div>
          <div className={"flex flex-col gap-y-4"}>
            <Controller
              render={({ field }) => (
                <FormControl error={!!errors.status}>
                  <Select
                    value={field.value}
                    defaultValue={0}
                    fullWidth
                    onChange={(_, value) => field.onChange(value)}
                  >
                    <Option value={0}>
                      {t("default_options.select_status")}
                    </Option>
                    {getAvailableStatuses(status)?.map((value, key) => (
                      <Option key={key} value={value}>
                        {t(`withdraws.statuses.${value}`)}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              )}
              name={"status"}
              control={control}
            />
            {watchStatus === WithdrawStatusEnum.DECLINED && (
              <Controller
                render={({ field }) => (
                  <FormControl {...field} error={!!errors.status_message}>
                    <Input
                      placeholder={t("placeholders.enter_message")}
                      multiline
                      label={t("labels.message")}
                      rows={4}
                    />
                  </FormControl>
                )}
                name={"status_message"}
                control={control}
              />
            )}
            <Button variant={"contained"} onClick={handleSubmit(onSubmit)}>
              {t("buttons.save")}
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export const withdrawEditModal = create(WithdrawEdit)
