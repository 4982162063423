import * as yup from "yup"
import { TFunction } from "i18next"
import ReactQuill from "react-quill"
import { GenderEnum } from "@/enums/gender"
import { StoreUserProps } from "@/interfaces/user"
import { ValidationInterface } from "@hypedevgroup/core"

class StoreUserValidation implements ValidationInterface<StoreUserProps> {
  rules(t: TFunction): yup.ObjectSchema<StoreUserProps, yup.AnyObject> {
    return yup.object().shape({
      username: yup.string().required(t("validations.field_is_required")),
      email: yup
        .string()
        .email(t("validations.field_must_be_email"))
        .required(t("validations.field_is_required"))
        .trim(),
      password: yup
        .string()
        .required(t("validations.field_is_required"))
        .min(6, t("validations.field_must_be_at_least_characters", { min: 6 })),
      password_confirmation: yup
        .string()
        .required(t("validations.field_is_required"))
        .oneOf([yup.ref("password")], t("validations.passwords_must_match")),
      gender: yup
        .mixed<GenderEnum>()
        .oneOf(Object.values(GenderEnum))
        .required(t("validations.field_is_required")),
      location: yup.string().nullable(),
      website: yup.string().nullable(),
      bio: yup.mixed<ReactQuill.Value>(),
      role_id: yup
        .number()
        .required(t("validations.field_is_required"))
        .notOneOf([0], t("validations.field_is_required")),
      avatar: yup.mixed<File>().nullable(),
      cover: yup.mixed<File>().nullable(),
      public_account: yup
        .boolean()
        .required(t("validations.field_is_required")),
      open_profile: yup.boolean().required(t("validations.field_is_required")),
      verified_at: yup.string().nullable(),
      date_of_birth: yup.string().nullable(),
      country_id: yup
        .number()
        .required(t("validations.field_is_required"))
        .notOneOf([0], t("validations.field_is_required")),
    })
  }

  defaultValues(): StoreUserProps {
    return {
      username: "",
      email: "",
      password: "",
      password_confirmation: "",
      gender: GenderEnum.MALE,
      location: "",
      website: "",
      cover: null,
      avatar: null,
      role_id: 0,
      public_account: false,
      open_profile: false,
      bio: "",
      verified_at: "",
      date_of_birth: "",
      country_id: 0,
    }
  }
}

export default StoreUserValidation
