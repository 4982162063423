import { useAppSelector } from "@/app/hooks"
import { getIsAuthorized, getPermissions } from "@/states/authState"
import { matchRoutes, Navigate, Outlet, useLocation } from "react-router-dom"
import Layout from "@/resources/components/layouts/app/layout"
import React, { useCallback } from "react"
import { PrivateRouterPropsInterface } from "./privateRouter.types"

const PrivateRouter = (props: PrivateRouterPropsInterface) => {
  const isAuthorized = useAppSelector(getIsAuthorized)
  const userPermissions = useAppSelector(getPermissions)
  const { pathname } = useLocation()
  const match = matchRoutes(props.routes, pathname)
  const isAllowed = useCallback(() => {
    if (!match) return false

    const { route } = match[0]
    const { permissions } = route

    return (
      permissions.length === 0 ||
      userPermissions.some((p) => permissions.includes(p))
    )
  }, [match, userPermissions])

  return isAuthorized && isAllowed() ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/login" />
  )
}

export default PrivateRouter
