import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  GetPermissionsResponseInterface,
  PermissionInterface,
} from "@/interfaces/permission"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    searchPermissions: builder.query<PermissionInterface[], void>({
      query: () => "/api/panel/permissions/search",
      transformResponse: (response: GetPermissionsResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Role", id } as const)),
              { type: "Permission", id: "SEARCH" },
            ]
          : [{ type: "Permission", id: "SEARCH" }],
    }),
  }),
})

export const { useSearchPermissionsQuery } = requests
