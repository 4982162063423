import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  GetSettingResponseInterface,
  GetSettingsResponseInterface,
  SettingInterface,
  UpdateSettingPropsInterface,
} from "@/interfaces/setting"
import { SaveDataWithId } from "@/types/base"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query<GetSettingsResponseInterface["data"], void>({
      query: () => `/api/panel/settings`,
      transformResponse: (response: GetSettingsResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Setting", id } as const)),
              { type: "Setting", id: "LIST" },
            ]
          : [{ type: "Setting", id: "LIST" }],
    }),
    getSetting: builder.query<SettingInterface, string>({
      query: (id) => `/api/panel/settings/${id}`,
      transformResponse: (response: GetSettingResponseInterface) =>
        response.data,
      providesTags: (result, error, id) => [{ type: "Setting", id }],
    }),
    updateSetting: builder.mutation<
      void,
      SaveDataWithId<UpdateSettingPropsInterface>
    >({
      query: ({ id, ...body }) => ({
        url: `/api/panel/settings`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Setting", id },
        { type: "Setting", id: "LIST" },
      ],
    }),
  }),
})

export const {
  useGetSettingsQuery,
  useGetSettingQuery,
  useUpdateSettingMutation,
} = requests
