import { useParams } from "react-router-dom"
import {
  useGetWalletsQuery,
  useUpdateBalanceMutation,
} from "@/connectors/fansbay/requests/userRequests"
import React, { Fragment, useEffect, useState } from "react"
import { Input } from "@/resources/components/inputs/input"
import { useTranslation } from "react-i18next"
import { WalletSlugEnum } from "@/enums/walletSlug"
import { Button } from "@/resources/components/buttons/button"
import { walletDescriptionModal } from "@/resources/views/users/details/walletDescription"
import { toast } from "react-toastify"
import { Container as ModalContainer } from "react-modal-promise"

export const Wallets = () => {
  const { id } = useParams<{ id: string }>()
  const { data } = useGetWalletsQuery(id!)
  const [wallets, setWallets] = useState<
    { id: number; name: WalletSlugEnum; balance: string; currency: string }[]
  >([])
  const { t } = useTranslation()
  const [updateBalance] = useUpdateBalanceMutation()

  useEffect(() => {
    if (data) {
      setWallets(
        data.map((wallet) => ({
          id: wallet.id,
          name: wallet.slug,
          balance: wallet.balance.toString(),
          currency: wallet.currency,
        })),
      )
    }
  }, [data])

  const onChangeBalance = (value: string, name: WalletSlugEnum) => {
    if (name !== WalletSlugEnum.DEFAULT) {
      return
    }

    const walletIndex = wallets.findIndex((wallet) => wallet.name === name)

    if (walletIndex !== -1) {
      setWallets((prevState) => {
        const newState = [...prevState]
        newState[walletIndex].balance = value
        return newState
      })
    }
  }

  const handleSubmit = () => {
    const defaultWallet = wallets.find(
      (wallet) => wallet.name === WalletSlugEnum.DEFAULT,
    )

    if (!defaultWallet || Number(defaultWallet.balance) < 0) {
      toast.warning(t("messages.balance_can_not_be_negative"))
      return
    }

    walletDescriptionModal().then((result) => {
      if (result.description.length === 0) {
        toast.warning(t("messages.description_can_not_be_empty"))
        return
      }

      updateBalance({
        id: defaultWallet.id.toString(),
        amount: Number(defaultWallet.balance),
        description: result.description,
      })
        .unwrap()
        .then(() => toast.success(t("users.edit_wallet.success")))
    })
  }

  return (
    <Fragment>
      {data && (
        <Fragment>
          <div
            className={
              "flex flex-col flex-wrap gap-y-8 px-4 pb-4 pt-12 text-neutral-200 lg:w-1/4"
            }
          >
            {wallets.map((wallet) => (
              <div key={wallet.id}>
                <Input
                  type={"number"}
                  label={t(`wallets.${wallet.name}`)}
                  value={wallet.balance}
                  disabled={wallet.name !== WalletSlugEnum.DEFAULT}
                  onChange={(e) =>
                    onChangeBalance(e.currentTarget.value, wallet.name)
                  }
                  endAdornment={<span>{wallet.currency}</span>}
                />
              </div>
            ))}
            <div>
              <Button variant={"contained"} onClick={handleSubmit}>
                {t("buttons.save")}
              </Button>
            </div>
          </div>
        </Fragment>
      )}
      <ModalContainer />
    </Fragment>
  )
}
