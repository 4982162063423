import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useDropzone } from "react-dropzone"
import Card from "@/resources/components/cards/card"
import { FormControl } from "@mui/base"
import { Input } from "@/resources/components/inputs/input"
import { FormHelperText } from "@/resources/components/inputs/formHelperText"
import Select, { Option } from "@/resources/components/inputs/select"
import { GenderEnum } from "@/enums/gender"
import Switch from "@/resources/components/inputs/switch"
import Editor from "@/resources/components/inputs/editor"
import { Button } from "@/resources/components/buttons/button"
import { useAssignAttributes, useValidation } from "@hypedevgroup/core"
import { useSearchRolesQuery } from "@/connectors/fansbay/requests/roleRequests"
import { useSearchCountriesQuery } from "@/connectors/fansbay/requests/countryRequests"
import { FormInterface } from "@/interfaces/base"
import {
  StoreUserProps,
  UpdateUserProps,
  UserInterface,
} from "@/interfaces/user"
import StoreUserValidation from "@/validations/users/storeUserValidation"
import { UpdateUserValidation } from "@/validations/users/updateUserValidation"

const UserForm: React.FC<
  FormInterface<StoreUserProps | UpdateUserProps, UserInterface> & {
    validationClass: StoreUserValidation | UpdateUserValidation
  }
> = ({
  onSubmit,
  errors: formErrors,
  data,
  additionalData,
  validationClass,
}) => {
  const { t } = useTranslation()
  const { schema, defaultValues, resolveValidationErrors } = useValidation(
    validationClass,
    t,
  )
  const { data: roles = [] } = useSearchRolesQuery()
  const { data: countries = [] } = useSearchCountriesQuery()

  const {
    formState: { errors },
    control,
    setValue,
    handleSubmit,
    setError,
    watch,
  } = useForm<StoreUserProps | UpdateUserProps>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  })

  useAssignAttributes(setValue, data)

  const avatarDropzone = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDropAccepted: (files) => setValue("avatar", files[0]),
    multiple: false,
  })

  const coverDropzone = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDropAccepted: (files) => setValue("cover", files[0]),
    multiple: false,
  })

  const watchAvatar = watch("avatar")
  const watchCover = watch("cover")

  useEffect(() => {
    if (formErrors) {
      const errors = resolveValidationErrors(formErrors)

      Object.keys(errors).forEach((key) => {
        const errorKey = key as keyof StoreUserProps

        setError(errorKey, {
          type: "manual",
          message: errors[errorKey],
        })
      })
    }
  }, [formErrors])

  return (
    <div className={"mb-8 flex flex-col gap-8"}>
      <Card className={"flex w-full"}>
        <div className={"flex w-full flex-col gap-16 md:flex-row"}>
          <div className={"md:w-[150px]"}>
            <span>{t("users.forms.basic_data")}</span>
          </div>
          <div className={"w-full md:w-[400px]"}>
            <div className={"flex flex-col gap-y-8"}>
              <Controller
                render={({ field }) => (
                  <FormControl {...field} error={!!errors.username}>
                    <Input
                      label={t("labels.username")}
                      placeholder={t("placeholders.username")}
                    />
                    <FormHelperText message={errors.username?.message} />
                  </FormControl>
                )}
                name={"username"}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <FormControl {...field} error={!!errors.email}>
                    <Input
                      label={t("labels.email")}
                      placeholder={t("placeholders.email")}
                      type={"email"}
                    />
                    <FormHelperText message={errors.email?.message} />
                  </FormControl>
                )}
                name={"email"}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <FormControl {...field} error={!!errors.password}>
                    <Input
                      label={t("labels.password")}
                      type={"password"}
                      placeholder={t("placeholders.password")}
                    />
                    <FormHelperText message={errors.password?.message} />
                  </FormControl>
                )}
                name={"password"}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <FormControl
                    {...field}
                    error={!!errors.password_confirmation}
                  >
                    <Input
                      label={t("labels.password_confirmation")}
                      placeholder={t("placeholders.password_confirmation")}
                      type={"password"}
                    />
                    <FormHelperText
                      message={errors.password_confirmation?.message}
                    />
                  </FormControl>
                )}
                name={"password_confirmation"}
                control={control}
              />
            </div>
          </div>
        </div>
      </Card>
      <Card className={"flex w-full"}>
        <div className={"flex w-full flex-col gap-16 md:flex-row"}>
          <div className={"md:w-[150px]"}>
            <span>{t("users.forms.profile_information")}</span>
          </div>
          <div className={"w-full md:w-[400px]"}>
            <div className={"flex flex-col gap-y-8"}>
              <Controller
                render={({ field: { onChange, ...rest } }) => (
                  <FormControl error={!!errors.gender}>
                    <Select
                      label={t("labels.gender")}
                      fullWidth
                      onChange={(_, value) => onChange(value)}
                      {...rest}
                    >
                      {Object.entries(GenderEnum).map(([key, value]) => (
                        <Option key={key} value={value}>
                          {t(`genders.${value}`)}
                        </Option>
                      ))}
                    </Select>
                    <FormHelperText message={errors.gender?.message} />
                  </FormControl>
                )}
                name={"gender"}
                control={control}
              />
              <Controller
                render={({ field: { onChange, ...rest } }) => (
                  <FormControl error={!!errors.country_id}>
                    <Select
                      label={t("labels.country")}
                      fullWidth
                      onChange={(_, value) => onChange(value)}
                      {...rest}
                    >
                      <Option value={0}>
                        {t("default_options.select_country")}
                      </Option>
                      {countries.map((country) => (
                        <Option key={country.id} value={country.id}>
                          {country.name}
                        </Option>
                      ))}
                    </Select>
                    <FormHelperText message={errors.country_id?.message} />
                  </FormControl>
                )}
                name={"country_id"}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <FormControl {...field} error={!!errors.website}>
                    <Input
                      label={t("labels.website")}
                      placeholder={t("placeholders.website")}
                      type={"url"}
                    />
                    <FormHelperText message={errors.website?.message} />
                  </FormControl>
                )}
                name={"website"}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <FormControl {...field} error={!!errors.location}>
                    <Input
                      label={t("labels.location")}
                      placeholder={t("placeholders.location")}
                    />
                    <FormHelperText message={errors.location?.message} />
                  </FormControl>
                )}
                name={"location"}
                control={control}
              />
              <Controller
                render={({ field: { onChange, ...rest } }) => (
                  <FormControl error={!!errors.role_id}>
                    <Select
                      label={t("labels.role")}
                      fullWidth
                      onChange={(_, value) => onChange(value)}
                      {...rest}
                    >
                      <Option value={0}>
                        {t("default_options.select_role")}
                      </Option>
                      {roles.map((role) => (
                        <Option value={role.id} key={role.id}>
                          {role.name}
                        </Option>
                      ))}
                    </Select>
                    <FormHelperText message={errors.role_id?.message} />
                  </FormControl>
                )}
                name={"role_id"}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <FormControl {...field} error={!!errors.verified_at}>
                    <Input
                      label={t("labels.verified_at")}
                      type={"datetime-local"}
                    />
                    <FormHelperText message={errors.verified_at?.message} />
                  </FormControl>
                )}
                name={"verified_at"}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <FormControl {...field} error={!!errors.date_of_birth}>
                    <Input label={t("labels.date_of_birth")} type={"date"} />
                    <FormHelperText message={errors.date_of_birth?.message} />
                  </FormControl>
                )}
                name={"date_of_birth"}
                control={control}
              />
            </div>
          </div>
        </div>
      </Card>
      <Card className={"flex w-full"}>
        <div
          className={
            "flex w-full flex-col gap-x-16 gap-y-8 md:flex-row 2xl:w-1/2"
          }
        >
          <div className={"w-[150px]"}>
            <span>{t("users.forms.cover")}</span>
          </div>
          <div className={"w-full"}>
            <div {...coverDropzone.getRootProps({ className: "dropzone" })}>
              <input {...coverDropzone.getInputProps()} />
              <p>{t("dropzone.select_files")}</p>
            </div>
            {watchCover && (
              <ImagePreview url={URL.createObjectURL(watchCover)} />
            )}
            {additionalData?.cover && !watchCover && (
              <ImagePreview url={additionalData.cover} />
            )}
          </div>
        </div>
      </Card>
      <Card className={"flex w-full"}>
        <div className={"flex w-full flex-row gap-x-16 gap-y-8 2xl:w-1/2"}>
          <div className={"w-[150px]"}>
            <span>{t("users.forms.avatar")}</span>
          </div>
          <div className={"w-full"}>
            <div {...avatarDropzone.getRootProps({ className: "dropzone" })}>
              <input {...avatarDropzone.getInputProps()} />
              <p>{t("dropzone.select_files")}</p>
            </div>
            {watchAvatar && (
              <ImagePreview url={URL.createObjectURL(watchAvatar)} />
            )}
            {additionalData?.avatar.thumb_url && !watchAvatar && (
              <ImagePreview url={additionalData.avatar.thumb_url} />
            )}
          </div>
        </div>
      </Card>
      <Card className={"flex w-full"}>
        <div className={"flex flex-row gap-x-16 gap-y-8"}>
          <div className={"w-[150px]"}>
            <span>{t("users.forms.additional_information")}</span>
          </div>
          <div className={"w-[250px] md:w-[400px]"}>
            <div className={"flex flex-col gap-y-8"}>
              <Controller
                render={({ field }) => (
                  <FormControl error={!!errors.open_profile}>
                    <Switch
                      checked={field.value}
                      onChange={(value) => field.onChange(value)}
                      label={t("labels.open_profile")}
                    />
                  </FormControl>
                )}
                name={"open_profile"}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <FormControl error={!!errors.public_account}>
                    <Switch
                      checked={field.value}
                      onChange={(value) => field.onChange(value)}
                      label={t("labels.public_account")}
                    />
                  </FormControl>
                )}
                name={"public_account"}
                control={control}
              />
            </div>
          </div>
        </div>
      </Card>
      <Card className={"flex w-full"}>
        <div className={"flex w-full flex-row gap-x-16 gap-y-8 2xl:w-1/2"}>
          <div className={"w-[150px]"}>
            <span>{t("users.forms.bio")}</span>
          </div>
          <div className={"w-full"}>
            <Controller
              render={({ field }) => (
                <Editor
                  onChange={(value) => field.onChange(value)}
                  value={field.value}
                />
              )}
              name={"bio"}
              control={control}
            />
          </div>
        </div>
      </Card>
      <div>
        <Button variant={"contained"} onClick={handleSubmit(onSubmit)}>
          {t("buttons.save")}
        </Button>
      </div>
    </div>
  )
}

const ImagePreview = ({ url }: { url: string }) => {
  return (
    <div className={"w-full pt-4"}>
      <img className={"max-w-[200px]"} src={url} alt={"avatar"} />
    </div>
  )
}

export default UserForm
