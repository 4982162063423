import { create, InstanceProps } from "react-modal-promise"
import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"
import Modal from "@/resources/components/modals/modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { useAssignAttributes, useValidation } from "@hypedevgroup/core"
import ChangeStatusValidation from "@/validations/payments/changeStatusValidation"
import { useGetPaymentQuery } from "@/connectors/fansbay/requests/paymentRequests"
import { Controller, useForm } from "react-hook-form"
import { ChangeStatusOfPaymentPropsInterface } from "@/interfaces/payment"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormControl } from "@mui/base"
import Select, { Option } from "@/resources/components/inputs/select"
import { FormHelperText } from "@/resources/components/inputs/formHelperText"
import { PaymentStatusEnum } from "@/enums/paymentStatus"
import { Button } from "@/resources/components/buttons/button"

const PaymentEdit: React.FC<InstanceProps<unknown> & { id: string }> = (
  props,
) => {
  const { id, isOpen, onResolve, onReject } = props
  const { t } = useTranslation()
  const { data } = useGetPaymentQuery(id)
  const { schema, defaultValues } = useValidation(ChangeStatusValidation, t)

  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<ChangeStatusOfPaymentPropsInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  useAssignAttributes(setValue, data)

  const onSubmit = (data: ChangeStatusOfPaymentPropsInterface) => {
    onResolve(data)
  }

  return (
    <Modal open={isOpen} onClose={onReject}>
      <Modal.Content>
        <Fragment>
          <div className={"flex w-full flex-row items-center justify-between"}>
            <span className={"text-lg font-semibold text-white"}>
              {t("payments.edit.title")}
            </span>
            <FontAwesomeIcon
              onClick={onReject}
              icon={faTimes}
              className={"cursor-pointer text-white"}
            />
          </div>
          <div className={"flex flex-col gap-y-4 pt-4"}>
            <Controller
              render={({ field }) => (
                <FormControl error={!!errors.status}>
                  <Select
                    fullWidth
                    label={t("labels.status")}
                    {...field}
                    onChange={(_, value) => field.onChange(value)}
                  >
                    <Option disabled={true} value={PaymentStatusEnum.PENDING}>
                      {t(`payments.statuses.${PaymentStatusEnum.PENDING}`)}
                    </Option>
                    <Option
                      disabled={PaymentStatusEnum.PENDING !== field.value}
                      value={PaymentStatusEnum.PAID}
                    >
                      {t(`payments.statuses.${PaymentStatusEnum.PAID}`)}
                    </Option>
                    <Option disabled={true} value={PaymentStatusEnum.DECLINED}>
                      {t(`payments.statuses.${PaymentStatusEnum.DECLINED}`)}
                    </Option>
                    <Option
                      disabled={field.value !== PaymentStatusEnum.PAID}
                      value={PaymentStatusEnum.REFUNDED}
                    >
                      {t(`payments.statuses.${PaymentStatusEnum.REFUNDED}`)}
                    </Option>
                    <Option disabled={true} value={PaymentStatusEnum.FAILED}>
                      {t(`payments.statuses.${PaymentStatusEnum.FAILED}`)}
                    </Option>
                  </Select>
                  <FormHelperText message={errors.status?.message} />
                </FormControl>
              )}
              name={"status"}
              control={control}
            />
            <div className={"text-s text-gray-500"}>
              <span>{t("user_verifications.edit.urgent")}</span>
              <ul className={"pt-4"}>
                <li className={"flex gap-x-2"}>
                  <span>
                    {t(`payments.statuses.${PaymentStatusEnum.PENDING}`)}
                  </span>
                  <span>{"->"}</span>
                  <span>
                    {t(`payments.statuses.${PaymentStatusEnum.PAID}`)}
                  </span>
                </li>
                <li className={"flex gap-x-2"}>
                  <span>
                    {t(`payments.statuses.${PaymentStatusEnum.PAID}`)}
                  </span>
                  <span>{"->"}</span>
                  <span>
                    {t(`payments.statuses.${PaymentStatusEnum.REFUNDED}`)}
                  </span>
                </li>
              </ul>
            </div>
            <div className={"flex w-full pt-4"}>
              <Button
                variant={"contained"}
                className={"w-full bg-green-900"}
                onClick={handleSubmit(onSubmit)}
              >
                {t("buttons.save")}
              </Button>
            </div>
          </div>
        </Fragment>
      </Modal.Content>
    </Modal>
  )
}

export const paymentEditModal = create(PaymentEdit)
