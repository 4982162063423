import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import { UserGroupFilterEnum } from "@/enums/userGroupFilter"
import {
  GetUserGroupResponseInterface,
  GetUserGroupsResponseInterface,
  SaveUserGroupPropsInterface,
  UserGroupInterface,
} from "@/interfaces/userGroup"
import { SaveDataWithId } from "@/types/base"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getFilters: builder.query<UserGroupFilterEnum[], void>({
      query: () => "/api/panel/user-groups/filters",
      providesTags: [{ type: "UserGroup", id: "FILTERS" }],
    }),
    getUserGroups: builder.query<
      GetUserGroupsResponseInterface["data"],
      string
    >({
      query: (query) => `/api/panel/user-groups${query}`,
      transformResponse: (response: GetUserGroupsResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }) => ({ type: "UserGroup", id } as const),
              ),
              { type: "UserGroup", id: "LIST" },
            ]
          : [{ type: "UserGroup", id: "LIST" }],
    }),
    getUserGroup: builder.query<UserGroupInterface, string>({
      query: (id) => `/api/panel/user-groups/${id}`,
      transformResponse: (response: GetUserGroupResponseInterface) =>
        response.data,
      providesTags: (result, error, id) => [{ type: "UserGroup", id }],
    }),
    storeUserGroup: builder.mutation<void, SaveUserGroupPropsInterface>({
      query: (body) => ({
        url: "/api/panel/user-groups",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "UserGroup", id: "LIST" }],
    }),
    updateUserGroup: builder.mutation<
      void,
      SaveDataWithId<SaveUserGroupPropsInterface>
    >({
      query: ({ id, ...body }) => ({
        url: `/api/panel/user-groups/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "UserGroup", id },
        { type: "UserGroup", id: "LIST" },
      ],
    }),
    deleteUserGroup: builder.mutation<void, string>({
      query: (id) => ({
        url: `/api/panel/user-groups/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "UserGroup", id: "LIST" }],
    }),
  }),
})

export const {
  useGetFiltersQuery,
  useGetUserGroupQuery,
  useGetUserGroupsQuery,
  useStoreUserGroupMutation,
  useUpdateUserGroupMutation,
  useDeleteUserGroupMutation,
} = requests
