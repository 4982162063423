import { FormInterface } from "@/interfaces/base"
import { useTranslation } from "react-i18next"
import { useAssignAttributes, useValidation } from "@hypedevgroup/core"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { Fragment } from "react"
import Card from "@/resources/components/cards/card"
import { FormControl } from "@mui/base"
import { Input } from "@/resources/components/inputs/input"
import { FormHelperText } from "@/resources/components/inputs/formHelperText"
import { Button } from "@/resources/components/buttons/button"
import {
  SaveUserGroupPropsInterface,
  UserGroupFilterInterface,
} from "@/interfaces/userGroup"
import SaveUserGroupValidation from "@/validations/userGroups/saveUserGroupValidation"
import Select, { Option } from "@/resources/components/inputs/select"
import { UserGroupFilterEnum } from "@/enums/userGroupFilter"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

export const UserGroupForm = ({
  data,
  onSubmit,
}: FormInterface<SaveUserGroupPropsInterface>) => {
  const { t } = useTranslation()
  const { schema, defaultValues } = useValidation(SaveUserGroupValidation, t)

  const {
    setValue,
    control,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm<SaveUserGroupPropsInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  useAssignAttributes(setValue, data)

  const onChangeFilter = (
    key: keyof UserGroupFilterInterface,
    value: string | number | null | undefined,
    index: number,
  ) => {
    const filters = getValues("filters")
    let filter = filters[index]

    Object.assign(filter, {
      [key]: value === 0 && key === "type" ? undefined : value,
    })

    filters[index] = filter
    setValue("filters", filters)
  }

  const onAddFilter = () => {
    const filters = getValues("filters")
    const filter: UserGroupFilterInterface = {
      name: UserGroupFilterEnum.SUBSCRIBERS,
    }

    setValue("filters", [...filters, ...[filter]])
  }

  const handleDeleteFilter = (index: number) => {
    const filters = getValues("filters")
    delete filters[index]
    setValue("filters", filters)
  }

  return (
    <div className={"mb-8 flex flex-col gap-8"}>
      <Card className={"flex w-full"}>
        <div className={"flex w-full flex-col gap-16 md:flex-row"}>
          <div className={"md:w-[150px]"}>
            <span>{t("users.forms.basic_data")}</span>
          </div>
          <div className={"w-full md:w-[400px]"}>
            <div className={"flex flex-col gap-y-8"}>
              <Controller
                render={({ field }) => (
                  <FormControl {...field} error={!!errors.name}>
                    <Input
                      label={t("labels.name")}
                      placeholder={t("placeholders.name")}
                    />
                    <FormHelperText message={errors.name?.message} />
                  </FormControl>
                )}
                name={"name"}
                control={control}
              />
              <div className={"flex w-full flex-col"}>
                <Button onClick={onAddFilter} variant={"contained"}>
                  {t("user_groups.form.add_filter")}
                </Button>
                <FormHelperText
                  error={!!errors.filters}
                  message={errors.filters?.message}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
      <Controller
        name={"filters"}
        control={control}
        render={({ field }) => (
          <Fragment>
            {field.value.map((filter, index) => (
              <Card key={index} className={"relative"}>
                <div
                  className={"absolute -right-2 -top-3"}
                  onClick={() => handleDeleteFilter(index)}
                >
                  <div
                    className={
                      "flex h-6 w-6 items-center justify-center rounded-full bg-red-600"
                    }
                  >
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={"cursor-pointer text-white"}
                    />
                  </div>
                </div>
                <div className={"flex w-full flex-col gap-8 lg:w-[400px]"}>
                  <div>
                    <Select
                      label={t("labels.filter_name")}
                      value={filter.name}
                      fullWidth
                      onChange={(_, value) =>
                        onChangeFilter("name", value as string, index)
                      }
                    >
                      {Object.values(UserGroupFilterEnum).map(
                        (filter, index) => (
                          <Option value={filter} key={index}>
                            {t(`user_groups.filters.${filter}`)}
                          </Option>
                        ),
                      )}
                    </Select>
                  </div>
                  <div>
                    <Input
                      type={"number"}
                      label={t("labels.value")}
                      placeholder={t("placeholders.enter_value")}
                      defaultValue={filter.value}
                      onChange={(e) =>
                        onChangeFilter("value", e.currentTarget.value, index)
                      }
                    />
                  </div>
                  <div>
                    <Select
                      label={t("labels.type")}
                      defaultValue={filter.type ?? 0}
                      fullWidth
                      onChange={(_, value) =>
                        onChangeFilter("type", value as string, index)
                      }
                    >
                      <Option value={0}>
                        {t("default_options.select_type")}
                      </Option>
                      {Object.values(["count", "month"]).map(
                        (filter, index) => (
                          <Option value={filter} key={index}>
                            {t(`user_groups.types.${filter}`)}
                          </Option>
                        ),
                      )}
                    </Select>
                    <FormHelperText
                      error={errors.filters && !!errors.filters[index]?.type}
                      message={
                        // @ts-ignore
                        errors.filters && errors.filters[index]?.type?.message
                      }
                    />
                  </div>
                </div>
              </Card>
            ))}
          </Fragment>
        )}
      />
      <div>
        <Button variant={"contained"} onClick={handleSubmit(onSubmit)}>
          {t("buttons.save")}
        </Button>
      </div>
    </div>
  )
}
