import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  GetTaxRateResponseInterface,
  GetTaxRatesResponseInterface,
  SaveTaxRatePropsInterface,
  TaxRateInterface,
} from "@/interfaces/taxRate"
import { SaveDataWithId } from "@/types/base"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getTaxRates: builder.query<GetTaxRatesResponseInterface["data"], string>({
      query: (query) => `/api/panel/tax-rates${query}`,
      transformResponse: (response: GetTaxRatesResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }) => ({ type: "TaxRate", id } as const),
              ),
              { type: "TaxRate", id: "LIST" },
            ]
          : [{ type: "TaxRate", id: "LIST" }],
    }),
    getTaxRate: builder.query<TaxRateInterface, string>({
      query: (id) => `/api/panel/tax-rates/${id}`,
      transformResponse: (response: GetTaxRateResponseInterface) =>
        response.data,
      providesTags: (response, error, id) => [{ type: "TaxRate", id }],
    }),
    storeTaxRate: builder.mutation<void, SaveTaxRatePropsInterface>({
      query: (body) => ({
        method: "POST",
        url: "/api/panel/tax-rates",
        body,
      }),
      invalidatesTags: [{ type: "TaxRate", id: "LIST" }],
    }),
    updateTaxRate: builder.mutation<
      void,
      SaveDataWithId<SaveTaxRatePropsInterface>
    >({
      query: ({ id, ...body }) => ({
        method: "PUT",
        url: `/api/panel/tax-rates/${id}`,
        body,
      }),
      invalidatesTags: (response, error, { id }) => [
        { type: "TaxRate", id },
        { type: "TaxRate", id: "LIST" },
      ],
    }),
    deleteTaxRate: builder.mutation<void, string>({
      query: (id) => ({
        method: "DELETE",
        url: `/api/panel/tax-rates/${id}`,
      }),
    }),
  }),
})

export const {
  useGetTaxRateQuery,
  useGetTaxRatesQuery,
  useStoreTaxRateMutation,
  useUpdateTaxRateMutation,
  useDeleteTaxRateMutation,
} = requests
