import { FormInterface } from "@/interfaces/base"
import { SaveTaxRatePropsInterface } from "@/interfaces/taxRate"
import { useTranslation } from "react-i18next"
import { useAssignAttributes, useValidation } from "@hypedevgroup/core"
import SaveTaxRateValidation from "@/validations/taxRates/saveTaxRateValidation"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useEffect } from "react"
import Card from "@/resources/components/cards/card"
import { FormControl } from "@mui/base"
import { Input } from "@/resources/components/inputs/input"
import { FormHelperText } from "@/resources/components/inputs/formHelperText"
import Select, { Option } from "@/resources/components/inputs/select"
import { useSearchCountriesQuery } from "@/connectors/fansbay/requests/countryRequests"
import { Checkbox } from "@/resources/components/inputs/checkbox"
import { Label } from "@/resources/components/inputs/label"
import { Button } from "@/resources/components/buttons/button"

export const TaxRateForm = ({
  data,
  errors: formErrors,
  onSubmit,
}: FormInterface<SaveTaxRatePropsInterface>) => {
  const { t } = useTranslation()
  const { schema, defaultValues, resolveValidationErrors } = useValidation(
    SaveTaxRateValidation,
    t,
  )
  const { data: countries = [] } = useSearchCountriesQuery()

  const {
    setValue,
    setError,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<SaveTaxRatePropsInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  useAssignAttributes(setValue, data)

  useEffect(() => {
    if (formErrors) {
      const errors = resolveValidationErrors(formErrors)
      Object.keys(errors).forEach((key) => {
        const errorKey = key as keyof SaveTaxRatePropsInterface

        setError(errorKey, {
          type: "manual",
          message: errors[errorKey],
        })
      })
    }
  }, [formErrors, setError])

  return (
    <div className={"mb-8 flex flex-col gap-8"}>
      <Card className={"flex w-full"}>
        <div className={"flex w-full flex-col gap-16 md:flex-row"}>
          <div className={"md:w-[150px]"}>
            <span>{t("users.forms.basic_data")}</span>
          </div>
          <div className={"w-full md:w-[400px]"}>
            <div className={"flex flex-col gap-y-8"}>
              <Controller
                render={({ field }) => (
                  <FormControl {...field} error={!!errors.name}>
                    <Input
                      label={t("labels.name")}
                      placeholder={t("placeholders.name")}
                    />
                    <FormHelperText message={errors.name?.message} />
                  </FormControl>
                )}
                name={"name"}
                control={control}
              />
              <Controller
                render={({ field: { onChange, ...rest } }) => (
                  <FormControl error={!!errors.country_id}>
                    <Select
                      label={t("labels.country")}
                      fullWidth
                      onChange={(_, value) => onChange(value)}
                      {...rest}
                    >
                      <Option value={0}>
                        {t("default_options.select_country")}
                      </Option>
                      {countries.map((country) => (
                        <Option key={country.id} value={country.id}>
                          {country.name}
                        </Option>
                      ))}
                    </Select>
                    <FormHelperText message={errors.country_id?.message} />
                  </FormControl>
                )}
                name={"country_id"}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <FormControl {...field} error={!!errors.rate}>
                    <Input
                      label={t("labels.value")}
                      endAdornment={<span>%</span>}
                      type={"number"}
                      placeholder={t("placeholders.enter_value")}
                    />
                    <FormHelperText message={errors.rate?.message} />
                  </FormControl>
                )}
                name={"rate"}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <FormControl error={!!errors.active}>
                    <Label label={t("labels.active")} />
                    <Checkbox
                      checked={field.value}
                      onChange={(e) => field.onChange(e.currentTarget.checked)}
                    />
                    <FormHelperText message={errors.active?.message} />
                  </FormControl>
                )}
                name={"active"}
                control={control}
              />
            </div>
          </div>
        </div>
      </Card>
      <div>
        <Button variant={"contained"} onClick={handleSubmit(onSubmit)}>
          {t("buttons.save")}
        </Button>
      </div>
    </div>
  )
}
