import _ from "lodash"
import { useTranslation } from "react-i18next"
import { ChangeEvent } from "react"
import { Input } from "@/resources/components/inputs/input"

type Props = {
  onChange: (value?: string) => void
}

const Search = (props: Props) => {
  const { onChange } = props
  const { t } = useTranslation()

  const _onSearchChange = (e: ChangeEvent<HTMLInputElement>) =>
    onChange(e.target.value)

  const onSearchChange = _.debounce(_onSearchChange, 500)

  return (
    <Input
      className={"w-full 2xl:w-[400px]"}
      placeholder={t("placeholders.type_to_search")}
      onChange={onSearchChange}
      type={"search"}
    />
  )
}

export default Search
