import { useNavigate, useParams } from "react-router-dom"
import { useRedirectWithError } from "@/utils/hooks/useRedirectWithError"
import { BreadCrumbInterface } from "@/interfaces/base"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import {
  useGetUserGroupQuery,
  useUpdateUserGroupMutation,
} from "@/connectors/fansbay/requests/userGroupRequests"
import { SaveUserGroupPropsInterface } from "@/interfaces/userGroup"
import { UserGroupForm } from "@/resources/views/userGroups/parts/form"

export const UserGroupEdit = () => {
  const { id } = useParams<{ id: string }>()
  const { data, error } = useGetUserGroupQuery(id!)
  const [update] = useUpdateUserGroupMutation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  useRedirectWithError(error, "/error")

  const handleSubmit = (data: SaveUserGroupPropsInterface) => {
    update({
      ...data,
      id: id as string,
    })
      .unwrap()
      .then(() => {
        toast.success(t("user_groups.edit.success"))
        navigate("/user-groups")
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      <UserGroupForm data={data} onSubmit={handleSubmit} />
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "modules.breadcrumbs.setting",
    url: "#",
  },
  {
    title: "user_groups.breadcrumbs.user_groups",
    url: "/user-groups",
  },
  {
    title: "user_groups.breadcrumbs.edit",
    url: `/user-groups/${id}/edit`,
  },
]
