import { FormInterface } from "@/interfaces/base"
import { SaveChatMessageFilterPropsInterface } from "@/interfaces/chatMessageFilter"
import { useTranslation } from "react-i18next"
import { useValidation } from "@hypedevgroup/core"
import SaveMessageFilterValidation from "@/validations/chatMessageFilters/saveMessageFilterValidation"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useEffect } from "react"
import Card from "@/resources/components/cards/card"
import { FormControl } from "@mui/base"
import { Input } from "@/resources/components/inputs/input"
import { FormHelperText } from "@/resources/components/inputs/formHelperText"
import CreatableSelect from "react-select/creatable"
import { Button } from "@/resources/components/buttons/button"

export const MessageFilterForm = ({
  onSubmit,
  data,
  errors: formErrors,
}: FormInterface<SaveChatMessageFilterPropsInterface>) => {
  const { t } = useTranslation()
  const { schema, defaultValues, resolveValidationErrors } = useValidation(
    SaveMessageFilterValidation,
    t,
  )

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useForm<SaveChatMessageFilterPropsInterface>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => {
    if (formErrors) {
      const errors = resolveValidationErrors(formErrors)

      Object.keys(errors).forEach((key) => {
        const errorKey = key as keyof SaveChatMessageFilterPropsInterface

        setError(errorKey, {
          type: "manual",
          message: errors[errorKey],
        })
      })
    }
  }, [formErrors, setError])

  useEffect(() => {
    if (data) {
      setValue("name", data.name)
      setValue("fields", data.fields)
    }
  }, [data, setValue])

  return (
    <div className={"mb-8 flex flex-col gap-8"}>
      <Card className={"flex w-full"}>
        <div className={"flex w-full flex-col gap-16 md:flex-row"}>
          <div className={"md:w-[150px]"}>
            <span>{t("forms.basic_data")}</span>
          </div>
          <div className={"w-full md:w-[400px]"}>
            <div className={"flex flex-col gap-y-8"}>
              <Controller
                render={({ field }) => (
                  <FormControl {...field} error={!!errors.name}>
                    <Input
                      label={t("labels.name")}
                      placeholder={t("placeholders.name")}
                    />
                    <FormHelperText message={errors.name?.message} />
                  </FormControl>
                )}
                name={"name"}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <FormControl error={!!errors.fields}>
                    <CreatableSelect
                      isClearable
                      isMulti
                      value={field.value.map((value) => ({
                        label: value,
                        value,
                      }))}
                      options={field.value.map((value) => ({
                        label: value,
                        value,
                      }))}
                      onChange={(value) =>
                        field.onChange(value.map((v) => v.value))
                      }
                      classNames={{
                        control: () =>
                          "!border !border-gray-700 !bg-gray-800 !rounded-md !text-neutral-200",
                        placeholder: () => "!text-neutral-200",
                        dropdownIndicator: () =>
                          "!text-neutral-200 !bg-gray-800",
                        menu: () => "!bg-gray-800 min-w-[200px]",
                        input: () => "!text-neutral-200",
                        indicatorSeparator: () => "hidden",
                        singleValue: () => "!text-neutral-200",
                        option: (state) =>
                          state.isFocused
                            ? "!bg-gray-700"
                            : "!text-neutral-200",
                        valueContainer: () =>
                          "!px-[14px] !py-1 !bg-gray-800 !text-neutral-200",
                        multiValueLabel: () => "!bg-gray-700 !text-white",
                        multiValueRemove: () => "!bg-gray-700 !text-white",
                        menuList: () => "!bg-gray-700",
                        multiValue: () => "!bg-gray-700",
                      }}
                      formatCreateLabel={(inputValue) =>
                        t("labels.add", { value: inputValue })
                      }
                      noOptionsMessage={() => t("messages.no_options")}
                      placeholder={t("placeholders.add_fields")}
                    />
                    <FormHelperText
                      error={!!errors.fields}
                      message={errors.fields?.message}
                    />
                  </FormControl>
                )}
                name={"fields"}
                control={control}
              />
            </div>
          </div>
        </div>
      </Card>
      <div>
        <Button variant={"contained"} onClick={handleSubmit(onSubmit)}>
          {t("buttons.save")}
        </Button>
      </div>
    </div>
  )
}
