import { useTranslation } from "react-i18next"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { ButtonLink } from "@/resources/components/buttons/button"
import Card from "@/resources/components/cards/card"
import { BreadCrumbInterface } from "@/interfaces/base"
import Table from "@/resources/views/subscriptions/parts/table"
import { useMemo, useState } from "react"
import { baseQuery } from "@/utils/query"
import { SearchProps } from "@/resources/views/subscriptions/parts/search/search.types"
import { Search } from "@/resources/views/subscriptions/parts/search/search"

export const SubscriptionList = () => {
  const { t } = useTranslation()
  const [searchParameters, setSearchParameters] = useState<SearchProps>()

  const query = useMemo(
    () =>
      baseQuery
        .where("user_id", searchParameters?.user_id)
        .where("subscribable_id", searchParameters?.subscribable_id)
        .where("from", searchParameters?.from)
        .where("to", searchParameters?.to),
    [searchParameters],
  )

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <div className={"flex flex-row-reverse"}>
        <ButtonLink variant={"contained"} to={"/subscriptions/create"}>
          {t("subscriptions.list.create")}
        </ButtonLink>
      </div>
      <Card noPadding>
        <div
          className={
            "border-b border-gray-800 px-6 py-4 text-l font-medium text-neutral-200"
          }
        >
          <span>{t("subscriptions.list.title")}</span>
        </div>
        <div
          className={"flex flex-col gap-y-4 border-b border-gray-800 px-6 py-4"}
        >
          <Search
            onUpdateParameters={(parameters) => setSearchParameters(parameters)}
          />
        </div>
        <Table query={query} />
      </Card>
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "subscriptions.breadcrumbs.subscriptions",
    url: "/subscriptions",
  },
]
