import { FormInterface } from "@/interfaces/base"
import { SaveDefaultPlanInterface } from "@/interfaces/defaultPlan"
import { useTranslation } from "react-i18next"
import React, { useEffect } from "react"
import Card from "@/resources/components/cards/card"
import { useAssignAttributes, useValidation } from "@hypedevgroup/core"
import SaveDefaultPlanValidation from "@/validations/defaultPlans/saveDefaultPlanValidation"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useSearchCurrenciesQuery } from "@/connectors/fansbay/requests/currencyRequests"
import { FormControl } from "@mui/base"
import { Input } from "@/resources/components/inputs/input"
import { FormHelperText } from "@/resources/components/inputs/formHelperText"
import Select, { Option } from "@/resources/components/inputs/select"
import { Button } from "@/resources/components/buttons/button"

export const DefaultPlansForm = ({
  onSubmit,
  data,
  errors: formErrors,
}: FormInterface<SaveDefaultPlanInterface>) => {
  const { t } = useTranslation()
  const { schema, defaultValues, resolveValidationErrors } = useValidation(
    SaveDefaultPlanValidation,
    t,
  )
  const { data: currencies = [] } = useSearchCurrenciesQuery()

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
  } = useForm<SaveDefaultPlanInterface>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  })

  useAssignAttributes(setValue, data)

  useEffect(() => {
    if (formErrors) {
      const errors = resolveValidationErrors(formErrors)

      Object.keys(errors).forEach((key) => {
        const errorKey = key as keyof SaveDefaultPlanInterface
        setError(errorKey, {
          type: "manual",
          message: errors[errorKey],
        })
      })
    }
  }, [formErrors, setError])

  return (
    <div className={"mb-8 flex flex-col gap-8"}>
      <Card className={"flex w-full"}>
        <div className={"flex w-full flex-col gap-16 md:flex-row"}>
          <div className={"md:w-[150px]"}>
            <span>{t("forms.basic_data")}</span>
          </div>
          <div className={"w-full md:w-[400px]"}>
            <div className={"flex flex-col gap-y-8"}>
              <Controller
                render={({ field }) => (
                  <FormControl {...field} error={!!errors.duration}>
                    <Input
                      type={"number"}
                      placeholder={t("placeholders.duration")}
                      label={t("labels.duration")}
                    />
                    <FormHelperText message={errors.duration?.message} />
                  </FormControl>
                )}
                name={"duration"}
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <FormControl error={!!errors.currency_id}>
                    <Select
                      label={t("labels.currency")}
                      {...field}
                      onChange={(_, value) => field.onChange(value)}
                    >
                      <Option value={0}>
                        {t("default_options.select_currency")}
                      </Option>
                      {currencies.map((currency) => (
                        <Option key={currency.id} value={currency.id}>
                          {currency.name}
                        </Option>
                      ))}
                    </Select>
                    <FormHelperText message={errors.currency_id?.message} />
                  </FormControl>
                )}
                control={control}
                name={"currency_id"}
              />
              <Controller
                render={({ field }) => (
                  <FormControl {...field} error={!!errors.price}>
                    <Input
                      type={"number"}
                      placeholder={t("placeholders.price")}
                      label={t("labels.price")}
                    />
                    <FormHelperText message={errors.price?.message} />
                  </FormControl>
                )}
                name={"price"}
                control={control}
              />
            </div>
          </div>
        </div>
      </Card>
      <div>
        <Button variant={"contained"} onClick={handleSubmit(onSubmit)}>
          {t("buttons.save")}
        </Button>
      </div>
    </div>
  )
}
