import React, { FC, useEffect } from "react"
import { create, InstanceProps } from "react-modal-promise"
import { useTranslation } from "react-i18next"
import Modal from "@/resources/components/modals/modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { useValidation } from "@hypedevgroup/core"
import { updateAgreementValidation } from "@/validations/agreements"
import { Controller, useForm } from "react-hook-form"
import { UpdateAgreementPropsInterface } from "@/interfaces/agreement"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  useGetAgreementQuery,
  useUpdateAgreementMutation,
} from "@/connectors/fansbay"
import { FormControl } from "@mui/base"
import Select, { Option } from "@/resources/components/inputs/select"
import _ from "lodash"
import { AgreementGroupNameEnum } from "@/enums"
import { Checkbox } from "@/resources/components/inputs/checkbox"
import { Button } from "@/resources/components/buttons/button"
import { serialize } from "object-to-formdata"

const ModalEdit: FC<InstanceProps<void> & { id: string | number }> = ({
  isOpen,
  onResolve,
  onReject,
  id,
}) => {
  const { t } = useTranslation()
  const { schema, defaultValues } = useValidation(updateAgreementValidation, t)
  const { data } = useGetAgreementQuery(id)
  const [updateAgreement] = useUpdateAgreementMutation()

  const { control, reset, handleSubmit } =
    useForm<UpdateAgreementPropsInterface>({
      resolver: yupResolver(schema),
      defaultValues,
    })

  useEffect(() => {
    if (data) {
      reset({
        groups: data.groups,
        visible: data.visible,
      })
    }
  }, [data])

  const onSubmit = async (bodyData: UpdateAgreementPropsInterface) => {
    if (!data) return

    const { file, ...rest } = data

    try {
      const formData = serialize(
        {
          ...rest,
          ...bodyData,
          _method: "PUT",
        },
        {
          nullsAsUndefineds: true,
          booleansAsIntegers: true,
          indices: true,
        },
      )

      await updateAgreement({
        id,
        data: formData,
      }).unwrap()

      onResolve()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Modal open={isOpen} onClose={onReject}>
      <Modal.Content>
        <div className={"flex flex-col gap-4"}>
          <div className={"flex w-full flex-row items-center justify-between"}>
            <span className={"text-lg font-semibold text-white"}>
              {t("agreements.edit.title")}
            </span>
            <FontAwesomeIcon
              onClick={() => onReject()}
              icon={faTimes}
              className={"cursor-pointer text-white"}
            />
          </div>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl className={"md:col-span-2"} error={!!error}>
                <Select
                  fullWidth
                  label={t("labels.groups")}
                  multiple
                  placeholder={t("placeholders.groups")}
                  value={field.value.map((item) => item.name)}
                  onChange={(_, val) =>
                    field.onChange(
                      (val as string[]).map((item) => ({
                        name: item,
                      })),
                    )
                  }
                >
                  {_.map(AgreementGroupNameEnum, (value, index) => (
                    <Option value={value} key={index}>
                      {t(`agreements.groups.${value}`)}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            )}
            name={"groups"}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <FormControl error={!!error}>
                <Checkbox
                  label={t("labels.visible")}
                  checked={field.value}
                  onChange={field.onChange}
                />
              </FormControl>
            )}
            name={"visible"}
            control={control}
          />
        </div>
        <div className={"flex gap-2 pt-8"}>
          <Button fullWidth variant={"contained"} onClick={() => onReject()}>
            {t("buttons.cancel")}
          </Button>
          <Button
            variant={"contained"}
            fullWidth
            className={"!bg-green-600"}
            onClick={handleSubmit(onSubmit)}
          >
            {t("buttons.save")}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export const AgreementEdit = create(ModalEdit)
