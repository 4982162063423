import React from "react"
import _ from "lodash"
import { Input } from "@/resources/components/inputs/input"

type Props = {
  onChange: (value: string) => void
  placeholder: string
  value: string | undefined | number
}

export const SearchBy = (props: Props) => {
  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.value)
  }

  const onChange = _.debounce(_onChange, 1000)

  return (
    <Input
      type={"search"}
      defaultValue={props.value}
      onChange={onChange}
      placeholder={props.placeholder}
    />
  )
}
