import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  GetWithdrawResponseInterface,
  GetWithdrawsResponseInterface,
  UpdateStatusPropsInterface,
  WithdrawInterface,
} from "@/interfaces/withdraw"
import { SaveDataWithId } from "@/types/base"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getWithdraws: builder.query<GetWithdrawsResponseInterface["data"], string>({
      query: (query) => `/api/panel/withdraws${query}`,
      transformResponse: (response: GetWithdrawsResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }) => ({ type: "Withdraw", id } as const),
              ),
              { type: "Withdraw", id: "LIST" },
            ]
          : [{ type: "Withdraw", id: "LIST" }],
    }),
    getWithdraw: builder.query<WithdrawInterface, string>({
      query: (id) => `/api/panel/withdraws/${id}`,
      transformResponse: (response: GetWithdrawResponseInterface) =>
        response.data,
      providesTags: (result, error, id) => [{ type: "Withdraw", id }],
    }),
    updateWithdraw: builder.mutation<
      void,
      SaveDataWithId<UpdateStatusPropsInterface>
    >({
      query: ({ id, ...body }) => ({
        url: `/api/panel/withdraws/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Withdraw", id: "LIST" },
        { type: "Withdraw", id },
      ],
    }),
  }),
})

export const {
  useGetWithdrawQuery,
  useGetWithdrawsQuery,
  useUpdateWithdrawMutation,
} = requests
