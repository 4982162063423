import React, { useEffect, useState } from "react"
import BaseTable from "@/resources/components/table/table"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { TFunction } from "i18next"
import RowAction from "@/resources/components/table/rowAction"
import { format } from "date-fns"
import { ColumnSetting } from "@/resources/components/table/columnSetting"
import { RoleInterface } from "@/interfaces/role"
import { useGetRolesQuery } from "@/connectors/fansbay/requests/roleRequests"

const columnHelper = createColumnHelper<RoleInterface>()

const columns = (t: TFunction) => [
  columnHelper.accessor("name", {
    id: "roleName",
    header: t("labels.role_name"),
  }),
  columnHelper.accessor("created_at", {
    header: t("labels.created_at"),
    cell: (row) => format(new Date(row.getValue()), "dd.MM.yyyy HH:mm"),
  }),
  columnHelper.display({
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => <RowAction url={`/roles/${row.original.id}`} />,
  }),
]

const Table = () => {
  const { t } = useTranslation()
  const [data, setData] = useState<RoleInterface[]>([])
  const { data: apiData } = useGetRolesQuery()

  useEffect(() => {
    if (apiData) {
      setData(apiData)
    }
  }, [apiData])

  const table = useReactTable({
    columns: columns(t),
    data: data,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  return (
    <div className={"flex flex-col"}>
      <div className={"flex flex-row-reverse px-8 py-4"}>
        <ColumnSetting table={table} />
      </div>
      <BaseTable table={table} />
    </div>
  )
}

export default Table
