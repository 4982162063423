import * as yup from "yup"
import { LoginPropsInterface } from "@/interfaces/auth"
import { TFunction } from "i18next"

class LoginValidation {
  static defaultValues(): LoginPropsInterface {
    return {
      email: "",
      password: "",
    }
  }

  static rules(
    t: TFunction,
  ): yup.ObjectSchema<LoginPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      email: yup
        .string()
        .email(t("validations.field_must_be_email"))
        .required(t("validations.field_is_required")),
      password: yup.string().required(t("validations.field_is_required")),
    })
  }
}

export default LoginValidation
