import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  GetSubscriptionResponseInterface,
  GetSubscriptionsResponseInterface,
  StoreSubscriptionPropsInterface,
  SubscriptionInterface,
  UpdateSubscriptionPropsInterface,
} from "@/interfaces/subscription"
import { SaveDataWithId } from "@/types/base"
import { format } from "date-fns"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getSubscriptions: builder.query<
      GetSubscriptionsResponseInterface["data"],
      string
    >({
      query: (query) => `/api/panel/subscriptions${query}`,
      transformResponse: (response: GetSubscriptionsResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }) => ({ type: "Subscription", id } as const),
              ),
              { type: "Subscription", id: "LIST" },
            ]
          : [{ type: "Subscription", id: "LIST" }],
    }),
    getSubscription: builder.query<SubscriptionInterface, string>({
      query: (id) => `/api/panel/subscriptions/${id}`,
      transformResponse: (response: GetSubscriptionResponseInterface) => {
        const data = response.data

        return {
          ...data,
          current_period_start: format(
            new Date(data.current_period_start),
            "yyyy-MM-dd",
          ),
          current_period_end: format(
            new Date(data.current_period_end),
            "yyyy-MM-dd",
          ),
        }
      },
      providesTags: (result, error, id) => [{ type: "Subscription", id }],
    }),
    storeSubscription: builder.mutation<void, StoreSubscriptionPropsInterface>({
      query: (body) => ({
        url: `/api/panel/subscriptions`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Subscription", id: "LIST" }],
    }),
    updateSubscription: builder.mutation<
      void,
      SaveDataWithId<UpdateSubscriptionPropsInterface>
    >({
      query: ({ id, ...body }) => ({
        url: `/api/panel/subscriptions/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Subscription", id },
        { type: "Subscription", id: "LIST" },
      ],
    }),
    deleteSubscription: builder.mutation<void, string>({
      query: (id) => ({
        url: `/api/panel/subscriptions/${id}`,
        method: "DELETE",
      }),
    }),
  }),
})

export const {
  useDeleteSubscriptionMutation,
  useGetSubscriptionQuery,
  useGetSubscriptionsQuery,
  useStoreSubscriptionMutation,
  useUpdateSubscriptionMutation,
} = requests
