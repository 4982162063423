import { useTranslation } from "react-i18next"
import { BreadCrumbInterface } from "@/interfaces/base"
import Table from "@/resources/views/messages/filters/parts/table"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import Card from "@/resources/components/cards/card"
import { ButtonLink } from "@/resources/components/buttons/button"
import { useHasPermission } from "@/utils/hooks/useHasPermission"
import { PermissionEnum } from "@/enums/permission"

export const MessageFilterList = () => {
  const { t } = useTranslation()
  const hasPermission = useHasPermission()

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <div className={"flex flex-row-reverse"}>
        {hasPermission(PermissionEnum.MESSAGE_FILTERS_STORE) && (
          <ButtonLink variant={"contained"} to={"/messages/filters/create"}>
            {t("chat_message_filters.list.create")}
          </ButtonLink>
        )}
      </div>
      <Card noPadding>
        <div
          className={
            "border-b border-gray-800 px-6 py-4 text-l font-medium text-neutral-200"
          }
        >
          <span>{t("chat_message_filters.list.title")}</span>
        </div>
        <Table />
      </Card>
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "modules.breadcrumbs.content",
    url: "#",
  },
  {
    title: "chat_messages.breadcrumbs.messages",
    url: "/messages",
  },
  {
    title: "chat_message_filters.breadcrumbs.filters",
    url: "/messages/filters",
  },
]
