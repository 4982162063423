export enum WithdrawStatusEnum {
  PENDING = "pending",
  PROCESSING = "processing",
  DECLINED = "declined",
  DONE = "done",
}

export const getAvailableStatuses = (status: WithdrawStatusEnum) => {
  switch (status) {
    case WithdrawStatusEnum.PENDING:
      return [
        WithdrawStatusEnum.PENDING,
        WithdrawStatusEnum.PROCESSING,
        WithdrawStatusEnum.DECLINED,
      ]
    case WithdrawStatusEnum.PROCESSING:
      return [
        WithdrawStatusEnum.PROCESSING,
        WithdrawStatusEnum.DONE,
        WithdrawStatusEnum.DECLINED,
      ]
  }
}
