import { useTranslation } from "react-i18next"
import { useStoreSubscriptionPlanMutation } from "@/connectors/fansbay/requests/subscriptionPlanRequests"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { SaveSubscriptionPlanPropsInterface } from "@/interfaces/subscriptionPlan"
import { SubscriptionPlanForm } from "@/resources/views/plans/parts/form"
import { BreadCrumbInterface } from "@/interfaces/base"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { BackendValidationErrorInterface } from "@hypedevgroup/core"

export const SubscriptionPlanCreate = () => {
  const { t } = useTranslation()
  const [storePlan] = useStoreSubscriptionPlanMutation()
  const navigate = useNavigate()
  const [errors, setErrors] =
    useState<
      BackendValidationErrorInterface<SaveSubscriptionPlanPropsInterface>
    >()

  const handleSubmit = (data: SaveSubscriptionPlanPropsInterface) => {
    storePlan(data)
      .unwrap()
      .then(() => {
        toast.success(t("subscription_plans.create.success"))
        navigate("/subscription-plans")
      })
      .catch((error) => {
        if (error.status === 422) {
          setErrors(error.data)
        }
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <SubscriptionPlanForm errors={errors} onSubmit={handleSubmit} />
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "subscription_plans.breadcrumbs.subscription_plans",
    url: "/subscription-plans",
  },
  {
    title: "subscription_plans.breadcrumbs.create",
    url: "/subscription-plans/create",
  },
]
