import { Fragment } from "react"
import Divider from "@/resources/components/layouts/auth/divider"
import { useTranslation } from "react-i18next"
import { Controller, useForm } from "react-hook-form"
import { ForgotPasswordPropsInterface } from "@/interfaces/auth"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormControl } from "@mui/base"
import { Input } from "@/resources/components/inputs/input"
import { FormHelperText } from "@/resources/components/inputs/formHelperText"
import { Button } from "@/resources/components/buttons/button"
import { useValidation } from "@hypedevgroup/core"
import ForgotPasswordValidation from "@/validations/auth/forgotPasswordValidation"
import { useAppDispatch } from "@/app/hooks"
import { forgotPasswordRequest } from "@/connectors/fansbay/requests/authRequests"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

export const ForgotPassword = () => {
  const { t } = useTranslation()
  const { schema, defaultValues } = useValidation(ForgotPasswordValidation, t)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordPropsInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const onSubmit = (data: ForgotPasswordPropsInterface) => {
    dispatch(forgotPasswordRequest(data))
      .unwrap()
      .then(() => {
        navigate("/reset-password", {
          state: {
            email: data.email,
          },
        })
      })
      .catch(() => toast.error(t("auth.forgot_password.error")))
  }

  return (
    <Fragment>
      <Divider title={t("auth.forgot_password.title")} />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={"flex flex-col gap-y-6"}
      >
        <Controller
          control={control}
          name={"email"}
          render={({ field }) => (
            <FormControl {...field} error={!!errors.email}>
              <Input
                label={t("labels.email")}
                type={"email"}
                placeholder={t("placeholders.email")}
              />
              <FormHelperText message={errors.email?.message} />
            </FormControl>
          )}
        />
        <Button type={"submit"} size={"large"} variant={"contained"}>
          {t("auth.forgot_password.button")}
        </Button>
      </form>
    </Fragment>
  )
}
