import { TFunction } from "i18next"
import * as yup from "yup"
import {
  SaveUserGroupPropsInterface,
  UserGroupFilterType,
} from "@/interfaces/userGroup"
import { UserGroupFilterEnum } from "@/enums/userGroupFilter"

export default class SaveUserGroupValidation {
  static rules(
    t: TFunction,
  ): yup.ObjectSchema<SaveUserGroupPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      name: yup.string().required(t("validations.field_is_required")),
      filters: yup
        .array()
        .required(t("validations.field_is_required"))
        .of(
          yup.object().shape({
            name: yup
              .mixed<UserGroupFilterEnum>()
              .required()
              .oneOf(Object.values(UserGroupFilterEnum)),
            value: yup.number().nullable(),
            type: yup
              .mixed<UserGroupFilterType>()
              .when("value", {
                is: (value: number | undefined) => value !== undefined,
                then: (yup) => yup.required(t("validations.field_is_required")),
              })
              .oneOf(["count", "month"]),
          }),
        )
        .min(1, t("validations.field_is_required")),
    })
  }

  static defaultValues(): SaveUserGroupPropsInterface {
    return {
      name: "",
      filters: [],
    }
  }
}
