import * as yup from "yup"
import { ResetPasswordPropsInterface } from "@/interfaces/auth"
import { TFunction } from "i18next"

class ResetPasswordValidation {
  static defaultValues(): ResetPasswordPropsInterface {
    return {
      email: "",
      password: "",
      password_confirmation: "",
      code: "",
    }
  }

  static rules(
    t: TFunction,
  ): yup.ObjectSchema<ResetPasswordPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      email: yup
        .string()
        .email(t("validations.field_must_be_email"))
        .required(t("validations.field_is_required")),
      password: yup.string().required(t("validations.field_is_required")),
      password_confirmation: yup
        .string()
        .required(t("validations.field_is_required"))
        .oneOf(
          [yup.ref("password"), ""],
          t("validations.passwords_must_match"),
        ),
      code: yup
        .string()
        .typeError(t("validations.field_is_required"))
        .required(t("validations.field_is_required"))
        .min(4, t("validations.min_value", { value: 4 }))
        .max(4, t("validations.max_value", { value: 4 })),
    })
  }
}

export default ResetPasswordValidation
