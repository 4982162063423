import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  GetChatMessageResponseInterface,
  GetChatMessagesResponseInterface,
} from "@/interfaces/chatMessage"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getChatMessages: builder.query<
      GetChatMessagesResponseInterface["data"],
      string
    >({
      query: (query) => `/api/panel/chat-messages${query}`,
      transformResponse: (response: GetChatMessagesResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }) => ({ type: "ChatMessage", id } as const),
              ),
              { type: "ChatMessage", id: "LIST" },
            ]
          : [{ type: "ChatMessage", id: "LIST" }],
      keepUnusedDataFor: 60,
    }),
    getChatMessage: builder.query<
      GetChatMessageResponseInterface["data"],
      string
    >({
      query: (id) => `/api/panel/chat-messages/${id}`,
      transformResponse: (response: GetChatMessageResponseInterface) =>
        response.data,
      providesTags: (result, error, id) => [{ type: "ChatMessage", id }],
      keepUnusedDataFor: 60,
    }),
  }),
})

export const { useGetChatMessagesQuery, useGetChatMessageQuery } = requests
