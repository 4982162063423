import Select, { Option } from "@/resources/components/inputs/select"
import { useTranslation } from "react-i18next"
import _ from "lodash"

type Props = {
  onChange: (value?: string) => void
}

const SearchByVerification = (props: Props) => {
  const { onChange } = props
  const { t } = useTranslation()

  const _onSelectChange = (value: number) => {
    switch (value) {
      case 0:
        onChange(undefined)
        break
      case 1:
        onChange("true")
        break
      case 2:
        onChange("false")
        break
    }
  }

  const onSelectChange = _.debounce(_onSelectChange, 500)

  return (
    <Select
      defaultValue={0}
      onChange={(_, value) => onSelectChange(value as number)}
    >
      <Option value={0}>{t("labels.all")}</Option>
      <Option value={1}>{t("labels.only_verified")}</Option>
      <Option value={2}>{t("labels.only_not_verified")}</Option>
    </Select>
  )
}

export default SearchByVerification
