import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  GetPageResponseInterface,
  GetPagesResponseInterface,
  SavePageContentPropsWithLocale,
} from "@/interfaces/page"
import { SaveDataWithId } from "@/types/base"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getPages: builder.query<GetPagesResponseInterface["data"], void>({
      query: () => `/api/panel/pages`,
      transformResponse: (response: GetPagesResponseInterface) => response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Page", id } as const)),
              { type: "Page", id: "LIST" },
            ]
          : [{ type: "Page", id: "LIST" }],
    }),
    getPage: builder.query<GetPageResponseInterface["data"], string>({
      query: (id) => `/api/panel/pages/${id}`,
      transformResponse: (response: GetPageResponseInterface) => response.data,
      providesTags: (result, error, id) => [{ type: "Page", id }],
    }),
    storePageContent: builder.mutation<
      void,
      SaveDataWithId<SavePageContentPropsWithLocale>
    >({
      query: ({ id, ...body }) => ({
        url: `/api/panel/pages/${id}/page-contents`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Page", id }],
    }),
    deletePageContent: builder.mutation<void, { page_id: string; id: string }>({
      query: ({ page_id, id }) => ({
        url: `/api/panel/pages/${page_id}/page-contents/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { page_id }) => [
        { type: "Page", id: page_id },
      ],
    }),
  }),
})

export const {
  useGetPagesQuery,
  useGetPageQuery,
  useStorePageContentMutation,
  useDeletePageContentMutation,
} = requests
