import React, { FC } from "react"
import { FormInterface } from "@/interfaces/base"
import { StoreAgreementPropsInterface } from "@/interfaces/agreement"
import { useValidation } from "@hypedevgroup/core"
import { storeAgreementValidation } from "@/validations/agreements"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useTranslation } from "react-i18next"
import Card from "@/resources/components/cards/card"
import { FormControl } from "@mui/base"
import { Input } from "@/resources/components/inputs/input"
import { FormHelperText } from "@/resources/components/inputs/formHelperText"
import { Checkbox } from "@/resources/components/inputs/checkbox"
import { useDropzone } from "react-dropzone"
import { Label } from "@/resources/components/inputs/label"
import Select, { Option } from "@/resources/components/inputs/select"
import _ from "lodash"
import { AgreementGroupNameEnum } from "@/enums"
import { Button } from "@/resources/components/buttons/button"

const Form: FC<FormInterface<StoreAgreementPropsInterface>> = ({
  onSubmit,
}) => {
  const { t } = useTranslation()
  const { schema, defaultValues } = useValidation(storeAgreementValidation, t)

  const { control, setValue, handleSubmit, watch } = useForm<
    typeof defaultValues
  >({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const watchFile = watch("file")

  const fileDropzone = useDropzone({
    accept: {
      "application/pdf": [],
    },
    onDropAccepted: (files) => setValue("file", files[0]),
    multiple: false,
  })

  return (
    <div className={"mb-8 flex flex-col gap-8"}>
      <Card className={"flex w-full"}>
        <div className={"flex w-full flex-col gap-16"}>
          <div className={"md:w-[150px]"}>
            <span>{t("agreements.create.title")}</span>
          </div>
          <div className={"grid gap-4 md:grid-cols-4"}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <FormControl {...field} error={!!error}>
                  <Input
                    label={t("labels.name")}
                    placeholder={t("placeholders.name")}
                  />
                  <FormHelperText message={error?.message} />
                </FormControl>
              )}
              name={"name"}
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <FormControl {...field} error={!!error}>
                  <Input
                    label={t("labels.slug")}
                    placeholder={t("placeholders.slug")}
                  />
                  <FormHelperText message={error?.message} />
                </FormControl>
              )}
              name={"slug"}
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <FormControl className={"md:col-span-2"} error={!!error}>
                  <Select
                    fullWidth
                    label={t("labels.groups")}
                    multiple
                    placeholder={t("placeholders.groups")}
                    value={field.value.map((item) => item.name)}
                    onChange={(_, val) =>
                      field.onChange(
                        (val as string[]).map((item) => ({
                          name: item,
                        })),
                      )
                    }
                  >
                    {_.map(AgreementGroupNameEnum, (value, index) => (
                      <Option value={value} key={index}>
                        {t(`agreements.groups.${value}`)}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              )}
              name={"groups"}
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <FormControl
                  className={"md:col-span-4"}
                  {...field}
                  error={!!error}
                >
                  <Input
                    multiline
                    rows={5}
                    label={t("labels.description")}
                    placeholder={t("placeholders.description")}
                  />
                  <FormHelperText message={error?.message} />
                </FormControl>
              )}
              name={"description"}
              control={control}
            />
            <div className={"flex flex-col gap-0.5 md:col-span-4"}>
              <Label label={t("labels.file")} />
              <div className={"w-full"}>
                <div {...fileDropzone.getRootProps({ className: "dropzone" })}>
                  <input {...fileDropzone.getInputProps()} />
                  <p>{t("dropzone.select_files")}</p>
                </div>
              </div>
              {watchFile && <span>{watchFile.name}</span>}
            </div>
            <div className={"flex gap-4 md:col-span-2"}>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error}>
                    <Checkbox
                      label={t("labels.required")}
                      checked={field.value}
                      onChange={field.onChange}
                    />
                  </FormControl>
                )}
                name={"required"}
                control={control}
              />
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error}>
                    <Checkbox
                      label={t("labels.visible")}
                      checked={field.value}
                      onChange={field.onChange}
                    />
                  </FormControl>
                )}
                name={"visible"}
                control={control}
              />
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <FormControl error={!!error}>
                    <Checkbox
                      label={t("labels.only_text")}
                      checked={field.value}
                      onChange={field.onChange}
                    />
                  </FormControl>
                )}
                name={"only_text"}
                control={control}
              />
            </div>
          </div>
          <div className={"flex"}>
            <Button
              variant={"contained"}
              className={"bg-green-900"}
              onClick={handleSubmit(onSubmit)}
            >
              {t("buttons.save")}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  )
}

export { Form }
