import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import React, { Fragment } from "react"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { BreadCrumbInterface, ErrorResponseInterface } from "@/interfaces/base"
import { Label } from "@/resources/components/inputs/label"
import Card from "@/resources/components/cards/card"
import { formatDate } from "@/utils/formatDate"
import { Lightbox } from "@/resources/components/lightbox/lightbox"
import { useHasPermission } from "@/utils/hooks/useHasPermission"
import { PermissionEnum } from "@/enums/permission"
import { Button } from "@/resources/components/buttons/button"
import {
  useChangeStatusMutation,
  useGetUserVerificationQuery,
} from "@/connectors/fansbay/requests/userVerificationRequests"
import { Badge } from "@/resources/components/badge"
import { userVerificationChangeStatus } from "@/resources/views/userVerifications/edit"
import _ from "lodash"
import { ChangeStatusOfUserVerificationPropsInterface } from "@/interfaces/userVerification"
import { toast } from "react-toastify"
import { isType } from "@hypedevgroup/core"
import { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { ResponseCodeEnum } from "@/enums/responseCode"

export const UserVerificationDetails = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { data } = useGetUserVerificationQuery(id!)
  const hasPermission = useHasPermission()
  const [changeStatus] = useChangeStatusMutation()

  const handleChangeStatus = () => {
    userVerificationChangeStatus({
      status: data?.status!,
      note: data?.note!,
    }).then((props) => {
      if (props.status !== data?.status || props.note.length > 0) {
        const data = _.pickBy(
          props,
        ) as ChangeStatusOfUserVerificationPropsInterface

        changeStatus({
          id: id!,
          ...data,
        })
          .unwrap()
          .then(() => {
            toast.success(t("user_verifications.details.updated"))
          })
          .catch((error) => {
            if (isType<FetchBaseQueryError>(error)) {
              const errorData = error as ErrorResponseInterface
              if (
                errorData.status === 400 &&
                errorData.data.code ===
                  ResponseCodeEnum.ILLEGAL_STATUS_TRANSITION
              ) {
                toast.error(t("messages.illegal_status_transition"))
              }
            }
          })
      }
    })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      <div className={"flex justify-end gap-x-4"}>
        {hasPermission(PermissionEnum.USER_VERIFICATIONS_STORE) && (
          <Button onClick={handleChangeStatus} variant={"contained"}>
            {t("user_verifications.details.change_status")}
          </Button>
        )}
      </div>
      {data && (
        <Fragment>
          <Card>
            <div className={"flex flex-col gap-4"}>
              <span className={"text-xl font-medium text-neutral-200"}>
                {t("user_verifications.details.title")}
              </span>
              <div className={"flex flex-col gap-16 py-4 lg:flex-row"}>
                <div>
                  <Label label={t("labels.user")} />
                  <div className={"flex flex-col"}>
                    <span className={"text-s font-medium text-neutral-200"}>
                      {data.user.username}
                    </span>
                    <span className={"text-s font-medium text-gray-25"}>
                      {data.user.email}
                    </span>
                  </div>
                </div>
                <div>
                  <Label label={t("labels.status")} />
                  <span>{t(`user_verifications.status.${data.status}`)}</span>
                </div>
                <div>
                  <Label label={t("labels.date_of_birth")} />
                  {data.user.date_of_birth && (
                    <span>{formatDate(data.user.date_of_birth)}</span>
                  )}
                </div>
                <div>
                  <Label label={t("labels.is_adult")} />
                  <Badge
                    color={data.user.is_adult ? "bg-green-500" : "bg-gray-800"}
                    textColor={"text-neutral-200"}
                  >
                    {data.user.is_adult ? t("labels.yes") : t("labels.no")}
                  </Badge>
                </div>
                <div>
                  <Label label={t("labels.email_verified_at")} />
                  {data.user.email_verified_at && (
                    <span>{formatDate(data.user.email_verified_at)}</span>
                  )}
                </div>
                <div>
                  <Label label={t("labels.created_at")} />
                  <span>{formatDate(data.created_at)}</span>
                </div>
              </div>
              <div>
                <Label label={t("labels.note")} />
                <span>{data.note}</span>
              </div>
            </div>
          </Card>
          <Lightbox
            images={data.urls}
            type={() => "image"}
            renderThumbnail={(image) => image.thumb}
            renderImage={(image) => image.url}
          />
        </Fragment>
      )}
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "modules.breadcrumbs.users",
    url: "#",
  },
  {
    title: "user_verifications.breadcrumbs.user_verifications",
    url: "/user-verifications",
  },
  {
    title: "user_verifications.breadcrumbs.details",
    url: `/user-verifications/${id}`,
  },
]
