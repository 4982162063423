import { create, InstanceProps } from "react-modal-promise"
import React, { Fragment } from "react"
import Modal from "@/resources/components/modals/modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"
import { useValidation } from "@hypedevgroup/core"
import { Controller, useForm } from "react-hook-form"
import { StoreAwardedUserPropsInterface } from "@/interfaces/awardedUser"
import { yupResolver } from "@hookform/resolvers/yup"
import { SearchByUser } from "@/resources/components/inputs/search/searchByUser"
import { FormControl } from "@mui/base"
import { Input } from "@/resources/components/inputs/input"
import { FormHelperText } from "@/resources/components/inputs/formHelperText"
import { Button } from "@/resources/components/buttons/button"
import { Label } from "@/resources/components/inputs/label"
import StoreAwardedUserValidation from "@/validations/awardedUsers/storeAwardedUserValidation"

const AwardedUserCreate: React.FC<InstanceProps<unknown>> = (props) => {
  const { isOpen, onReject, onResolve } = props
  const { t } = useTranslation()
  const { schema, defaultValues } = useValidation(StoreAwardedUserValidation, t)

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<StoreAwardedUserPropsInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const onSubmit = (data: StoreAwardedUserPropsInterface) => {
    onResolve(data)
  }

  return (
    <Modal onClose={onReject} open={isOpen}>
      <Modal.Content>
        <Fragment>
          <div className={"flex w-full flex-row items-center justify-between"}>
            <span className={"text-lg font-semibold text-white"}>
              {t("awarded_users.create.title")}
            </span>
            <FontAwesomeIcon
              onClick={onReject}
              icon={faTimes}
              className={"cursor-pointer text-white"}
            />
          </div>
          <div className={"flex flex-col gap-y-4 py-4"}>
            <Controller
              render={({ field }) => (
                <FormControl error={!!errors.user_id}>
                  <Label label={t("labels.user")} />
                  <SearchByUser onChange={(value) => field.onChange(value)} />
                </FormControl>
              )}
              control={control}
              name={"user_id"}
            />
            <Controller
              render={({ field }) => (
                <FormControl {...field} error={!!errors.order}>
                  <Input
                    placeholder={t("placeholders.order")}
                    label={t("labels.order")}
                  />
                  <FormHelperText message={errors?.order?.message} />
                </FormControl>
              )}
              name={"order"}
              control={control}
            />
            <div className={"flex w-full pt-4"}>
              <Button
                variant={"contained"}
                className={"w-full bg-green-900"}
                onClick={handleSubmit(onSubmit)}
              >
                {t("buttons.save")}
              </Button>
            </div>
          </div>
        </Fragment>
      </Modal.Content>
    </Modal>
  )
}

export const createAwardedUser = create(AwardedUserCreate)
