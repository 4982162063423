import { Avatar } from "@/resources/components/avatar"

export const UserAvatar = ({ url }: { url: string | null }) => {
  return (
    <div className={"hidden lg:block"}>
      {url ? (
        <img
          alt={"avatar"}
          src={url}
          className={"max-h-[100px] w-auto rounded-full "}
        />
      ) : (
        <Avatar avatarSize={"small"} />
      )}
    </div>
  )
}
