import { BreadCrumbInterface } from "@/interfaces/base"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { UserGroupForm } from "@/resources/views/userGroups/parts/form"
import { SaveUserGroupPropsInterface } from "@/interfaces/userGroup"
import { useStoreUserGroupMutation } from "@/connectors/fansbay/requests/userGroupRequests"
import { toast } from "react-toastify"

export const UserGroupCreate = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [storeUserGroup] = useStoreUserGroupMutation()

  const handleSubmit = (data: SaveUserGroupPropsInterface) => {
    storeUserGroup(data)
      .unwrap()
      .then(() => {
        toast.success(t("user_groups.create.success"))
        navigate("/user-groups")
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <UserGroupForm onSubmit={handleSubmit} />
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "modules.breadcrumbs.setting",
    url: "#",
  },
  {
    title: "user_groups.breadcrumbs.user_groups",
    url: "/user-groups",
  },
  {
    title: "user_groups.breadcrumbs.create",
    url: "/user-groups/create",
  },
]
