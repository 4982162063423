import { ChildSidebarMenuItemInterface } from "@/interfaces/base"
import { NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "@/app/hooks"
import { getPermissions } from "@/states/authState"
import { useCallback } from "react"
import { PermissionEnum } from "@/enums/permission"

type Props = {
  children: ChildSidebarMenuItemInterface[]
  title: string
  open: boolean
  closeSidebar: () => void
}

const ChildrenSidebar = (props: Props) => {
  const { children, open, title } = props
  const { t } = useTranslation()
  const userPermissions = useAppSelector(getPermissions)
  const hasPermission = useCallback(
    (permissions: PermissionEnum[]) => {
      return (
        permissions.length === 0 ||
        userPermissions.some((p) => permissions.includes(p))
      )
    },
    [userPermissions],
  )

  return (
    <div
      className={`${
        open ? "w-[312px]" : "w-0"
      } fixed z-[9999] h-full overflow-hidden whitespace-nowrap border-r border-r-gray-700 bg-gray-900 pb-6 pt-4 text-m font-semibold text-gray-25 duration-500 ease-in-out md:translate-x-[80px]`}
    >
      {props.title && (
        <div
          className={"whitespace-nowrap px-7 text-l font-semibold text-white"}
        >
          {t(title)}
        </div>
      )}
      <ul className={"flex flex-col gap-y-1 pt-[44px]"}>
        {children.map(
          (item, index) =>
            hasPermission(item.permissions) && (
              <NavLink
                to={item.path}
                key={index}
                onClick={props.closeSidebar}
                className={({ isActive }) =>
                  `${
                    isActive
                      ? "border-l-2 border-gray-400 bg-gray-800 text-neutral-200"
                      : "ml-[3px]"
                  } overflow-hidden py-3 text-m font-semibold text-gray-25`
                }
              >
                <li
                  key={index}
                  className={"flex flex-row items-center gap-x-3 px-7"}
                >
                  <span>{item.icon}</span>
                  <span className={"text-m font-semibold"}>
                    {t(item.title)}
                  </span>
                </li>
              </NavLink>
            ),
        )}
      </ul>
    </div>
  )
}

export default ChildrenSidebar
