import React from "react"

type Props = {
  children: React.ReactNode | React.ReactNode[]
}

const Layout = (props: Props) => {
  return (
    <div
      className={
        "flex h-full w-full items-center justify-center bg-gray-950 text-neutral-200"
      }
    >
      <div className={"flex w-[424px] flex-col"}>
        <span className={"text-center text-4xl font-medium text-neutral-200"}>
          {import.meta.env.VITE_APP_NAME}
        </span>
        {props.children}
      </div>
    </div>
  )
}

export default Layout
