import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  GetIssueMessagesPropsInterface,
  GetIssueMessagesResponseInterface,
} from "@/interfaces/issueMessage"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getIssueMessages: builder.query<
      GetIssueMessagesResponseInterface["data"],
      GetIssueMessagesPropsInterface
    >({
      query: ({ issue_id, query }) =>
        `/api/panel/issues/${issue_id}/messages${query}`,
      transformResponse: (response: GetIssueMessagesResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }) => ({ type: "IssueMessage", id } as const),
              ),
              {
                type: "IssueMessage",
                id: "LIST",
              },
            ]
          : [{ type: "IssueMessage", id: "LIST" }],
    }),
    sendMessage: builder.mutation<void, { issue_id: string; data: FormData }>({
      query: ({ issue_id, data }) => ({
        url: `/api/panel/issues/${issue_id}/messages`,
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: [{ type: "IssueMessage", id: "LIST" }],
    }),
  }),
})

export const { useGetIssueMessagesQuery, useSendMessageMutation } = requests
