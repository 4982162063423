import { useNavigate, useParams } from "react-router-dom"
import {
  useDeleteCurrencyMutation,
  useGetCurrencyQuery,
} from "@/connectors/fansbay/requests/currencyRequests"
import { useTranslation } from "react-i18next"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { PermissionEnum } from "@/enums/permission"
import { Button, ButtonLink } from "@/resources/components/buttons/button"
import { useHasPermission } from "@/utils/hooks/useHasPermission"
import { BreadCrumbInterface, ErrorResponseInterface } from "@/interfaces/base"
import Card from "@/resources/components/cards/card"
import { toast } from "react-toastify"
import { ResponseCodeEnum } from "@/enums/responseCode"

export const CurrencyDetails = () => {
  const { id } = useParams<{ id: string }>()
  const { data } = useGetCurrencyQuery(id!)
  const { t } = useTranslation()
  const hasPermission = useHasPermission()
  const [deleteCurrency] = useDeleteCurrencyMutation()
  const navigate = useNavigate()

  const handleDeleteCurrency = () => {
    deleteCurrency(id!)
      .unwrap()
      .then(() => {
        toast.success(t("currencies.destroy.success"))
        navigate("/currencies")
      })
      .catch((error: ErrorResponseInterface) => {
        if (
          error.status === 400 &&
          error.data.code === ResponseCodeEnum.COUNTRY_HAVE_USERS
        ) {
          toast.error(t("currencies.show.currency_have_users"))
        }
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      <div className={"flex flex-row-reverse gap-x-4"}>
        {hasPermission(PermissionEnum.CURRENCIES_STORE) && (
          <ButtonLink variant={"contained"} to={`/currencies/${id}/edit`}>
            {t("currencies.show.edit")}
          </ButtonLink>
        )}
        {hasPermission(PermissionEnum.CURRENCIES_DESTROY) && (
          <Button
            variant={"contained"}
            onClick={handleDeleteCurrency}
            className={"bg-red-600"}
          >
            {t("currencies.show.delete")}
          </Button>
        )}
      </div>
      {data && (
        <Card>
          <div className={"flex flex-col gap-y-4"}>
            <div className={"flex flex-col gap-2"}>
              <span className={"font-semibold"}>
                {t("labels.currency_name")}
              </span>
              <span>{data?.name}</span>
            </div>
            <div className={"flex flex-col gap-2"}>
              <span className={"font-semibold"}>
                {t("labels.currency_code")}
              </span>
              <span>{data?.code}</span>
            </div>
            <div className={"flex flex-col gap-2"}>
              <span className={"font-semibold"}>
                {t("labels.currency_symbol")}
              </span>
              <span>{data?.symbol}</span>
            </div>
            <div className={"flex flex-col gap-2"}>
              <span className={"font-semibold"}>
                {t("labels.currency_multiplier")}
              </span>
              <span>{data?.multiplier}</span>
            </div>
          </div>
        </Card>
      )}
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "currencies.breadcrumbs.currencies",
    url: "/currencies",
  },
  {
    title: "currencies.breadcrumbs.details",
    url: `/currencies/${id}`,
  },
]
