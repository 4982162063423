import { TFunction } from "i18next"
import * as yup from "yup"
import { SaveSubscriptionPlanPropsInterface } from "@/interfaces/subscriptionPlan"

export default class SaveSubscriptionPlanValidation {
  static rules(
    t: TFunction,
  ): yup.ObjectSchema<SaveSubscriptionPlanPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      user_id: yup
        .number()
        .required(t("validations.field_is_required"))
        .notOneOf([0], t("validations.field_is_required")),
      price: yup
        .number()
        .typeError(t("validations.field_is_required"))
        .required(t("validations.field_is_required"))
        .min(1, t("validations.min_value", { value: 1 })),
      duration: yup
        .number()
        .typeError(t("validations.field_is_required"))
        .required(t("validations.field_is_required"))
        .min(1, t("validations.min_value", { value: 1 }))
        .max(12, t("validations.max_value", { value: 12 })),
    })
  }

  static defaultValues(): SaveSubscriptionPlanPropsInterface {
    return {
      user_id: 0,
      price: "",
      duration: "",
    }
  }
}
