import { Fragment, useMemo } from "react"
import Divider from "@/resources/components/layouts/auth/divider"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import LoginValidation from "@/validations/auth/loginValidation"
import { Controller, useForm } from "react-hook-form"
import { LoginPropsInterface } from "@/interfaces/auth"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormControl } from "@mui/base"
import { Input } from "@/resources/components/inputs/input"
import { FormHelperText } from "@/resources/components/inputs/formHelperText"
import { Button } from "@/resources/components/buttons/button"
import { useAppDispatch } from "@/app/hooks"
import { loginRequest } from "@/connectors/fansbay/requests/authRequests"
import { toast } from "react-toastify"

const LoginScreen = () => {
  const { t } = useTranslation()
  const schema = useMemo(() => LoginValidation.rules(t), [t])
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginPropsInterface>({
    defaultValues: LoginValidation.defaultValues(),
    resolver: yupResolver(schema),
  })

  const onSubmit = (data: LoginPropsInterface) => {
    dispatch(loginRequest(data))
      .unwrap()
      .then((response) => {
        if (response.data.user.permissions.length > 0) {
          navigate("/dashboard")
        } else {
          toast.error(t("auth.login.error"))
        }
      })
      .catch(() => toast.error(t("auth.login.error")))
  }

  return (
    <Fragment>
      <Divider title={t("auth.login.title")} />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={"flex flex-col gap-y-6"}
      >
        <Controller
          control={control}
          name={"email"}
          render={({ field }) => (
            <FormControl {...field} error={!!errors.email}>
              <Input
                label={t("labels.email")}
                type={"email"}
                placeholder={t("placeholders.email")}
              />
              <FormHelperText message={errors.email?.message} />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name={"password"}
          render={({ field }) => (
            <FormControl {...field} error={!!errors.password}>
              <Input
                label={t("labels.password")}
                type={"password"}
                placeholder={t("placeholders.password")}
              />
              <FormHelperText message={errors.password?.message} />
            </FormControl>
          )}
        />
        <div className={"flex justify-end"}>
          <Link to={"/forgot-password"} className={"text-sm text-primary-600"}>
            {t("auth.login.forgot_password")}
          </Link>
        </div>
        <Button type={"submit"} size={"large"} variant={"contained"}>
          {t("auth.login.button")}
        </Button>
      </form>
    </Fragment>
  )
}

export default LoginScreen
