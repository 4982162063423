import { SearchPropsInterface } from "@/resources/views/withdraws/parts/search/search.types"
import { useEffect, useState } from "react"
import { SearchByUser } from "@/resources/components/inputs/search/searchByUser"
import { SearchByCurrency } from "@/resources/components/inputs/search/searchByCurrency"
import { SearchByStatus } from "@/resources/views/withdraws/parts/search/searchByStatus"
import { SearchByDate } from "@/resources/components/inputs/search/searchByDate"
import { useTranslation } from "react-i18next"
import { WithdrawStatusEnum } from "@/enums/widthdrawStatus"

export const Search = (props: SearchPropsInterface) => {
  const [userId, setUserId] = useState<number | string | undefined>("")
  const [currencyId, setCurrencyId] = useState<number | string | undefined>("")
  const [status, setStatus] = useState<WithdrawStatusEnum>()
  const [withdrawAt, setWithdrawAt] = useState<string>("")
  const { t } = useTranslation()

  useEffect(() => {
    props.onUpdateParams({
      user_id: userId,
      currency_id: currencyId,
      status,
      withdraw_at: withdrawAt,
    })
  }, [userId, withdrawAt, currencyId, status])

  return (
    <div className={"flex w-full"}>
      <div className={"grid gap-x-4 md:grid-cols-4"}>
        <SearchByUser onChange={(value) => setUserId(value)} />
        <SearchByCurrency onChange={(value) => setCurrencyId(value)} />
        <SearchByStatus onChange={(value) => setStatus(value)} value={status} />
        <SearchByDate
          onChange={(value) => setWithdrawAt(value)}
          placeholder={t("placeholders.withdraw_at")}
          value={withdrawAt}
        />
      </div>
    </div>
  )
}
