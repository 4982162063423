import { useParams } from "react-router-dom"
import { useGetUserQuery } from "@/connectors/fansbay/requests/userRequests"
import React, { Fragment } from "react"
import { Label } from "@/resources/components/inputs/label"
import { UserAvatar } from "@/resources/components/userAvatar"
import { useTranslation } from "react-i18next"
import { formatDate } from "@/utils/formatDate"
import { Badge } from "@/resources/components/badge"
import parse from "html-react-parser"

export const Details = () => {
  const { id } = useParams<{ id: string }>()
  const { data } = useGetUserQuery(id!)
  const { t } = useTranslation()

  return (
    <Fragment>
      {data && (
        <Fragment>
          <div
            className={
              "flex flex-col flex-wrap gap-16 px-4 pb-4 pt-12 text-neutral-200 lg:flex-row"
            }
          >
            <div>
              <Label label={t("labels.user")} />
              <div className={"flex flex-row items-center gap-x-2"}>
                <UserAvatar url={data.avatar.thumb_url} />
                <span>{data.username}</span>
              </div>
            </div>
            <div>
              <Label label={t("labels.email")} />
              <span>{data.email}</span>
            </div>
            <div>
              <Label label={t("labels.email_verified_at")} />
              <span>{data.email_verified_at}</span>
            </div>
            <div>
              <Label label={t("labels.date_of_birth")} />
              <span>{data.date_of_birth}</span>
            </div>
            <div>
              <Label label={t("labels.gender")} />
              <span>{t(`genders.${data.gender}`)}</span>
            </div>
            <div>
              <Label label={t("labels.location")} />
              <span>{data.location}</span>
            </div>
            <div>
              <Label label={t("labels.verified_at")} />
              {data.verified_at && <span>{formatDate(data.verified_at)}</span>}
            </div>
            <div>
              <Label label={t("labels.website")} />
              {data.website && (
                <a href={data.website} target="_blank" rel="noreferrer">
                  {data.website}
                </a>
              )}
            </div>
            <div>
              <Label label={t("labels.open_profile")} />
              <Badge
                color={data.open_profile ? "bg-green-500" : "bg-gray-800"}
                textColor={"text-neutral-200"}
              >
                {data.open_profile ? t("labels.yes") : t("labels.no")}
              </Badge>
            </div>
            <div>
              <Label label={t("labels.public_account")} />
              <Badge
                color={data.public_account ? "bg-green-500" : "bg-gray-800"}
                textColor={"text-neutral-200"}
              >
                {data.public_account ? t("labels.yes") : t("labels.no")}
              </Badge>
            </div>
            <div>
              <Label label={t("labels.role")} />
              <span>{data.role?.name}</span>
            </div>
            <div>
              <Label label={t("labels.country")} />
              <span>{data.country.name}</span>
            </div>
            <div>
              <Label label={t("labels.bio")} />
              {data.bio ? (
                <span>{parse(data.bio)}</span>
              ) : (
                <span>{t("labels.empty")}</span>
              )}
            </div>
            <div>
              <Label label={t("labels.cover")} />
              {data.cover && (
                <img
                  className={"h-auto w-full"}
                  src={data.cover}
                  alt={data.cover}
                />
              )}
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}
