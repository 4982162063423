import { BreadCrumbInterface } from "@/interfaces/base"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { RoleForm } from "@/resources/views/roles/parts/form"
import { SaveRoleInterface } from "@/interfaces/role"
import { useTranslation } from "react-i18next"
import { useStoreRoleMutation } from "@/connectors/fansbay/requests/roleRequests"
import { toast } from "react-toastify"
import { useState } from "react"
import { BackendValidationErrorInterface } from "@hypedevgroup/core"
import { useNavigate } from "react-router-dom"

export const RoleCreate = () => {
  const { t } = useTranslation()
  const [storeRole] = useStoreRoleMutation()
  const [errors, setErrors] =
    useState<BackendValidationErrorInterface<SaveRoleInterface>>()
  const navigate = useNavigate()

  const handleSubmit = (data: SaveRoleInterface) => {
    storeRole(data)
      .unwrap()
      .then(() => {
        toast.success(t("roles.create.success"))
        navigate("/roles")
      })
      .catch((error) => {
        if (error.status === 422) {
          setErrors(error.data)
        }
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <RoleForm onSubmit={handleSubmit} errors={errors} />
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "roles.breadcrumbs.module",
    url: "#",
  },
  {
    title: "roles.breadcrumbs.roles",
    url: "/roles",
  },
  {
    title: "roles.breadcrumbs.create",
    url: "/roles/create",
  },
]
