import { useNavigate, useParams } from "react-router-dom"
import { useRedirectWithError } from "@/utils/hooks/useRedirectWithError"
import { BreadCrumbInterface } from "@/interfaces/base"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { useState } from "react"
import { BackendValidationErrorInterface } from "@hypedevgroup/core"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import {
  useGetTaxRateQuery,
  useUpdateTaxRateMutation,
} from "@/connectors/fansbay/requests/taxRateRequests"
import { SaveTaxRatePropsInterface } from "@/interfaces/taxRate"
import { TaxRateForm } from "@/resources/views/taxRates/parts/form"

export const TaxRateEdit = () => {
  const { id } = useParams<{ id: string }>()
  const { data, error } = useGetTaxRateQuery(id!)
  const [update] = useUpdateTaxRateMutation()
  const [errors, setErrors] =
    useState<BackendValidationErrorInterface<SaveTaxRatePropsInterface>>()
  const navigate = useNavigate()
  const { t } = useTranslation()

  useRedirectWithError(error, "/error")

  const handleSubmit = (data: SaveTaxRatePropsInterface) => {
    update({
      ...data,
      id: id as string,
    })
      .unwrap()
      .then(() => {
        toast.success(t("tax_rates.edit.success"))
        navigate("/tax-rates")
      })
      .catch((error) => {
        if (error.status === 422) {
          setErrors(error.data)
        }
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      <TaxRateForm data={data} onSubmit={handleSubmit} errors={errors} />
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "tax_rates.breadcrumbs.tax_rates",
    url: "/tax-rates",
  },
  {
    title: "tax_rates.breadcrumbs.edit",
    url: `/tax-rates/${id}/edit`,
  },
]
