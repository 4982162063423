import { PrivateRouterInterface } from "@/utils/routes/privateRouter/privateRouter.types"
import Dashboard from "@/resources/views/dashboard/dashboard"
import UserList from "@/resources/views/users"
import UserCreate from "@/resources/views/users/create"
import { RoleList } from "@/resources/views/roles"
import { RoleCreate } from "@/resources/views/roles/create"
import { RoleEdit } from "@/resources/views/roles/edt"
import { RoleDetails } from "@/resources/views/roles/show"
import { DefaultPlanList } from "@/resources/views/defaultPlans"
import { DefaultPlanCreate } from "@/resources/views/defaultPlans/create"
import { DefaultPlanDetails } from "@/resources/views/defaultPlans/show"
import { PermissionEnum } from "@/enums/permission"
import { DefaultPlanEdit } from "@/resources/views/defaultPlans/edit"
import { CurrencyList } from "@/resources/views/currencies"
import { CurrencyCreate } from "@/resources/views/currencies/create"
import { CurrencyDetails } from "@/resources/views/currencies/show"
import { CurrencyEdit } from "@/resources/views/currencies/edit"
import { SubscriptionPlanList } from "@/resources/views/plans"
import { SubscriptionPlanCreate } from "@/resources/views/plans/create"
import { SubscriptionPlanDetails } from "@/resources/views/plans/show"
import { SubscriptionPlanEdit } from "@/resources/views/plans/edit"
import { ExchangeRateList } from "@/resources/views/exchangeRates"
import { SubscriptionList } from "@/resources/views/subscriptions"
import { SubscriptionCreate } from "@/resources/views/subscriptions/create"
import { SubscriptionDetails } from "@/resources/views/subscriptions/show"
import { SubscriptionEdit } from "@/resources/views/subscriptions/edit"
import { PaymentProviderList } from "@/resources/views/paymentProviders"
import { PaymentProviderEdit } from "@/resources/views/paymentProviders/edit"
import { PostList } from "@/resources/views/posts"
import { PostDetails } from "@/resources/views/posts/show"
import { WithdrawList } from "@/resources/views/withdraws"
import { WithdrawDetails } from "@/resources/views/withdraws/show"
import { TaxRateList } from "@/resources/views/taxRates"
import { TaxRateCreate } from "@/resources/views/taxRates/create"
import { TaxRateDetails } from "@/resources/views/taxRates/show"
import { TaxRateEdit } from "@/resources/views/taxRates/edt"
import { ProvisionRateList } from "@/resources/views/provisionRates"
import { ProvisionRateCreate } from "@/resources/views/provisionRates/create"
import { ProvisionRateDetails } from "@/resources/views/provisionRates/show"
import { ProvisionRateEdit } from "@/resources/views/provisionRates/edt"
import { IssueList } from "@/resources/views/issues"
import { IssueDetails } from "@/resources/views/issues/show"
import { UserGroupList } from "@/resources/views/userGroups"
import { UserGroupCreate } from "@/resources/views/userGroups/create"
import { UserGroupDetails } from "@/resources/views/userGroups/show"
import { UserGroupEdit } from "@/resources/views/userGroups/edt"
import { UserVerificationList } from "@/resources/views/userVerifications"
import { UserVerificationDetails } from "@/resources/views/userVerifications/show"
import { AwardedUserList } from "@/resources/views/awardedUsers"
import { MessageFilterList } from "@/resources/views/messages/filters"
import { MessageFilterCreate } from "@/resources/views/messages/filters/create"
import { MessageFilterEdit } from "@/resources/views/messages/filters/edit"
import { ChatMessageList } from "@/resources/views/messages"
import { ChatMessageDetails } from "@/resources/views/messages/show"
import { PageList } from "@/resources/views/pages"
import { UserDetails } from "@/resources/views/users/show"
import { PageEdit } from "@/resources/views/pages/edit"
import { PaymentList } from "@/resources/views/payments"
import { SettingList } from "@/resources/views/settings"
import { SettingEdit } from "@/resources/views/settings/edit"
import { UserEdit } from "@/resources/views/users/edt"
import { AgreementCreate, AgreementList } from "@/resources/views/agreements"

export const privateRoutes: PrivateRouterInterface[] = [
  {
    path: "/dashboard",
    component: Dashboard,
    permissions: [],
  },
  {
    path: "/users",
    component: UserList,
    permissions: [],
  },
  {
    path: "/users/:id",
    component: UserDetails,
    permissions: [],
  },
  {
    path: "/users/create",
    component: UserCreate,
    permissions: [],
  },
  {
    path: "/users/:id/edit",
    component: UserEdit,
    permissions: [PermissionEnum.USERS_STORE],
  },
  {
    path: "/roles",
    component: RoleList,
    permissions: [],
  },
  {
    path: "/roles/create",
    component: RoleCreate,
    permissions: [],
  },
  {
    path: "/roles/:id/edit",
    component: RoleEdit,
    permissions: [],
  },
  {
    path: "/roles/:id",
    component: RoleDetails,
    permissions: [],
  },
  {
    path: "/default-plans",
    component: DefaultPlanList,
    permissions: [],
  },
  {
    path: "/default-plans/create",
    component: DefaultPlanCreate,
    permissions: [],
  },
  {
    path: "/default-plans/:id",
    component: DefaultPlanDetails,
    permissions: [PermissionEnum.DEFAULT_PLANS_SHOW],
  },
  {
    path: "/default-plans/:id/edit",
    component: DefaultPlanEdit,
    permissions: [],
  },
  {
    path: "/currencies",
    component: CurrencyList,
    permissions: [],
  },
  {
    path: "/currencies/create",
    component: CurrencyCreate,
    permissions: [],
  },
  {
    path: "/currencies/:id",
    component: CurrencyDetails,
    permissions: [],
  },
  {
    path: "/currencies/:id/edit",
    component: CurrencyEdit,
    permissions: [],
  },
  {
    path: "/subscription-plans",
    component: SubscriptionPlanList,
    permissions: [],
  },
  {
    path: "/subscription-plans/create",
    component: SubscriptionPlanCreate,
    permissions: [],
  },
  {
    path: "/subscription-plans/:id",
    component: SubscriptionPlanDetails,
    permissions: [],
  },
  {
    path: "/subscription-plans/:id/edit",
    component: SubscriptionPlanEdit,
    permissions: [],
  },
  {
    path: "/exchange-rates",
    component: ExchangeRateList,
    permissions: [],
  },
  {
    path: "/subscriptions",
    component: SubscriptionList,
    permissions: [],
  },
  {
    path: "/subscriptions/create",
    component: SubscriptionCreate,
    permissions: [],
  },
  {
    path: "/subscriptions/:id",
    component: SubscriptionDetails,
    permissions: [],
  },
  {
    path: "/subscriptions/:id/edit",
    component: SubscriptionEdit,
    permissions: [],
  },
  {
    path: "/payment-providers",
    component: PaymentProviderList,
    permissions: [],
  },
  {
    path: "/payment-providers/:id/edit",
    component: PaymentProviderEdit,
    permissions: [],
  },
  {
    path: "/posts",
    component: PostList,
    permissions: [],
  },
  {
    path: "/posts/:id",
    component: PostDetails,
    permissions: [],
  },
  {
    path: "/withdraws",
    component: WithdrawList,
    permissions: [],
  },
  {
    path: "/withdraws/:id",
    component: WithdrawDetails,
    permissions: [],
  },
  {
    path: "/tax-rates",
    component: TaxRateList,
    permissions: [],
  },
  {
    path: "/tax-rates/create",
    component: TaxRateCreate,
    permissions: [],
  },
  {
    path: "/tax-rates/:id",
    component: TaxRateDetails,
    permissions: [],
  },
  {
    path: "/tax-rates/:id/edit",
    component: TaxRateEdit,
    permissions: [],
  },
  {
    path: "/provision-rates",
    component: ProvisionRateList,
    permissions: [],
  },
  {
    path: "/provision-rates/create",
    component: ProvisionRateCreate,
    permissions: [],
  },
  {
    path: "/provision-rates/:id",
    component: ProvisionRateDetails,
    permissions: [],
  },
  {
    path: "/provision-rates/:id/edit",
    component: ProvisionRateEdit,
    permissions: [],
  },
  {
    path: "/issues",
    component: IssueList,
    permissions: [],
  },
  {
    path: "/issues/:id",
    component: IssueDetails,
    permissions: [],
  },
  {
    path: "/user-groups",
    component: UserGroupList,
    permissions: [],
  },
  {
    path: "/user-groups/create",
    component: UserGroupCreate,
    permissions: [],
  },
  {
    path: "/user-groups/:id",
    component: UserGroupDetails,
    permissions: [],
  },
  {
    path: "/user-groups/:id/edit",
    component: UserGroupEdit,
    permissions: [],
  },
  {
    path: "/user-verifications",
    component: UserVerificationList,
    permissions: [],
  },
  {
    path: "/user-verifications/:id",
    component: UserVerificationDetails,
    permissions: [],
  },
  {
    path: "/awarded-users",
    component: AwardedUserList,
    permissions: [],
  },
  {
    path: "/messages/filters",
    component: MessageFilterList,
    permissions: [],
  },
  {
    path: "/messages/filters/create",
    component: MessageFilterCreate,
    permissions: [],
  },
  {
    path: "/messages/filters/:id/edit",
    component: MessageFilterEdit,
    permissions: [],
  },
  {
    path: "/messages",
    component: ChatMessageList,
    permissions: [],
  },
  {
    path: "/messages/:id",
    component: ChatMessageDetails,
    permissions: [],
  },
  {
    path: "/pages",
    component: PageList,
    permissions: [],
  },
  {
    path: "/pages/:id/edit",
    component: PageEdit,
    permissions: [],
  },
  {
    path: "/payments",
    component: PaymentList,
    permissions: [PermissionEnum.PAYMENTS_SHOW],
  },
  {
    path: "/settings",
    component: SettingList,
    permissions: [PermissionEnum.SETTINGS],
  },
  {
    path: "/settings/:id/edit",
    component: SettingEdit,
    permissions: [PermissionEnum.SETTINGS],
  },
  {
    path: "/agreements",
    component: AgreementList,
    permissions: [],
  },
  {
    path: "/agreements/create",
    component: AgreementCreate,
    permissions: [],
  },
  {
    path: "*",
    component: Dashboard,
    permissions: [],
  },
]
