import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  ForgotPasswordPropsInterface,
  LoginPropsInterface,
  LogInResponseInterface,
  ResetPasswordPropsInterface,
} from "@/interfaces/auth"
import axios, { AxiosError } from "axios"
import { SuccessResponseInterface } from "@/interfaces/base"
import { RootState } from "@/app/store"

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
}

const url = import.meta.env.VITE_FANSBAY_API_URL

export const loginRequest = createAsyncThunk<
  LogInResponseInterface,
  LoginPropsInterface
>("auth/login", async (data, thunkAPI) => {
  try {
    const response = await axios.post(
      `${url}/api/v1/auth/login`,
      {
        ...data,
        service: "admin_panel",
      },
      {
        headers: headers,
      },
    )

    return response.data
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return thunkAPI.rejectWithValue({
        error: error.response.data,
        code: error.response.status,
      })
    }

    return thunkAPI.rejectWithValue({
      error: null,
      code: 500,
    })
  }
})

export const forgotPasswordRequest = createAsyncThunk<
  SuccessResponseInterface,
  ForgotPasswordPropsInterface
>("auth/forgot-password", async (data, thunkAPI) => {
  try {
    const response = await axios.post(
      `${url}/api/v1/auth/forgot-password`,
      data,
      {
        headers: headers,
      },
    )

    return response.data
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return thunkAPI.rejectWithValue({
        error: error.response.data,
        code: error.response.status,
      })
    }

    return thunkAPI.rejectWithValue({
      error: null,
      code: 500,
    })
  }
})

export const resetPasswordRequestMutation = createAsyncThunk<
  LogInResponseInterface,
  ResetPasswordPropsInterface
>("auth/reset-password", async (data, thunkAPI) => {
  try {
    const response = await axios.post(
      `${url}/api/v1/auth/reset-password`,
      data,
      {
        headers: headers,
      },
    )

    return response.data
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return thunkAPI.rejectWithValue({
        error: error.response.data,
        code: error.response.status,
      })
    }

    return thunkAPI.rejectWithValue({
      error: null,
      code: 500,
    })
  }
})

export const logoutRequestMutation = createAsyncThunk<
  SuccessResponseInterface,
  void,
  { state: RootState }
>("auth/logout", async (_, thunkAPI) => {
  try {
    const response = await axios.post(`${url}/api/v1/auth/logout`, [], {
      headers: {
        ...headers,
        Authorization: `Bearer ${thunkAPI.getState().auth.token}`,
      },
    })

    return response.data
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return thunkAPI.rejectWithValue({
        error: error.response.data,
        code: error.response.status,
      })
    }

    return thunkAPI.rejectWithValue({
      error: null,
      code: 500,
    })
  }
})
