import React, { useEffect, useMemo, useState } from "react"
import BaseTable from "@/resources/components/table/table"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { TFunction } from "i18next"
import RowAction from "@/resources/components/table/rowAction"
import { ColumnSetting } from "@/resources/components/table/columnSetting"
import { Query, usePagination } from "@hypedevgroup/core"
import { toIntl } from "@/utils/toIntl"
import { PaymentInterface } from "@/interfaces/payment"
import { useGetPaymentsQuery } from "@/connectors/fansbay/requests/paymentRequests"
import { formatDate } from "@/utils/formatDate"

const columnHelper = createColumnHelper<PaymentInterface>()

const columns = (
  t: TFunction,
  locale: string,
  onEdit: (id: string) => void,
) => [
  columnHelper.display({
    id: "username",
    header: t("labels.username"),
    cell: ({ row }) => (
      <div className={"flex flex-col"}>
        <span className={"text-s font-medium text-neutral-200"}>
          {row.original.user.username}
        </span>
        <span className={"text-s font-medium text-gray-25"}>
          {row.original.user.email}
        </span>
      </div>
    ),
  }),
  columnHelper.display({
    id: "receiver",
    header: t("labels.receiver"),
    cell: ({ row }) => (
      <div className={"flex flex-col"}>
        <span className={"text-s font-medium text-neutral-200"}>
          {row.original.to?.username}
        </span>
        <span className={"text-s font-medium text-gray-25"}>
          {row.original.to?.email}
        </span>
      </div>
    ),
  }),
  columnHelper.accessor("type", {
    header: t("labels.type"),
    id: "type",
    cell: (cell) => t(`payments.types.${cell.getValue()}`),
  }),
  columnHelper.accessor("amount", {
    header: t("labels.amount"),
    id: "amount",
    cell: (cell) =>
      toIntl(cell.getValue(), cell.row.original.currency.code, locale),
  }),
  columnHelper.accessor("status", {
    header: t("labels.status"),
    id: "status",
    cell: (cell) => t(`payments.statuses.${cell.getValue()}`),
  }),
  columnHelper.accessor("provider", {
    header: t("labels.payment_provider"),
    id: "provider",
    cell: (cell) => t(`payments.providers.${cell.getValue()}`),
  }),
  columnHelper.accessor("payment_date", {
    id: "payment_date",
    header: t("labels.payment_date"),
    cell: (cell) =>
      cell.getValue() ? formatDate(cell.getValue()!) : t("labels.not_paid"),
  }),
  columnHelper.accessor("external_id", {
    id: "external_id",
    header: t("labels.external_id"),
  }),
  columnHelper.display({
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => (
      <RowAction onClick={() => onEdit(row.original.id.toString())} />
    ),
  }),
]

const Table = (props: { query: Query; handleEdit: (id: string) => void }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const [data, setData] = useState<PaymentInterface[]>([])
  const [pagination, setPagination] = usePagination()

  const query = useMemo(
    () => props.query.limit(pagination.pageSize).page(pagination.pageIndex),
    [pagination, props.query],
  )
  const { data: apiData } = useGetPaymentsQuery(query.url())

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const table = useReactTable({
    columns: columns(t, language, props.handleEdit),
    data: data,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  return (
    <div className={"flex flex-col"}>
      <div className={"flex flex-row-reverse px-8 py-4"}>
        <ColumnSetting table={table} />
      </div>
      <BaseTable pagination={apiData?.pagination} table={table} />
    </div>
  )
}

export default Table
