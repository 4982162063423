import React from "react"
import Sidebar from "@/resources/components/layouts/app/sidebar"

type Props = {
  children: React.ReactNode
}

const Layout = (props: Props) => {
  return (
    <div
      className={
        "flex h-full w-full flex-col overflow-y-auto bg-gray-950 md:flex-row"
      }
    >
      <Sidebar />
      <div className={"h-full p-8 md:ml-[80px] md:w-[calc(100%_-_80px)]"}>
        {props.children}
      </div>
    </div>
  )
}

export default Layout
