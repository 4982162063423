import { BreadCrumbInterface } from "@/interfaces/base"
import { useTranslation } from "react-i18next"
import {
  useDeleteMessageFilterMutation,
  useGetMessageFilterQuery,
  useUpdateMessageFiltersMutation,
} from "@/connectors/fansbay/requests/messageFilterRequests"
import { SaveChatMessageFilterPropsInterface } from "@/interfaces/chatMessageFilter"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { MessageFilterForm } from "@/resources/views/messages/filters/parts/form"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import { useState } from "react"
import { BackendValidationErrorInterface } from "@hypedevgroup/core"
import { PermissionEnum } from "@/enums/permission"
import { Button } from "@/resources/components/buttons/button"
import { useHasPermission } from "@/utils/hooks/useHasPermission"

export const MessageFilterEdit = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { data } = useGetMessageFilterQuery(id!)
  const [updateFilter] = useUpdateMessageFiltersMutation()
  const [deleteFilter] = useDeleteMessageFilterMutation()
  const navigate = useNavigate()
  const [errors, setErrors] =
    useState<
      BackendValidationErrorInterface<SaveChatMessageFilterPropsInterface>
    >()
  const hasPermission = useHasPermission()

  const handleSubmit = (data: SaveChatMessageFilterPropsInterface) => {
    updateFilter({
      ...data,
      id: id!,
    })
      .unwrap()
      .then(() => {
        toast.success(t("chat_message_filters.edit.success"))
        navigate("/messages/filters")
      })
      .catch((errors) => {
        if (errors.status === 422) {
          setErrors(errors.data)
        }
      })
  }

  const handleDelete = () => {
    deleteFilter(id!)
      .unwrap()
      .then(() => {
        toast.success(t("chat_message_filters.edit.deleted"))
        navigate("/messages/filters")
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      <div className={"flex flex-row-reverse"}>
        {hasPermission(PermissionEnum.MESSAGE_FILTERS_DESTROY) && (
          <Button
            variant={"contained"}
            onClick={handleDelete}
            className={"bg-red-500"}
          >
            {t("chat_message_filters.edit.delete")}
          </Button>
        )}
      </div>
      <MessageFilterForm data={data} errors={errors} onSubmit={handleSubmit} />
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "modules.breadcrumbs.content",
    url: "#",
  },
  {
    title: "chat_messages.breadcrumbs.messages",
    url: "/messages",
  },
  {
    title: "chat_message_filters.breadcrumbs.filters",
    url: "/messages/filters",
  },
  {
    title: "chat_message_filters.breadcrumbs.edit",
    url: `/messages/filters/${id}/edit`,
  },
]
