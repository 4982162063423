import { SearchProps } from "@/resources/views/subscriptions/parts/search/search.types"
import { useEffect, useState } from "react"
import { SearchByUser } from "@/resources/components/inputs/search/searchByUser"
import { SearchByDate } from "@/resources/components/inputs/search/searchByDate"
import { useTranslation } from "react-i18next"

type Props = {
  onUpdateParameters: (parameters: SearchProps) => void
}

export const Search = (props: Props) => {
  const [userId, setUserId] = useState<number | string | undefined>("")
  const [subscribableId, setSubscribableId] = useState<
    number | string | undefined
  >("")
  const [fromDate, setFromDate] = useState<string | undefined>("")
  const [toDate, setToDate] = useState<string | undefined>("")
  const { t } = useTranslation()

  useEffect(() => {
    props.onUpdateParameters({
      user_id: userId,
      subscribable_id: subscribableId,
      from: fromDate,
      to: toDate,
    })
  }, [userId, subscribableId, fromDate, toDate])

  return (
    <div className={"flex w-full"}>
      <div className={"grid gap-x-4 md:grid-cols-4"}>
        <div>
          <SearchByUser onChange={(value) => setUserId(value)} />
        </div>
        <div>
          <SearchByUser onChange={(value) => setSubscribableId(value)} />
        </div>
        <SearchByDate
          onChange={(value) => setFromDate(value)}
          value={fromDate}
          placeholder={t("placeholders.from_date")}
        />
        <SearchByDate
          onChange={(value) => setToDate(value)}
          value={toDate}
          placeholder={t("placeholders.to_date")}
        />
      </div>
    </div>
  )
}
