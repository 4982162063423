import React from "react"
import _ from "lodash"
import Select, { Option } from "@/resources/components/inputs/select"
import { useTranslation } from "react-i18next"
import { PaymentStatusEnum } from "@/enums/paymentStatus"

type Props = {
  onChange: (value: PaymentStatusEnum | undefined) => void
}

export const SearchByStatus = (props: Props) => {
  const _onChange = (value: string | null) => {
    props.onChange(
      value === "0" ? undefined : (value as unknown as PaymentStatusEnum),
    )
  }
  const { t } = useTranslation()

  const onChange = _.debounce(_onChange, 500)

  return (
    <Select defaultValue={0} onChange={(_, value) => onChange(value as string)}>
      <Option value={0}>{t("default_options.select_status")}</Option>
      {Object.entries(PaymentStatusEnum).map(([key, value]) => (
        <Option key={key} value={value}>
          {t(`payments.statuses.${value}`)}
        </Option>
      ))}
    </Select>
  )
}
