type Props = {
  title: string
}

const Divider = (props: Props) => {
  return (
    <div className={"py-8"}>
      <div
        className={
          'flex items-center text-neutral-200 before:mr-3 before:h-px before:flex-grow before:bg-gray-800 before:content-[""] after:ml-3 after:h-px after:flex-grow after:bg-gray-800 after:content-[""]'
        }
      >
        {props.title}
      </div>
    </div>
  )
}

export default Divider
