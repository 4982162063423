import { TFunction } from "i18next"
import * as yup from "yup"
import { UpdateIssuePropsInterface } from "@/interfaces/issue"
import { IssueStatusEnum } from "@/enums/issueStatus"

export default class ChangeStatusValidation {
  static rules(
    t: TFunction,
  ): yup.ObjectSchema<UpdateIssuePropsInterface, yup.AnyObject> {
    return yup.object().shape({
      status: yup
        .mixed<IssueStatusEnum>()
        .required(t("validations.field_is_required")),
      supporter_id: yup.number().required().nullable(),
      message: yup.string().nullable(),
    })
  }

  static defaultValues(): UpdateIssuePropsInterface {
    return {
      status: IssueStatusEnum.PENDING,
      supporter_id: 0,
      message: "",
    }
  }
}
