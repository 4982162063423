import { BreadCrumbInterface } from "@/interfaces/base"
import { useTranslation } from "react-i18next"
import { useStoreMessageFilterMutation } from "@/connectors/fansbay/requests/messageFilterRequests"
import { SaveChatMessageFilterPropsInterface } from "@/interfaces/chatMessageFilter"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { MessageFilterForm } from "@/resources/views/messages/filters/parts/form"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { BackendValidationErrorInterface } from "@hypedevgroup/core"

export const MessageFilterCreate = () => {
  const { t } = useTranslation()
  const [storeFilter] = useStoreMessageFilterMutation()
  const navigate = useNavigate()
  const [errors, setErrors] =
    useState<
      BackendValidationErrorInterface<SaveChatMessageFilterPropsInterface>
    >()

  const handleSubmit = (data: SaveChatMessageFilterPropsInterface) => {
    storeFilter(data)
      .unwrap()
      .then(() => {
        toast.success(t("chat_message_filters.create.success"))
        navigate("/messages/filters")
      })
      .catch((errors) => {
        if (errors.status === 422) {
          setErrors(errors.data)
        }
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <MessageFilterForm errors={errors} onSubmit={handleSubmit} />
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "modules.breadcrumbs.content",
    url: "#",
  },
  {
    title: "chat_messages.breadcrumbs.messages",
    url: "/messages",
  },
  {
    title: "chat_message_filters.breadcrumbs.filters",
    url: "/messages/filters",
  },
  {
    title: "chat_message_filters.breadcrumbs.create",
    url: "/messages/filters/create",
  },
]
