import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  GetStatisticsPropsInterface,
  GetStatisticsResponseInterface,
  StatisticInterface,
} from "@/interfaces/statistic"
import qs from "qs"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getStatistic: builder.query<
      StatisticInterface,
      GetStatisticsPropsInterface
    >({
      query: ({ from, to, resources }) => {
        const params = qs.stringify({
          from,
          to,
          resources,
        })

        return `/api/panel/dashboard/statistics?${params}`
      },
      transformResponse: (response: GetStatisticsResponseInterface) =>
        response.data,
      providesTags: (result, error, { resources }) => [
        { type: "Statistics", id: resources.join(",") } as const,
      ],
    }),
  }),
})

export const { useGetStatisticQuery } = requests
