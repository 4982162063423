import React from "react"
import _ from "lodash"
import Select, { Option } from "@/resources/components/inputs/select"
import { useTranslation } from "react-i18next"
import { UserVerificationStatusEnum } from "@/enums/userVerificationStatus"

type Props = {
  onChange: (value: UserVerificationStatusEnum | undefined) => void
  value?: UserVerificationStatusEnum | undefined
}

export const SearchByStatus = (props: Props) => {
  const _onChange = (value: string | null) => {
    props.onChange(
      value === "0"
        ? undefined
        : (value as unknown as UserVerificationStatusEnum),
    )
  }
  const { t } = useTranslation()

  const onChange = _.debounce(_onChange, 500)

  return (
    <Select
      value={props.value}
      defaultValue={0}
      onChange={(_, value) => onChange(value as string)}
    >
      <Option value={0}>{t("default_options.select_status")}</Option>
      {Object.entries(UserVerificationStatusEnum).map(([key, value]) => (
        <Option key={key} value={value}>
          {t(`user_verifications.status.${value}`)}
        </Option>
      ))}
    </Select>
  )
}
