import React, { useMemo } from "react"
import { useLocation, useMatch, useNavigate } from "react-router-dom"
import classNames from "classnames"

type Props = {
  url: string
  icon: React.ReactNode
  id: string
  onClick: (id: string) => void
  redirect?: boolean
}

export const SidebarToggle = (props: Props) => {
  const { url, icon, id, onClick, redirect } = props
  const location = useLocation()
  const match = useMatch(location.pathname)
  const isActive = useMemo(() => match?.pathname === url, [match, url])
  const navigate = useNavigate()

  const handleClick = () => {
    onClick(id)

    if (redirect) {
      navigate(url)
    }
  }

  const classes = classNames(
    "items-center justify-center flex w-12 h-12 stroke-gray-25",
    {
      "text-white bg-gray-800 stroke-neutral-200 flex rounded-lg": isActive,
    },
  )

  return (
    <button onClick={handleClick} className={classes}>
      {icon}
    </button>
  )
}
