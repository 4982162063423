import { FC, useState } from "react"
import { create, InstanceProps } from "react-modal-promise"
import { useTranslation } from "react-i18next"
import {
  useDeleteExchangeRateMutation,
  useGetExchangeRateQuery,
  useUpdateExchangeRateMutation,
} from "@/connectors/fansbay/requests/exchangeRateRequests"
import Modal from "@/resources/components/modals/modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { ExchangeRateForm } from "@/resources/views/exchangeRates/parts/form"
import { BackendValidationErrorInterface } from "@hypedevgroup/core"
import { SaveExchangeRatePropsInterface } from "@/interfaces/exchangeRate"

type Props = {
  id: string
}

const ExchangeRateEdit: FC<InstanceProps<unknown> & Props> = (props) => {
  const { onResolve, onReject, isOpen, id } = props
  const { t } = useTranslation()
  const { data } = useGetExchangeRateQuery(id)
  const [updateExchangeRate] = useUpdateExchangeRateMutation()
  const [deleteExchangeRate] = useDeleteExchangeRateMutation()
  const [errors, setErrors] =
    useState<BackendValidationErrorInterface<SaveExchangeRatePropsInterface>>()

  const handleSubmit = (data: SaveExchangeRatePropsInterface) => {
    updateExchangeRate({
      ...data,
      id,
    })
      .unwrap()
      .then(() => {
        onResolve()
      })
      .catch((error) => {
        if (error.status === 422) {
          setErrors(error.data)
        }
      })
  }

  const handleDelete = () => {
    deleteExchangeRate(id)
      .unwrap()
      .then(() => {
        onResolve()
      })
  }

  return (
    <Modal open={isOpen} onClose={onReject}>
      <Modal.Content size={"w-[600px]"}>
        <div className={"flex flex-col gap-y-4"}>
          <div className={"flex items-center justify-between"}>
            <span className={"text-l font-semibold text-neutral-200"}>
              {t("exchange_rates.edit.title")}
            </span>
            <FontAwesomeIcon
              icon={faTimes}
              onClick={onReject}
              className={"cursor-pointer"}
            />
          </div>
          <ExchangeRateForm
            data={data}
            errors={errors}
            onSubmit={handleSubmit}
            onDeleteClick={handleDelete}
          />
        </div>
      </Modal.Content>
    </Modal>
  )
}

export const exchangeRateEditModal = create(ExchangeRateEdit)
