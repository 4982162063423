import i18n from "i18next"
import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "pl",
    missingInterpolationHandler: (text, value, options) => {},
    backend: {
      allowMultiLoading: false,
      loadPath: `/locales/{{lng}}.json`,
    },
    returnNull: false,
    load: "languageOnly",
    debug: true,
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (format === "uppercase") return value.toUpperCase()
        if (format === "lowercase") return value.toLowerCase()
        if (format === "uppercaseFirst")
          return value.charAt(0).toUpperCase() + value.slice(1)
        return value
      },
    },
  })

export default i18n
