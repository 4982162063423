import "./App.css"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import PrivateRouter from "@/utils/routes/privateRouter/privateRouter"
import AuthRoutes from "@/utils/routes/authRoutes"
import LoginScreen from "@/resources/views/auth/login"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { privateRoutes } from "@/routes/privateRoutes"
import { ForgotPassword } from "@/resources/views/auth/forgotPassword"
import { ResetPassword } from "@/resources/views/auth/resetPassword"

function App() {
  return (
    <BrowserRouter>
      <ToastContainer
        className={"z-[99999]"}
        hideProgressBar={true}
        theme="colored"
      />
      <Routes>
        <Route element={<PrivateRouter routes={privateRoutes} />}>
          {privateRoutes.map((route, index) => (
            <Route
              path={route.path}
              key={index}
              // @ts-ignore
              element={<route.component />}
            />
          ))}
        </Route>
        <Route element={<AuthRoutes />}>
          <Route path={"/login"} element={<LoginScreen />} />
          <Route path={"/forgot-password"} element={<ForgotPassword />} />
          <Route path={"/reset-password"} element={<ResetPassword />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
