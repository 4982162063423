import { Select2OptionInterface } from "@/interfaces/base"
import { useLazySearchUsersQuery } from "@/connectors/fansbay/requests/userRequests"
import _ from "lodash"
import AsyncSelect from "@/resources/components/inputs/asyncSelect"
import { useTranslation } from "react-i18next"
import { useEffect, useMemo, useState } from "react"
import { Query } from "@hypedevgroup/core"
import { PermissionEnum } from "@/enums/permission"

type Props = {
  onChange: (value: string | undefined) => void
  value?: Select2OptionInterface
  disabled?: boolean
  permission?: PermissionEnum
}

export const SearchByUser = (props: Props) => {
  const [searchUsers] = useLazySearchUsersQuery()
  const { t } = useTranslation()
  const query = useMemo(() => new Query(), [])
  const [defaultUsers, setDefaultUsers] = useState<Select2OptionInterface[]>([])

  useEffect(() => {
    if (props.value) {
      setDefaultUsers([props.value])
    }
  }, [props.value])

  const _getUsers = (
    value: string,
    callback: (options: Select2OptionInterface[]) => void,
  ) => {
    searchUsers(
      query.where("search", value).where("permission", props.permission).url(),
    )
      .unwrap()
      .then((response) => {
        const options = response.map((user) => ({
          label: user.username,
          value: user.id.toString(),
        }))

        callback(options)
      })
  }

  const getUsers = _.debounce(_getUsers, 500)

  return (
    <AsyncSelect
      isDisabled={props.disabled}
      onChange={(value) => props.onChange(value?.value)}
      loadOptions={getUsers}
      defaultOptions={defaultUsers}
      value={props.value}
      placeholder={t("placeholders.type_to_search_user")}
      cacheOptions
      noOptionsMessage={() => t("messages.no_users_found")}
      isClearable
    />
  )
}
