import { FormInterface } from "@/interfaces/base"
import { SaveExchangeRatePropsInterface } from "@/interfaces/exchangeRate"
import { useTranslation } from "react-i18next"
import { useAssignAttributes, useValidation } from "@hypedevgroup/core"
import SaveExchangeRateValidation from "@/validations/exchangeRates/saveExchangeRateValidation"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, useForm } from "react-hook-form"
import { FormControl } from "@mui/base"
import { useSearchCurrenciesQuery } from "@/connectors/fansbay/requests/currencyRequests"
import Select, { Option } from "@/resources/components/inputs/select"
import { FormHelperText } from "@/resources/components/inputs/formHelperText"
import { Input } from "@/resources/components/inputs/input"
import { Button } from "@/resources/components/buttons/button"
import { useEffect } from "react"
import { useHasPermission } from "@/utils/hooks/useHasPermission"
import { PermissionEnum } from "@/enums/permission"

export const ExchangeRateForm = ({
  errors: formErrors,
  data,
  onSubmit,
  onDeleteClick,
}: FormInterface<SaveExchangeRatePropsInterface> & {
  onDeleteClick?: () => void
}) => {
  const { t } = useTranslation()
  const { schema, defaultValues, resolveValidationErrors } = useValidation(
    SaveExchangeRateValidation,
    t,
  )
  const { data: currencies = [] } = useSearchCurrenciesQuery()
  const hasPermission = useHasPermission()

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
  } = useForm<SaveExchangeRatePropsInterface>({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  })

  useAssignAttributes(setValue, data)

  useEffect(() => {
    if (formErrors) {
      const errors = resolveValidationErrors(formErrors)

      Object.keys(errors).forEach((key) => {
        const keyName = key as keyof SaveExchangeRatePropsInterface

        setError(keyName, {
          type: "manual",
          message: errors[keyName],
        })
      })
    }
  }, [formErrors, resolveValidationErrors, setError])

  return (
    <div className={"flex w-full flex-col gap-y-4"}>
      <Controller
        name={"from_id"}
        control={control}
        render={({ field }) => (
          <FormControl error={!!errors.from_id}>
            <Select
              label={t("labels.from_currency")}
              onChange={(_, value) => field.onChange(value)}
              value={field.value}
              fullWidth
            >
              <Option value={0}>{t("default_options.select_currency")}</Option>
              {currencies.map((currency) => (
                <Option key={currency.id} value={currency.id}>
                  {currency.name}
                </Option>
              ))}
            </Select>
            <FormHelperText message={errors.from_id?.message} />
          </FormControl>
        )}
      />
      <Controller
        name={"to_id"}
        control={control}
        render={({ field }) => (
          <FormControl error={!!errors.to_id}>
            <Select
              label={t("labels.to_currency")}
              onChange={(_, value) => field.onChange(value)}
              value={field.value}
              fullWidth
            >
              <Option value={0}>{t("default_options.select_currency")}</Option>
              {currencies.map((currency) => (
                <Option key={currency.id} value={currency.id}>
                  {currency.name}
                </Option>
              ))}
            </Select>
            <FormHelperText message={errors.to_id?.message} />
          </FormControl>
        )}
      />
      <Controller
        name={"rate"}
        control={control}
        render={({ field }) => (
          <FormControl {...field} error={!!errors.rate}>
            <Input
              label={t("labels.rate")}
              type={"number"}
              placeholder={t("placeholders.rate")}
            />
            <FormHelperText message={errors.rate?.message} />
          </FormControl>
        )}
      />
      <div className={"mt-4 flex flex-row-reverse gap-x-2"}>
        <Button variant={"contained"} onClick={handleSubmit(onSubmit)}>
          {t("buttons.save")}
        </Button>
        {onDeleteClick &&
          hasPermission(PermissionEnum.EXCHANGE_RATE_DESTROY) && (
            <Button
              variant={"contained"}
              className={"bg-red-600"}
              onClick={onDeleteClick}
            >
              {t("buttons.delete")}
            </Button>
          )}
      </div>
    </div>
  )
}
