import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

type Props = {
  value: ReactQuill.Value | undefined
  onChange: (value: ReactQuill.Value | undefined) => void
}

const Editor = (props: Props) => {
  return <ReactQuill theme="snow" {...props} />
}

export default Editor
