import { ForwardedRef, forwardRef, HTMLProps } from "react"

const Switch = forwardRef(
  (props: HTMLProps<HTMLInputElement>, ref: ForwardedRef<HTMLInputElement>) => {
    return (
      <div>
        <label className="relative inline-flex cursor-pointer items-center">
          <input type="checkbox" className="peer sr-only" {...props} />
          <div className="h-6 w-11 rounded-full bg-gray-700 after:absolute after:left-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary-600 peer-checked:after:translate-x-full peer-checked:after:border-white dark:border-gray-600"></div>
          {props.label && (
            <span className="ml-3 select-none text-sm font-medium text-neutral-200">
              {props.label}
            </span>
          )}
        </label>
      </div>
    )
  },
)

export default Switch
