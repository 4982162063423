import { useNavigate, useParams } from "react-router-dom"
import { useRedirectWithError } from "@/utils/hooks/useRedirectWithError"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { BreadCrumbInterface } from "@/interfaces/base"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import {
  useGetCurrencyQuery,
  useUpdateCurrencyMutation,
} from "@/connectors/fansbay/requests/currencyRequests"
import { SaveCurrencyPropsInterface } from "@/interfaces/currency"
import { CurrencyForm } from "@/resources/views/currencies/parts/form"

export const CurrencyEdit = () => {
  const { id } = useParams<{ id: string }>()
  const { data, error } = useGetCurrencyQuery(id!)
  useRedirectWithError(error, "/error")
  const { t } = useTranslation()
  const [updatePlan] = useUpdateCurrencyMutation()
  const navigate = useNavigate()

  const handleSubmit = (data: SaveCurrencyPropsInterface) => {
    updatePlan({
      ...data,
      id: id!,
    })
      .unwrap()
      .then(() => {
        navigate("/currencies")
        toast.success(t("currencies.edit.success"))
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      <CurrencyForm onSubmit={handleSubmit} data={data} />
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "currencies.breadcrumbs.currencies",
    url: "/currencies",
  },
  {
    title: "currencies.breadcrumbs.edit",
    url: `/currencies/${id}/edit`,
  },
]
