import * as yup from "yup"
import { AnyObject, ObjectSchema } from "yup"
import { TFunction } from "i18next"
import { StoreAgreementPropsInterface } from "@/interfaces/agreement"
import { AgreementGroupNameEnum } from "@/enums"

const storeAgreementValidation = {
  rules: (
    t: TFunction,
  ): ObjectSchema<StoreAgreementPropsInterface, AnyObject> =>
    yup.object().shape({
      name: yup.string().required(t("validations.field_is_required")),
      required: yup.boolean().required(t("validations.field_is_required")),
      visible: yup.boolean().required(t("validations.field_is_required")),
      slug: yup.string().required(t("validations.field_is_required")),
      description: yup.string().nullable(),
      locale: yup.string().required(t("validations.field_is_required")),
      file: yup.mixed<File>().nullable(),
      file_removed: yup.boolean().nullable(),
      groups: yup
        .array()
        .of(
          yup
            .object()
            .shape({
              name: yup
                .mixed<AgreementGroupNameEnum>()
                .required(t("validations.field_is_required")),
            })
            .required(t("validations.field_is_required")),
        )
        .required(t("validations.field_is_required")),
      only_text: yup.boolean().required(),
    }),

  defaultValues: (): StoreAgreementPropsInterface => ({
    name: "",
    required: false,
    visible: true,
    description: "",
    slug: "",
    locale: "pl",
    groups: [],
    only_text: false,
  }),
}

export { storeAgreementValidation }
