import { useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { PermissionEnum } from "@/enums/permission"
import { Button, ButtonLink } from "@/resources/components/buttons/button"
import React, { Fragment } from "react"
import { BreadCrumbInterface } from "@/interfaces/base"
import { useHasPermission } from "@/utils/hooks/useHasPermission"
import { Label } from "@/resources/components/inputs/label"
import Card from "@/resources/components/cards/card"
import { Badge } from "@/resources/components/badge"
import { formatDate } from "@/utils/formatDate"
import { toast } from "react-toastify"
import {
  useDeleteProvisionRateMutation,
  useGetProvisionRateQuery,
} from "@/connectors/fansbay/requests/provisionRateRequests"

export const ProvisionRateDetails = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const { data } = useGetProvisionRateQuery(id!)
  const hasPermission = useHasPermission()
  const navigate = useNavigate()
  const [deleteProvisionRate] = useDeleteProvisionRateMutation()

  const handleDeleteProvisionRate = () => {
    deleteProvisionRate(id!)
      .unwrap()
      .then(() => {
        toast.success(t("provision_rates.details.deleted"))
        navigate("/provision-rates")
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      <div className={"flex justify-end gap-x-4"}>
        {hasPermission(PermissionEnum.PROVISION_RATES_DESTROY) && (
          <Button
            onClick={handleDeleteProvisionRate}
            variant={"contained"}
            className={"bg-red-600"}
          >
            {t("tax_rates.details.delete")}
          </Button>
        )}
        {hasPermission(PermissionEnum.PROVISION_RATES_STORE) && (
          <ButtonLink to={`/provision-rates/${id}/edit`} variant={"contained"}>
            {t("provision_rates.details.edit")}
          </ButtonLink>
        )}
      </div>
      {data && (
        <Fragment>
          <Card>
            <div className={"flex flex-col gap-4"}>
              <span className={"text-xl font-medium text-neutral-200"}>
                {t("provision_rates.details.title")}
              </span>
              <div className={"flex flex-col gap-16 py-4 lg:flex-row"}>
                <div>
                  <Label label={t("labels.country")} />
                  <span className={"font-medium"}>{data.country.name}</span>
                </div>
                <div>
                  <Label label={t("labels.provider_name")} />
                  <span className={"font-medium"}>
                    {data.payment_provider.name}
                  </span>
                </div>
                <div>
                  <Label label={t("labels.provision_type")} />
                  <span className={"font-medium"}>
                    {t(`provision_rates.type.${data.type}`)}
                  </span>
                </div>
                <div>
                  <Label label={t("labels.rate")} />
                  <span className={"font-medium"}>{`${data.rate}%`}</span>
                </div>
                <div>
                  <Label label={t("labels.active")} />
                  <Badge
                    color={data.active ? "bg-green-500" : "bg-gray-800"}
                    textColor={"text-neutral-200"}
                  >
                    {data.active ? t("labels.yes") : t("labels.no")}
                  </Badge>
                </div>
                <div>
                  <Label label={t("labels.created_at")} />
                  <span className={"font-medium"}>
                    {formatDate(data.created_at)}
                  </span>
                </div>
              </div>
            </div>
          </Card>
        </Fragment>
      )}
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "provision_rates.breadcrumbs.provision_rates",
    url: "/provision-rates",
  },
  {
    title: "provision_rates.breadcrumbs.details",
    url: `/provision-rates/${id}`,
  },
]
