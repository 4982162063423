import { TFunction } from "i18next"
import * as yup from "yup"
import { SaveCurrencyPropsInterface } from "@/interfaces/currency"

export default class SaveCurrencyValidation {
  static rules(
    t: TFunction,
  ): yup.ObjectSchema<SaveCurrencyPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      name: yup.string().required(t("validations.field_is_required")),
      code: yup.string().required(t("validations.field_is_required")),
      symbol: yup.string().required(t("validations.field_is_required")),
      multiplier: yup
        .number()
        .typeError(t("validations.field_is_required"))
        .required(t("validations.field_is_required")),
    })
  }

  static defaultValues(): SaveCurrencyPropsInterface {
    return {
      name: "",
      code: "",
      symbol: "",
      multiplier: "",
    }
  }
}
