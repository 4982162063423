import {
  BreadCrumbInterface,
  ValidationErrorResponseInterface,
} from "@/interfaces/base"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { BackendValidationErrorInterface } from "@hypedevgroup/core"
import { useStoreProvisionRateMutation } from "@/connectors/fansbay/requests/provisionRateRequests"
import { SaveProvisionRatePropsInterface } from "@/interfaces/provisionRate"
import { ProvisionRateForm } from "@/resources/views/provisionRates/parts/form"

export const ProvisionRateCreate = () => {
  const { t } = useTranslation()
  const [storeTaxRate] = useStoreProvisionRateMutation()
  const navigate = useNavigate()
  const [errors, setErrors] =
    useState<BackendValidationErrorInterface<SaveProvisionRatePropsInterface>>()

  const handleSubmit = (data: SaveProvisionRatePropsInterface) => {
    storeTaxRate(data)
      .unwrap()
      .then(() => {
        toast.success(t("provision_rates.create.success"))
        navigate("/provision-rates")
      })
      .catch((errors: ValidationErrorResponseInterface) => {
        if (errors.status === 422) {
          setErrors(errors.data)
        }
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <ProvisionRateForm onSubmit={handleSubmit} errors={errors} />
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "provision_rates.breadcrumbs.provision_rates",
    url: "/provision-rates",
  },
  {
    title: "provision_rates.breadcrumbs.create",
    url: "/provision-rates/create",
  },
]
