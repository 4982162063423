import { useTranslation } from "react-i18next"
import { useLazySearchSubscriptionPlansQuery } from "@/connectors/fansbay/requests/subscriptionPlanRequests"
import { useNavigate } from "react-router-dom"
import { useValidation } from "@hypedevgroup/core"
import StoreSubscriptionValidation from "@/validations/subscriptions/storeSubscriptionValidation"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { BreadCrumbInterface } from "@/interfaces/base"
import React, { useState } from "react"
import Card from "@/resources/components/cards/card"
import { Controller, useForm } from "react-hook-form"
import { StoreSubscriptionPropsInterface } from "@/interfaces/subscription"
import { yupResolver } from "@hookform/resolvers/yup"
import { Label } from "@/resources/components/inputs/label"
import { SearchByUser } from "@/resources/components/inputs/search/searchByUser"
import { FormHelperText } from "@/resources/components/inputs/formHelperText"
import { SearchSubscriptionPlan } from "@/interfaces/subscriptionPlan"
import { baseQuery } from "@/utils/query"
import { FormControl } from "@mui/base"
import Select, { Option } from "@/resources/components/inputs/select"
import { planName } from "@/resources/views/plans/parts/planName"
import { Button } from "@/resources/components/buttons/button"
import { useStoreSubscriptionMutation } from "@/connectors/fansbay/requests/subscriptionRequests"
import { toast } from "react-toastify"

export const SubscriptionCreate = () => {
  const { t } = useTranslation()
  const [storeSubscription] = useStoreSubscriptionMutation()
  const navigate = useNavigate()
  const [searchSubscriptionPlans] = useLazySearchSubscriptionPlansQuery()
  const [plans, setPlans] = useState<SearchSubscriptionPlan[]>([])
  const { schema, defaultValues } = useValidation(
    StoreSubscriptionValidation,
    t,
  )
  const onSubmit = (data: StoreSubscriptionPropsInterface) => {
    storeSubscription(data)
      .unwrap()
      .then(() => {
        toast.success(t("subscriptions.create.success"))
        navigate("/subscriptions")
      })
  }

  const onChangeSubscribedPerson = (id: string | undefined) => {
    if (id) {
      searchSubscriptionPlans(baseQuery.where("user_id", id).url())
        .unwrap()
        .then((response) => {
          setPlans(response)
        })
    }
  }

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<StoreSubscriptionPropsInterface>({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  })

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <div className={"mb-8 flex flex-col gap-8"}>
        <Card className={"flex w-full"}>
          <div className={"flex w-full flex-col gap-16 md:flex-row"}>
            <div className={"md:w-[150px]"}>
              <span>{t("forms.basic_data")}</span>
            </div>
            <div className={"w-full md:w-[400px]"}>
              <div className={"flex flex-col gap-y-8"}>
                <Controller
                  render={({ field }) => (
                    <div className={"flex flex-col"}>
                      <Label label={t("labels.subscriber")} />
                      <SearchByUser onChange={field.onChange} />
                      <FormHelperText
                        error={!!errors.user_id}
                        message={errors.user_id?.message}
                      />
                    </div>
                  )}
                  name={"user_id"}
                  control={control}
                />
                <div className={"flex flex-col"}>
                  <Label label={t("labels.subscribed_person")} />
                  <SearchByUser onChange={onChangeSubscribedPerson} />
                </div>
                <Controller
                  render={({ field }) => (
                    <FormControl error={!!errors.subscription_plan_id}>
                      <Select
                        label={t("labels.subscription_plan")}
                        onChange={(_, value) => field.onChange(value)}
                        value={field.value}
                        fullWidth
                      >
                        <Option value={0}>
                          {t("default_options.select_plan")}
                        </Option>
                        {plans.map((plan) => (
                          <Option value={plan.id} key={plan.id}>
                            {planName(t, plan.duration, plan.duration_unit)}
                          </Option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  name={"subscription_plan_id"}
                  control={control}
                />
              </div>
            </div>
          </div>
        </Card>
        <div>
          <Button variant={"contained"} onClick={handleSubmit(onSubmit)}>
            {t("buttons.save")}
          </Button>
        </div>
      </div>
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "subscriptions.breadcrumbs.subscriptions",
    url: "/subscriptions",
  },
  {
    title: "subscriptions.breadcrumbs.create",
    url: "/subscriptions/create",
  },
]
