import { useEffect, useState } from "react"
import { SearchBy } from "@/resources/views/plans/parts/search/searchBy"
import { useTranslation } from "react-i18next"
import { SearchParameters } from "@/resources/views/plans"
import { SearchByUser } from "@/resources/components/inputs/search/searchByUser"

type Props = {
  onUpdateParameters: (parameters: SearchParameters) => void
}

export const Search = (props: Props) => {
  const [userId, setUserId] = useState<number | string | undefined>("")
  const [price, setPrice] = useState<number | string>("")
  const [duration, setDuration] = useState<number | string>("")
  const { t } = useTranslation()

  useEffect(() => {
    props.onUpdateParameters({
      user_id: userId,
      price,
      duration,
    })
  }, [userId, price, duration])

  return (
    <div className={"flex w-full"}>
      <div className={"grid gap-x-4 md:grid-cols-4"}>
        <SearchByUser onChange={(value) => setUserId(value)} />
        <SearchBy
          onChange={(value) => setDuration(value)}
          placeholder={t("placeholders.duration")}
          value={duration}
        />
        <SearchBy
          onChange={(value) => setPrice(value)}
          placeholder={t("placeholders.price")}
          value={price}
        />
      </div>
    </div>
  )
}
