import BreadCrumbs from "@/resources/components/breadCrumbs"
import { useTranslation } from "react-i18next"
import { BreadCrumbInterface } from "@/interfaces/base"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { useStoreCurrencyMutation } from "@/connectors/fansbay/requests/currencyRequests"
import { SaveCurrencyPropsInterface } from "@/interfaces/currency"
import { CurrencyForm } from "@/resources/views/currencies/parts/form"

export const CurrencyCreate = () => {
  const { t } = useTranslation()
  const [storeCurrency] = useStoreCurrencyMutation()
  const navigate = useNavigate()

  const handleSubmit = (data: SaveCurrencyPropsInterface) => {
    storeCurrency(data)
      .unwrap()
      .then(() => {
        toast.success(t("currencies.create.success"))
        navigate("/currencies")
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <CurrencyForm onSubmit={handleSubmit} />
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "currencies.breadcrumbs.currencies",
    url: "/currencies",
  },
  {
    title: "currencies.breadcrumbs.create",
    url: "/currencies/create",
  },
]
