import { useTranslation } from "react-i18next"
import React, { useMemo, useState } from "react"
import { BreadCrumbInterface } from "@/interfaces/base"
import Table from "@/resources/views/userVerifications/parts/table"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import Card from "@/resources/components/cards/card"
import { baseQuery } from "@/utils/query"
import { SearchByUser } from "@/resources/components/inputs/search/searchByUser"
import { SearchByStatus } from "@/resources/views/userVerifications/parts/searchByStatus"
import { UserVerificationStatusEnum } from "@/enums/userVerificationStatus"

export const UserVerificationList = () => {
  const { t } = useTranslation()
  const [user, setUser] = useState<string>()
  const [status, setStatus] = useState<UserVerificationStatusEnum | undefined>()

  const queryBuilder = useMemo(() => {
    return baseQuery.where("user_id", user).where("status", status)
  }, [user, status])

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <Card noPadding>
        <div
          className={
            "border-b border-gray-800 px-6 py-4 text-l font-medium text-neutral-200"
          }
        >
          <span>{t("user_verifications.list.title")}</span>
        </div>
        <div
          className={"flex flex-col gap-y-4 border-b border-gray-800 px-6 py-4"}
        >
          <div className={"grid gap-x-4 lg:w-1/2 lg:grid-cols-2"}>
            <SearchByUser onChange={(user) => setUser(user)} />
            <SearchByStatus
              onChange={(status) => setStatus(status)}
              value={status}
            />
          </div>
        </div>
        <Table query={queryBuilder} />
      </Card>
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "modules.breadcrumbs.users",
    url: "#",
  },
  {
    title: "user_verifications.breadcrumbs.user_verifications",
    url: "/user-verifications",
  },
]
