import { useMemo, useState } from "react"
import { SearchInterface } from "@/resources/views/withdraws/parts/search/search.types"
import { baseQuery } from "@/utils/query"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import Card from "@/resources/components/cards/card"
import { BreadCrumbInterface } from "@/interfaces/base"
import { Search } from "@/resources/views/withdraws/parts/search/search"
import { useTranslation } from "react-i18next"
import Table from "@/resources/views/withdraws/parts/table"

export const WithdrawList = () => {
  const [search, setSearch] = useState<SearchInterface>()
  const { t } = useTranslation()

  const query = useMemo(
    () =>
      baseQuery
        .where("user_id", search?.user_id)
        .where("currency_id", search?.currency_id)
        .where("status", search?.status)
        .where("withdraw_at", search?.withdraw_at),
    [search],
  )

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <Card noPadding>
        <div
          className={
            "border-b border-gray-800 px-6 py-4 text-l font-medium text-neutral-200"
          }
        >
          <span>{t("withdraws.list.title")}</span>
        </div>
        <div
          className={"flex flex-col gap-y-4 border-b border-gray-800 px-6 py-4"}
        >
          <Search onUpdateParams={(parameters) => setSearch(parameters)} />
        </div>
        <Table query={query} />
      </Card>
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "withdraws.breadcrumbs.withdraws",
    url: "/withdraws",
  },
]
