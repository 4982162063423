import { IssueMessageInterface } from "@/interfaces/issueMessage"
import { Fragment, useMemo } from "react"
import { formatDate } from "@/utils/formatDate"
import { Avatar } from "@/resources/components/avatar"
import { lightboxModal } from "@/resources/components/lightbox/modal"
import { mediaType } from "@/utils/mediaType"

type Props = {
  authorId: number
  message: IssueMessageInterface
}

export const IssueMessage = ({ authorId, message }: Props) => {
  const isAuthor = useMemo(
    () => authorId === message.user.id,
    [authorId, message],
  )

  const onImageClick = (imageUrl: string) => {
    lightboxModal({
      image: imageUrl,
    })
  }

  return (
    <div
      className={`flex flex-1 ${isAuthor ? "flex-row" : "flex-row-reverse"}`}
    >
      <div
        className={`flex ${isAuthor ? "flex-row" : "flex-row-reverse"} gap-x-2`}
      >
        <div className={"hidden lg:block"}>
          {message.user.avatar.thumb_url ? (
            <img
              alt={"avatar"}
              src={message.user.avatar.thumb_url}
              className={"rounded-full"}
            />
          ) : (
            <Avatar avatarSize={"small"} />
          )}
        </div>
        <div
          className={
            "max-w-1/2 flex flex-col gap-y-3 rounded-md border border-gray-800 bg-gray-900 px-4 py-2 text-neutral-200 shadow-sm"
          }
        >
          <Fragment>
            <div className={"flex flex-row items-center gap-x-2"}>
              <span className={"font-medium"}>{message.user.username}</span>
              {message.user.role && (
                <span className={"text-xs"}>{message.user.role}</span>
              )}
            </div>
            {message.attachments.length > 0 && (
              <Media onImageClick={onImageClick} message={message} />
            )}
            {message.content && <div>{message.content}</div>}
            <div className={"flex flex-row-reverse"}>
              <div className={"flex items-center gap-2"}>
                <div className={"text-xs"}>
                  {formatDate(message.created_at)}
                </div>
              </div>
            </div>
          </Fragment>
        </div>
      </div>
    </div>
  )
}

type MediaProps = {
  message: IssueMessageInterface
  onImageClick: (image: string) => void
}

const Media = ({ message, onImageClick }: MediaProps) => {
  return (
    <div className={"flex max-w-[200px] flex-row flex-wrap gap-x-2"}>
      {message.attachments.map((attachment, index) =>
        mediaType(attachment.mime_type) ? (
          <img
            key={index}
            onClick={() => onImageClick(attachment.temporary_url)}
            className={"cursor-pointer rounded"}
            src={attachment.thumb_url ?? attachment.temporary_url}
            alt={"attachment"}
          />
        ) : (
          <video
            key={index}
            controls
            src={attachment.temporary_url}
            typeof={attachment.mime_type}
          />
        ),
      )}
    </div>
  )
}
