import React from "react"
import { create, InstanceProps } from "react-modal-promise"
import { LightboxModalPropsInterface } from "@/resources/components/lightbox/lightbox.types"
import { Modal } from "@mui/base"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

const LightboxModal: React.FC<
  InstanceProps<unknown> & LightboxModalPropsInterface
> = (props) => {
  const { onResolve, isOpen, image } = props

  return (
    <Modal
      className={"fixed inset-0 z-[9999] flex items-center justify-center"}
      open={isOpen}
      onClose={onResolve}
      closeAfterTransition
      disableAutoFocus={true}
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          className: "backdrop-blur-sm fixed z-[-1] inset-0",
        },
      }}
    >
      <div className={"flex h-full max-h-full w-full max-w-full flex-col pb-4"}>
        <div className={"flex justify-end px-8 pt-4"}>
          <FontAwesomeIcon
            icon={faTimes}
            className={"cursor-pointer text-white"}
            onClick={onResolve}
            size={"3x"}
          />
        </div>
        <div className={"flex max-h-full justify-center pb-20"}>
          <img className={"max-h-full"} src={image} alt={""} />
        </div>
      </div>
    </Modal>
  )
}

const Backdrop = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string; onClick: () => void }
>((props, ref) => {
  const { open, className, onClick, ...other } = props
  return <div className={className} onClick={onClick} ref={ref} />
})

export const lightboxModal = create(LightboxModal)
