import { useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { PermissionEnum } from "@/enums/permission"
import { Button, ButtonLink } from "@/resources/components/buttons/button"
import React, { Fragment } from "react"
import { BreadCrumbInterface } from "@/interfaces/base"
import { useHasPermission } from "@/utils/hooks/useHasPermission"
import { Label } from "@/resources/components/inputs/label"
import Card from "@/resources/components/cards/card"
import { formatDate } from "@/utils/formatDate"
import { toast } from "react-toastify"
import {
  useDeleteUserGroupMutation,
  useGetUserGroupQuery,
} from "@/connectors/fansbay/requests/userGroupRequests"

export const UserGroupDetails = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const { data } = useGetUserGroupQuery(id!)
  const hasPermission = useHasPermission()
  const navigate = useNavigate()
  const [deleteUserGroup] = useDeleteUserGroupMutation()

  const handleDeleteTaxRate = () => {
    deleteUserGroup(id!)
      .unwrap()
      .then(() => {
        toast.success(t("user_groups.details.deleted"))
        navigate("/user-groups")
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      <div className={"flex justify-end gap-x-4"}>
        {hasPermission(PermissionEnum.USER_GROUPS_DESTROY) && (
          <Button
            onClick={handleDeleteTaxRate}
            variant={"contained"}
            className={"bg-red-600"}
          >
            {t("user_groups.details.delete")}
          </Button>
        )}
        {hasPermission(PermissionEnum.USER_GROUPS_STORE) && (
          <ButtonLink to={`/user-groups/${id}/edit`} variant={"contained"}>
            {t("user_groups.details.edit")}
          </ButtonLink>
        )}
      </div>
      {data && (
        <Fragment>
          <Card>
            <div className={"flex flex-col gap-4"}>
              <span className={"text-xl font-medium text-neutral-200"}>
                {t("user_groups.details.title")}
              </span>
              <div className={"flex flex-col gap-16 py-4 lg:flex-row"}>
                <div>
                  <Label label={t("labels.name")} />
                  <span className={"font-medium"}>{data.name}</span>
                </div>
                <div>
                  <table className={"m-0 table-auto p-0"}>
                    <thead>
                      <tr>
                        <td className={"pb-2 pr-4 font-medium"}>
                          {t("labels.filter_name")}
                        </td>
                        <td className={"pb-2 pr-4 font-medium"}>
                          {t("labels.value")}
                        </td>
                        <td className={"pb-2 pr-4 font-medium"}>
                          {t("labels.type")}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {data.filters.map((filter, index) => (
                        <tr key={index}>
                          <td className={"pb-2 pr-4"}>
                            {t(`user_groups.filters.${filter.name}`)}
                          </td>
                          <td className={"pb-2 pr-4"}>{filter.value}</td>
                          <td className={"pb-2 pr-4"}>
                            {filter.type
                              ? t(`user_groups.types.${filter.type}`)
                              : "-"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div>
                  <Label label={t("labels.created_at")} />
                  <span className={"font-medium"}>
                    {formatDate(data.created_at)}
                  </span>
                </div>
              </div>
            </div>
          </Card>
        </Fragment>
      )}
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "modules.breadcrumbs.setting",
    url: "#",
  },
  {
    title: "user_groups.breadcrumbs.user_groups",
    url: "/user-groups",
  },
  {
    title: "user_groups.breadcrumbs.details",
    url: `/user-groups/${id}`,
  },
]
