import React, { forwardRef, HTMLProps } from "react"
import classNames from "classnames"

type Props = {
  url?: string
  avatarSize?: "small" | "medium" | "large"
} & HTMLProps<HTMLDivElement>

export const Avatar = forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { url, avatarSize, className, ...rest } = props

    const sizeClass = classNames({
      "w-10 h-10": avatarSize === "medium" || !avatarSize,
      "w-8 h-8": avatarSize === "small",
      "w-12 h-12": avatarSize === "large",
    })

    const classes = classNames(
      "overflow-hidden flex items-center justify-center shadow rounded-full bg-primary dark:bg-gray-700",
      sizeClass,
      className,
    )

    const avatarClasses = classNames(
      "rounded-full object-center",
      sizeClass,
      className,
    )

    if (url) {
      return <img src={url} alt={""} className={avatarClasses} />
    }

    return (
      <div className={classes} {...rest} ref={ref}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.6077 19.1937C19.2111 18.4166 18.6877 17.6875 18.0374 17.0373C14.7031 13.7029 9.29705 13.7029 5.96272 17.0373C5.31247 17.6875 4.78903 18.4166 4.3924 19.1937M1 12C1 5.92487 5.92487 0.999999 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 0.999999 18.0751 1 12ZM16 10C16 12.2091 14.2091 14 12 14C9.79086 14 8 12.2091 8 10C8 7.79086 9.79086 6 12 6C14.2091 6 16 7.79086 16 10Z"
            stroke="white"
            strokeWidth="2"
          />
        </svg>
      </div>
    )
  },
)
