import React from "react"
import _ from "lodash"
import Select, { Option } from "@/resources/components/inputs/select"
import { useTranslation } from "react-i18next"
import { WithdrawStatusEnum } from "@/enums/widthdrawStatus"

type Props = {
  onChange: (value: WithdrawStatusEnum | undefined) => void
  value?: WithdrawStatusEnum | undefined
}

export const SearchByStatus = (props: Props) => {
  const _onChange = (value: string | null) => {
    props.onChange(
      value === "0" ? undefined : (value as unknown as WithdrawStatusEnum),
    )
  }
  const { t } = useTranslation()

  const onChange = _.debounce(_onChange, 500)

  return (
    <Select
      value={props.value}
      defaultValue={0}
      onChange={(_, value) => onChange(value as string)}
    >
      <Option value={0}>{t("default_options.select_status")}</Option>
      {Object.entries(WithdrawStatusEnum).map(([key, value]) => (
        <Option key={key} value={value}>
          {t(`withdraws.statuses.${value}`)}
        </Option>
      ))}
    </Select>
  )
}
