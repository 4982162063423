import { TFunction } from "i18next"
import * as yup from "yup"
import { SaveChatMessageFilterPropsInterface } from "@/interfaces/chatMessageFilter"

export default class SaveMessageFilterValidation {
  static rules(
    t: TFunction,
  ): yup.ObjectSchema<SaveChatMessageFilterPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      name: yup.string().required(t("validations.field_is_required")),
      fields: yup
        .array(yup.string().required())
        .required(t("validations.field_is_required"))
        .min(1),
    })
  }

  static defaultValues(): SaveChatMessageFilterPropsInterface {
    return {
      name: "",
      fields: [],
    }
  }
}
