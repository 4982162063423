import { BreadCrumbInterface } from "@/interfaces/base"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

type Props = {
  crumbs: BreadCrumbInterface[]
}

const BreadCrumbs = (props: Props) => {
  const { t } = useTranslation()

  return (
    <div className={"flex items-center gap-x-3"}>
      {props.crumbs.map((crumb, index) => {
        return (
          <Fragment key={index}>
            <Link
              to={crumb.url}
              className={`text-m font-medium text-gray-25 last:text-primary-600`}
            >
              {t(crumb.title)}
            </Link>
            {index !== props.crumbs.length - 1 && (
              <FontAwesomeIcon
                icon={faChevronRight}
                className={"text-gray-25"}
              />
            )}
          </Fragment>
        )
      })}
    </div>
  )
}

export default BreadCrumbs
