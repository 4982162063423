import { Fragment, MouseEvent, useCallback, useRef, useState } from "react"
import { ClickAwayListener, Menu } from "@mui/base"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { Button } from "@/resources/components/buttons/button"
import { Table } from "@tanstack/react-table"
import { useTranslation } from "react-i18next"

type Props<T> = {
  table?: Table<T>
}

export const ColumnSetting = <T extends object>(props: Props<T>) => {
  const [open, setIsOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null)
  const preventReopen = useRef<boolean>(false)
  const { t } = useTranslation()

  const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (preventReopen.current) {
      event.preventDefault()
      preventReopen.current = false
      return
    }

    setIsOpen((prev) => !prev)
  }

  const handleButtonMouseDown = () => {
    if (open) {
      preventReopen.current = true
    }
  }

  const updateAnchor = useCallback((node: HTMLDivElement | null) => {
    setAnchorEl(node)
  }, [])

  return (
    <Fragment>
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <div>
          <Button
            variant={"text"}
            aria-controls={open ? "menu-list" : undefined}
            aria-expanded={open || undefined}
            aria-haspopup="menu"
            ref={updateAnchor}
            onMouseDown={handleButtonMouseDown}
            className={
              "flex cursor-pointer flex-row items-center gap-x-2 text-button text-primary-600"
            }
            onClick={handleButtonClick}
          >
            <span className={"font-semibold"}>
              {t("table.columns_visibility")}
            </span>
            <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
          </Button>
          <Menu
            slotProps={{
              root: {
                className: "z-50",
              },
              listbox: {
                id: "menu-list",
                className:
                  "bg-gray-900 text-neutral-200 min-w-[200px] border border-gray-800 shadow p-4 my-2 shadow",
              },
            }}
            onOpenChange={(open) => setIsOpen(open)}
            anchorEl={anchorEl}
            open={open}
          >
            {props.table &&
              props.table.getAllLeafColumns().map(
                (column, index) =>
                  column.getCanHide() && (
                    <li key={index} className={"flex flex-row items-center"}>
                      <input
                        type={"checkbox"}
                        onChange={column.getToggleVisibilityHandler()}
                        checked={column.getIsVisible()}
                      />
                      <span className={"ml-4"}>
                        {column.columnDef.header?.toString()}
                      </span>
                    </li>
                  ),
              )}
          </Menu>
        </div>
      </ClickAwayListener>
    </Fragment>
  )
}
