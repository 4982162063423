import { WalletInterface } from "@/interfaces/user"

export const toIntl = (amount: number, currency: string, locale: string) =>
  Intl.NumberFormat(locale, { currency: currency, style: "currency" }).format(
    amount,
  )

export const toIntWithWallet = (wallet: WalletInterface) => {
  return toIntl(
    wallet.balance / 10 ** wallet.decimal_places,
    wallet.currency,
    "pl-PL",
  )
}
