import { useTranslation } from "react-i18next"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import Card from "@/resources/components/cards/card"
import { BreadCrumbInterface } from "@/interfaces/base"
import Table from "@/resources/views/settings/parts/table"

export const SettingList = () => {
  const { t } = useTranslation()

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <Card noPadding>
        <div
          className={
            "border-b border-gray-800 px-6 py-4 text-l font-medium text-neutral-200"
          }
        >
          <span>{t("settings.list.title")}</span>
        </div>
        <Table />
      </Card>
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "modules.breadcrumbs.content",
    url: "#",
  },
  {
    title: "settings.breadcrumbs.settings",
    url: "/settings",
  },
]
