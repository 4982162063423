import React from "react"
import classNames from "classnames"

type Props = {
  children: React.ReactNode
  className?: string
  noPadding?: boolean
}

const Card = (props: Props) => {
  const classes = classNames(
    props.className,
    "py-4.3 px-6 border border-gray-800 shadow-sm rounded-md bg-gray-900 text-neutral-200 mb-8",
    {
      "!p-0": props.noPadding,
    },
  )

  return <div className={classes}>{props.children}</div>
}

export default Card
