import ReactPaginate from "react-paginate"
import { useTranslation } from "react-i18next"

type Props = {
  onPageChange: (page: number) => void
  totalPages: number
}

export const Pagination = ({ onPageChange, totalPages }: Props) => {
  const { t } = useTranslation()

  return (
    <ReactPaginate
      previousClassName={"flex flex-1"}
      previousLabel={
        <div className={"flex flex-row items-center gap-x-3"}>
          <svg
            width="12"
            height="10"
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.6667 5H2M5.33333 1L1.80474 4.5286C1.54439 4.78894 1.54439 5.21106 1.80474 5.4714L5.33333 9"
              stroke="rgba(151, 154, 160, 1)"
              strokeWidth="1.67"
              strokeLinecap="round"
            />
          </svg>
          {t("table.previous")}
        </div>
      }
      nextClassName={"flex flex-row-reverse flex-1"}
      nextLabel={
        <div className={"flex flex-row items-center gap-x-3"}>
          {t("table.next")}
          <svg
            width="12"
            height="10"
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.33337 5H10M6.66671 1L10.1953 4.5286C10.4557 4.78894 10.4557 5.21106 10.1953 5.4714L6.66671 9"
              stroke="rgba(151, 154, 160, 1)"
              strokeWidth="1.67"
              strokeLinecap="round"
            />
          </svg>
        </div>
      }
      disabledClassName={"select-none"}
      disabledLinkClassName={"select-none"}
      activeLinkClassName={"text-primary-600"}
      containerClassName={"flex flex gap-x-4 text-gray-25 text-s"}
      onPageChange={({ selected }) => onPageChange(selected + 1)}
      pageCount={totalPages}
    />
  )
}
