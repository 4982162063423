import { TFunction } from "i18next"
import * as yup from "yup"
import { UpdateAwardedUserPropsInterface } from "@/interfaces/awardedUser"

export default class UpdateAwardedUserValidation {
  static rules(
    t: TFunction,
  ): yup.ObjectSchema<UpdateAwardedUserPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      order: yup
        .number()
        .required(t("validations.field_is_required"))
        .min(1, t("validations.min_value", { value: 1 })),
    })
  }

  static defaultValues(): UpdateAwardedUserPropsInterface {
    return {
      order: "",
    }
  }
}
