import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { BreadCrumbInterface } from "@/interfaces/base"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import {
  PageContentInterface,
  SavePageContentPropsInterface,
} from "@/interfaces/page"
import { PageForm } from "@/resources/views/pages/parts/form"
import {
  useDeletePageContentMutation,
  useGetPageQuery,
  useStorePageContentMutation,
} from "@/connectors/fansbay/requests/pageRequests"
import { useEffect, useState } from "react"
import { isArray } from "lodash"
import { PageEnum } from "@/enums/page"
import { toast } from "react-toastify"
import i18next from "i18next"

export const PageEdit = () => {
  const { t } = useTranslation()
  const { id: pageId } = useParams<{ id: string }>()
  const { data } = useGetPageQuery(pageId!)
  const [saveData] = useStorePageContentMutation()
  const [deleteContent] = useDeletePageContentMutation()
  const [pageContent, setPageContent] = useState<PageContentInterface[]>([])

  const handleSubmit = (data: SavePageContentPropsInterface) => {
    saveData({
      id: pageId!,
      pages: data.data.map((item) => ({
        ...item,
        locale: i18next.resolvedLanguage!,
      })),
    })
      .unwrap()
      .then(() => {
        toast.success(t("pages.edit.saved"))
      })
  }

  const onDelete = (id: number) => {
    deleteContent({
      page_id: pageId!,
      id: id.toString(),
    })
      .unwrap()
      .then(() => {
        toast.success(t("pages.edit.deleted"))
      })
  }

  useEffect(() => {
    if (data) {
      const contents = data.contents

      if (isArray(contents)) {
        setPageContent(contents)
      } else {
        setPageContent([contents])
      }
    }
  }, [data])

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(pageId!)} />
      {data && (
        <div>
          <span className={"text-2xl font-bold text-white"}>
            {t(`pages.name.${data?.name}`)}
          </span>
        </div>
      )}
      <PageForm
        allowDelete={data?.name === PageEnum.FAQ}
        onDelete={onDelete}
        onSubmit={handleSubmit}
        data={{
          data: pageContent,
        }}
      />
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "modules.breadcrumbs.content",
    url: "#",
  },
  {
    title: "pages.breadcrumbs.pages",
    url: "/pages",
  },
  {
    title: "pages.breadcrumbs.edit",
    url: `/pages/${id}/edit`,
  },
]
