import React from "react"
import { create, InstanceProps } from "react-modal-promise"
import { useTranslation } from "react-i18next"
import Modal from "@/resources/components/modals/modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { Input } from "@/resources/components/inputs/input"
import { Button } from "@/resources/components/buttons/button"

const WalletDescriptionModal: React.FC<InstanceProps<unknown>> = (props) => {
  const { t } = useTranslation()
  const { isOpen, onResolve, onReject } = props
  const [description, setDescription] = React.useState<string>("")

  const handleSubmit = () => {
    onResolve({
      description: description,
    })
  }

  return (
    <Modal open={isOpen} onClose={onReject}>
      <Modal.Content>
        <div className={"flex flex-col gap-y-4"}>
          <div className={"flex w-full flex-row items-center justify-between"}>
            <span className={"text-lg font-semibold text-white"}>
              {t("users.edit_wallet.title")}
            </span>
            <FontAwesomeIcon
              onClick={onReject}
              icon={faTimes}
              className={"cursor-pointer text-white"}
            />
          </div>
          <div>
            <Input
              multiline
              rows={4}
              onChange={(e) => setDescription(e.currentTarget.value)}
              value={description}
              label={t("labels.description")}
            />
          </div>
          <div className={"flex w-full pt-4"}>
            <Button
              variant={"contained"}
              className={"w-full bg-green-900"}
              onClick={handleSubmit}
            >
              {t("buttons.save")}
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export const walletDescriptionModal = create(WalletDescriptionModal)
