import { useTranslation } from "react-i18next"
import { BreadCrumbInterface } from "@/interfaces/base"
import Table from "@/resources/views/messages/parts/table"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import Card from "@/resources/components/cards/card"
import { useMemo, useState } from "react"
import { baseQuery } from "@/utils/query"
import { SearchByFilter } from "@/resources/views/messages/parts/searchByFilter"

export const ChatMessageList = () => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState<string>()

  const query = useMemo(() => {
    return filter
      ? baseQuery.params({
          filter_id: filter,
        })
      : baseQuery.removeParam("filter_id")
  }, [filter])

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <Card noPadding>
        <div
          className={
            "border-b border-gray-800 px-6 py-4 text-l font-medium text-neutral-200"
          }
        >
          <span>{t("chat_messages.list.title")}</span>
        </div>
        <div
          className={"flex flex-col gap-y-4 border-b border-gray-800 px-6 py-4"}
        >
          <div className={"grid gap-x-4 lg:w-1/2 lg:grid-cols-2"}>
            <SearchByFilter onChange={(value) => setFilter(value)} />
          </div>
        </div>
        <Table query={query} />
      </Card>
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "modules.breadcrumbs.content",
    url: "#",
  },
  {
    title: "chat_messages.breadcrumbs.messages",
    url: "/messages",
  },
]
