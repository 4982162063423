import { ElementType } from "react"
import { Link } from "react-router-dom"

type Props = {
  url?: string
  as?: ElementType<any>
  onClick?: () => void
}

const RowAction = ({ url, as: Component = Link, onClick }: Props) => {
  return (
    <Component className={"cursor-pointer"} to={url} onClick={onClick}>
      <svg
        width="7"
        height="12"
        viewBox="0 0 7 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.33331 1L5.74406 5.41074C6.06949 5.73618 6.06949 6.26382 5.74406 6.58926L1.33331 11"
          stroke="#979AA0"
          strokeWidth="1.67"
          strokeLinecap="round"
        />
      </svg>
    </Component>
  )
}

export default RowAction
