import { useParams } from "react-router-dom"
import {
  useGetSettingQuery,
  useUpdateSettingMutation,
} from "@/connectors/fansbay/requests/settingRequests"
import UpdateSettingValidation from "@/validations/settings/updateSettingValidation"
import { useAssignAttributes, useValidation } from "@hypedevgroup/core"
import { useTranslation } from "react-i18next"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import React from "react"
import { BreadCrumbInterface } from "@/interfaces/base"
import Card from "@/resources/components/cards/card"
import {
  SettingDataType,
  UpdateSettingPropsInterface,
} from "@/interfaces/setting"
import { FormControl } from "@mui/base"
import Select, { Option } from "@/resources/components/inputs/select"
import { Checkbox } from "@/resources/components/inputs/checkbox"
import { Input } from "@/resources/components/inputs/input"
import { Button } from "@/resources/components/buttons/button"
import { toast } from "react-toastify"

export const SettingEdit = () => {
  const { id } = useParams<{ id: string }>()
  const { data } = useGetSettingQuery(id!)
  const { t } = useTranslation()
  const { schema, defaultValues } = useValidation(UpdateSettingValidation, t)
  const [updateSetting] = useUpdateSettingMutation()

  const {
    setValue,
    watch,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<UpdateSettingPropsInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const watchDataType = watch("data_type")

  useAssignAttributes(setValue, data)

  const onSubmit = (data: UpdateSettingPropsInterface) => {
    updateSetting({
      id: id!,
      ...data,
    })
      .unwrap()
      .then(() => toast.success(t("settings.edit.success")))
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      <div className={"mb-8 flex flex-col gap-8"}>
        <Card className={"flex w-full"}>
          <div className={"flex w-full flex-col gap-16 md:flex-row"}>
            <div className={"lg:w-[150px]"}>
              <span>{t("forms.basic_data")}</span>
            </div>
            <div className={"w-full lg:w-[500px]"}>
              <div className={"flex flex-col gap-y-8"}>
                <Controller
                  render={({ field }) => (
                    <FormControl error={!!errors.key}>
                      <Select
                        disabled
                        value={field.value}
                        label={t("labels.key")}
                      >
                        <Option value={field.value}>
                          {t(`settings.key.${field.value}`)}
                        </Option>
                      </Select>
                    </FormControl>
                  )}
                  name={"key"}
                  control={control}
                />
                <Controller
                  render={({ field }) => (
                    <FormControl error={!!errors.data_type}>
                      <Select
                        disabled
                        value={field.value}
                        label={t("labels.type")}
                      >
                        <Option value={field.value}>
                          {t(`settings.data_type.${field.value}`)}
                        </Option>
                      </Select>
                    </FormControl>
                  )}
                  name={"data_type"}
                  control={control}
                />
                <Controller
                  render={({ field }) => (
                    <ResolvedFormField
                      dataType={watchDataType}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                  name={"value"}
                  control={control}
                />
              </div>
            </div>
          </div>
        </Card>
        <div>
          <Button variant={"contained"} onClick={handleSubmit(onSubmit)}>
            {t("buttons.save")}
          </Button>
        </div>
      </div>
    </div>
  )
}

type Props = {
  dataType: SettingDataType
  value: any
  onChange: (value: any) => void
}

const ResolvedFormField = (props: Props) => {
  const { t } = useTranslation()

  switch (props.dataType) {
    case "bool":
      return (
        <Checkbox
          checked={props.value}
          onChange={props.onChange}
          label={t("labels.value")}
        />
      )
    case "array":
    case "range":
      return (
        <div className={"flex flex-col gap-y-4"}>
          {Object.keys(props.value).map((key, index) => (
            <div key={index}>
              <Input
                type={"number"}
                label={t(`labels.${key}`)}
                value={props.value[key]}
                onChange={(value) =>
                  props.onChange({
                    ...props.value,
                    [key]: value.currentTarget.value,
                  })
                }
              />
            </div>
          ))}
        </div>
      )
    default:
      return (
        <Input
          type={props.dataType === "number" ? "number" : "text"}
          label={t("labels.value")}
          value={props.value}
          onChange={props.onChange}
        />
      )
  }
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "modules.breadcrumbs.content",
    url: "#",
  },
  {
    title: "settings.breadcrumbs.settings",
    url: "/settings",
  },
  {
    title: "settings.breadcrumbs.edit",
    url: `/settings/${id}/edit`,
  },
]
