import { Button as MUIButton, ButtonOwnerState, ButtonProps } from "@mui/base"
import React from "react"
import classNames from "classnames"
import { Link, LinkProps } from "react-router-dom"

type Props = {
  variant: "contained" | "outlined" | "text"
  size?: "medium" | "large"
  scale?: boolean
  fullWidth?: boolean
  color?: string
  className?: string
}

const classes = ({
  variant,
  className,
  fullWidth,
  scale,
  size,
  color,
}: Props) =>
  classNames(
    "py-[10px] px-[16px] rounded-lg box-shadow cursor-pointer",
    {
      "w-full": fullWidth,
      "bg-primary-600 text-white": variant === "contained" && !color,
      "!p-0": variant === "text",
      "border border-neutral-700 shadow-sm text-m text-neutral-950 !py-[7px]":
        variant === "outlined",
      "py-[12px] px-[18px]": size === "large",
      "hover:scale-105 transition-all duration-300": scale,
    },
    color,
    className,
  )

export const Button = React.forwardRef(
  (props: ButtonProps & Props, ref: React.ForwardedRef<any>) => {
    const {
      variant,
      children,
      className,
      fullWidth,
      scale,
      size,
      color,
      ...rest
    } = props

    const classNames = classes({
      variant,
      className,
      fullWidth,
      scale,
      size,
      color,
    })

    return (
      <MUIButton
        ref={ref}
        slotProps={{
          root: (state: ButtonOwnerState) => ({
            className: classNames,
          }),
        }}
        {...rest}
      >
        {children}
      </MUIButton>
    )
  },
)

export const ButtonLink = React.forwardRef(
  (props: LinkProps & Props, ref: React.ForwardedRef<HTMLAnchorElement>) => {
    const { variant, className, fullWidth, scale, size, color, ...rest } = props

    const classNames = classes({
      variant,
      className,
      fullWidth,
      scale,
      size,
      color,
    })

    return <Link className={classNames} ref={ref} {...rest} />
  },
)
