import { useTranslation } from "react-i18next"
import React, { Fragment, useEffect, useMemo, useState } from "react"
import { Query, usePagination } from "@hypedevgroup/core"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { ColumnSetting } from "@/resources/components/table/columnSetting"
import BaseTable from "@/resources/components/table/table"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons"
import { AgreementInterface } from "@/interfaces/agreement"
import { TFunction } from "i18next"
import {
  useDeleteAgreementMutation,
  useGetAgreementsQuery,
} from "@/connectors/fansbay"
import { AgreementEdit } from "@/resources/views/agreements/Edit"
import { Container as ModalContainer } from "react-modal-promise"
import { toast } from "react-toastify"

const columnHelper = createColumnHelper<AgreementInterface>()

const columns = (
  t: TFunction,
  onDelete: (id: number) => void,
  onEdit: (id: number) => void,
) => [
  columnHelper.accessor("name", {
    id: "name",
    header: t("labels.name"),
  }),
  columnHelper.accessor("required", {
    id: "required",
    header: t("labels.required"),
    cell: ({ row }) =>
      row.original.required ? t("labels.yes") : t("labels.no"),
  }),
  columnHelper.accessor("visible", {
    id: "visible",
    header: t("labels.visible"),
    cell: ({ row }) =>
      row.original.visible ? t("labels.yes") : t("labels.no"),
  }),
  columnHelper.accessor("groups", {
    id: "groups",
    header: t("labels.groups"),
    cell: ({ row }) =>
      row.original.groups
        .map((group) => t(`agreements.groups.${group.name}`))
        .join(", "),
  }),
  columnHelper.display({
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => (
      <div className={"flex flex-row items-center gap-x-4"}>
        <FontAwesomeIcon
          icon={faTimes}
          size={"lg"}
          className={"cursor-pointer text-red-600"}
          onClick={() => onDelete(row.original.id)}
        />
        <FontAwesomeIcon
          icon={faEdit}
          size={"lg"}
          className={"cursor-pointer text-blue-600"}
          onClick={() => onEdit(row.original.id)}
        />
      </div>
    ),
  }),
]

const Table = () => {
  const { t } = useTranslation()
  const [data, setData] = useState<AgreementInterface[]>([])
  const [pagination, setPagination] = usePagination()
  const query = useMemo(
    () =>
      new Query()
        .limit(pagination.pageSize)
        .page(pagination.pageIndex)
        .includes("agreementGroups"),
    [pagination.pageIndex, pagination.pageSize],
  )
  const { data: apiData } = useGetAgreementsQuery(query.url())
  const [deleteAgreement] = useDeleteAgreementMutation()

  useEffect(() => {
    if (apiData) {
      setData(apiData.data)
    }
  }, [apiData])

  const handleDelete = async (id: number) => {
    try {
      await deleteAgreement(id).unwrap()

      toast.success(t("agreements.list.deleted"))
    } catch (error) {
      toast.error(t("alerts.500"))
    }
  }

  const handleEdit = async (id: number) => {
    try {
      await AgreementEdit({
        id: id,
      })
    } catch (error) {}
  }

  const table = useReactTable({
    columns: columns(t, handleDelete, handleEdit),
    data: data,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
    },
    manualPagination: true,
    onPaginationChange: setPagination,
  })

  return (
    <Fragment>
      <div className={"flex flex-col"}>
        <div className={"flex flex-row-reverse px-8 py-4"}>
          <ColumnSetting table={table} />
        </div>
        <BaseTable pagination={apiData?.pagination} table={table} />
      </div>
      <ModalContainer />
    </Fragment>
  )
}

export { Table }
