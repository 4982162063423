import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useGetWithdrawQuery } from "@/connectors/fansbay/requests/withdrawRequests"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import React from "react"
import { BreadCrumbInterface } from "@/interfaces/base"
import Card from "@/resources/components/cards/card"
import { Label } from "@/resources/components/inputs/label"
import { toIntl } from "@/utils/toIntl"
import { formatDate } from "@/utils/formatDate"
import { PermissionEnum } from "@/enums/permission"
import { Button } from "@/resources/components/buttons/button"
import { useHasPermission } from "@/utils/hooks/useHasPermission"
import { withdrawEditModal } from "@/resources/views/withdraws/edit"
import { toast } from "react-toastify"
import { Container as ModalContainer } from "react-modal-promise"
import { getAvailableStatuses } from "@/enums/widthdrawStatus"

export const WithdrawDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { data } = useGetWithdrawQuery(id!)
  const hasPermission = useHasPermission()

  const handleEdit = () => {
    if (data && getAvailableStatuses(data.status)) {
      withdrawEditModal({
        id: id!,
        status: data.status,
      }).then(() => {
        toast.success(t("withdraws.edit.success"))
      })
    }
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      <div className={"flex flex-row-reverse gap-x-4"}>
        {hasPermission(PermissionEnum.WITHDRAWS_STORE) && (
          <Button variant={"contained"} onClick={handleEdit}>
            {t("withdraws.details.edit")}
          </Button>
        )}
      </div>
      {data && (
        <Card>
          <div className={"flex flex-col gap-y-4"}>
            <span className={"text-xl font-medium text-neutral-200"}>
              {t("withdraws.details.title")}
            </span>
            <div className={"flex flex-col flex-wrap gap-16 py-4 lg:flex-row"}>
              <div>
                <Label label={t("labels.user")} />
                <div className={"flex flex-col"}>
                  <span className={"text-s font-medium text-neutral-200"}>
                    {data.user.username}
                  </span>
                  <span className={"text-s font-medium text-gray-25"}>
                    {data.user.email}
                  </span>
                </div>
              </div>
              <div>
                <Label label={t("labels.amount")} />
                <span>{toIntl(data.amount, data.currency.code, language)}</span>
              </div>
              <div>
                <Label label={t("labels.fee")} />
                <span>{toIntl(data.fee, data.currency.code, language)}</span>
              </div>
              <div>
                <Label label={t("labels.withdraw_at")} />
                <span>{data.withdraw_at && formatDate(data.withdraw_at)}</span>
              </div>
              <div>
                <Label label={t("labels.status")} />
                <span>{t(`withdraws.statuses.${data.status}`)}</span>
              </div>
              <div>
                <Label label={t("labels.reason")} />
                <span>{data.status_message}</span>
              </div>
            </div>
          </div>
        </Card>
      )}
      <ModalContainer />
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "withdraws.breadcrumbs.withdraws",
    url: "/withdraws",
  },
  {
    title: "withdraws.breadcrumbs.details",
    url: `/withdraws/${id}`,
  },
]
