import { useNavigate, useParams } from "react-router-dom"
import {
  useDeleteRoleMutation,
  useGetRoleQuery,
} from "@/connectors/fansbay/requests/roleRequests"
import { useRedirectWithError } from "@/utils/hooks/useRedirectWithError"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { BreadCrumbInterface } from "@/interfaces/base"
import Card from "@/resources/components/cards/card"
import { useTranslation } from "react-i18next"
import { Button, ButtonLink } from "@/resources/components/buttons/button"
import { toast } from "react-toastify"
import { useHasPermission } from "@/utils/hooks/useHasPermission"
import { PermissionEnum } from "@/enums/permission"

export const RoleDetails = () => {
  const { id } = useParams<{ id: string }>()
  const { data, error } = useGetRoleQuery(id as string)
  const [deleteRole] = useDeleteRoleMutation()
  useRedirectWithError(error, "/error")
  const { t } = useTranslation()
  const navigate = useNavigate()
  const hasPermission = useHasPermission()

  const handleDelete = () => {
    deleteRole(id as string)
      .unwrap()
      .then(() => {
        toast.success(t("roles.show.deleted"))
        navigate("/roles")
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      <div className={"flex flex-row-reverse gap-x-4"}>
        <ButtonLink variant={"contained"} to={`/roles/${id}/edit`}>
          {t("roles.show.edit")}
        </ButtonLink>
        {hasPermission(PermissionEnum.ROLES_DESTROY) && (
          <Button
            variant={"contained"}
            className={"bg-red-600"}
            onClick={handleDelete}
          >
            {t("roles.show.delete")}
          </Button>
        )}
      </div>
      <Card>
        <div className={"flex flex-col gap-y-4"}>
          <div className={"flex flex-col gap-2"}>
            <span className={"font-semibold"}>{t("labels.role_name")}</span>
            <span>{data?.name}</span>
          </div>
          <div className={"flex flex-col gap-4"}>
            <span className={"font-semibold"}>{t("labels.permissions")}</span>
            <div className={"flex flex-col gap-2"}>
              {data &&
                data.permissions.map((permission, index) => (
                  <span key={index}>{permission.name_translated}</span>
                ))}
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "roles.breadcrumbs.module",
    url: "#",
  },
  {
    title: "roles.breadcrumbs.roles",
    url: "/roles",
  },
  {
    title: "roles.breadcrumbs.details",
    url: `/roles/${id}`,
  },
]
