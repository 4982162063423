import { TFunction } from "i18next"
import * as yup from "yup"
import { AnyObject, ObjectSchema } from "yup"
import { UpdateAgreementPropsInterface } from "@/interfaces/agreement"
import { AgreementGroupNameEnum } from "@/enums"

const updateAgreementValidation = {
  rules: (
    t: TFunction,
  ): ObjectSchema<UpdateAgreementPropsInterface, AnyObject> =>
    yup.object().shape({
      visible: yup.boolean().required(t("validations.field_is_required")),
      groups: yup
        .array()
        .of(
          yup
            .object()
            .shape({
              name: yup
                .mixed<AgreementGroupNameEnum>()
                .required(t("validations.field_is_required")),
            })
            .required(t("validations.field_is_required")),
        )
        .required(t("validations.field_is_required")),
    }),

  defaultValues: (): UpdateAgreementPropsInterface => ({
    visible: false,
    groups: [],
  }),
}

export { updateAgreementValidation }
