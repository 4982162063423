import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import {
  useDeletePostMutation,
  useGetPostQuery,
} from "@/connectors/fansbay/requests/postRequests"
import React, { Fragment } from "react"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { BreadCrumbInterface } from "@/interfaces/base"
import { Label } from "@/resources/components/inputs/label"
import Card from "@/resources/components/cards/card"
import { Badge } from "@/resources/components/badge"
import { toIntl } from "@/utils/toIntl"
import { formatDate } from "@/utils/formatDate"
import { Lightbox } from "@/resources/components/lightbox/lightbox"
import { PostCommentList } from "@/resources/views/posts/comments"
import { useHasPermission } from "@/utils/hooks/useHasPermission"
import { PermissionEnum } from "@/enums/permission"
import { Button } from "@/resources/components/buttons/button"
import { toast } from "react-toastify"

export const PostDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { data: postData } = useGetPostQuery(id!)
  const [deletePost] = useDeletePostMutation()
  const hasPermission = useHasPermission()
  const navigate = useNavigate()

  const handleDeletePost = () => {
    deletePost(id!)
      .unwrap()
      .then(() => {
        toast.success(t("posts.details.deleted"))
        navigate("/posts")
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      <div className={"flex justify-end gap-x-4"}>
        {hasPermission(PermissionEnum.POSTS_DESTROY) && (
          <Button
            onClick={handleDeletePost}
            variant={"contained"}
            className={"bg-red-600"}
          >
            {t("posts.details.delete")}
          </Button>
        )}
      </div>
      {postData && (
        <Fragment>
          <Card>
            <div className={"flex flex-col gap-4"}>
              <span className={"text-xl font-medium text-neutral-200"}>
                {t("posts.details.title")}
              </span>
              <div className={"flex flex-col gap-16 py-4 lg:flex-row"}>
                <div>
                  <Label label={t("labels.user")} />
                  <div className={"flex flex-col"}>
                    <span className={"text-s font-medium text-neutral-200"}>
                      {postData.user.username}
                    </span>
                    <span className={"text-s font-medium text-gray-25"}>
                      {postData.user.email}
                    </span>
                  </div>
                </div>
                <div>
                  <Label label={t("labels.is_processed")} />
                  <Badge
                    color={
                      postData.is_processed ? "bg-green-500" : "bg-gray-800"
                    }
                    textColor={"text-neutral-200"}
                  >
                    {postData.is_processed ? t("labels.yes") : t("labels.no")}
                  </Badge>
                </div>
                <div>
                  <Label label={t("labels.is_free")} />
                  <Badge
                    color={postData.is_free ? "bg-green-500" : "bg-gray-800"}
                    textColor={"text-neutral-200"}
                  >
                    {postData.is_free ? t("labels.yes") : t("labels.no")}
                  </Badge>
                </div>
                <div>
                  <Label label={t("labels.free_for_subscribers")} />
                  <Badge
                    color={
                      postData.free_for_subscribers
                        ? "bg-green-500"
                        : "bg-gray-800"
                    }
                    textColor={"text-neutral-200"}
                  >
                    {postData.free_for_subscribers
                      ? t("labels.yes")
                      : t("labels.no")}
                  </Badge>
                </div>
                <div>
                  <Label label={t("labels.price")} />
                  <span>
                    {toIntl(postData.price, postData.currency_code, language)}
                  </span>
                </div>
                <div>
                  <Label label={t("labels.published_at")} />
                  <span>{formatDate(postData.published_at)}</span>
                </div>
                <div>
                  <Label label={t("labels.created_at")} />
                  <span>{formatDate(postData.created_at)}</span>
                </div>
              </div>
              <div>
                <Label label={t("labels.content")} />
                <span>{postData.content}</span>
              </div>
            </div>
          </Card>
          <Lightbox
            images={postData.attachments}
            type={(image) => image.type}
            renderThumbnail={(image) => image.urls.thumb}
            renderImage={(image) => image.urls.default}
          />
          {hasPermission(PermissionEnum.POST_COMMENTS_SHOW) && (
            <PostCommentList postId={id!} />
          )}
        </Fragment>
      )}
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "modules.breadcrumbs.content",
    url: "#",
  },
  {
    title: "posts.breadcrumbs.posts",
    url: "/posts",
  },
  {
    title: "posts.breadcrumbs.details",
    url: `/posts/${id}`,
  },
]
