import { useTranslation } from "react-i18next"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import Card from "@/resources/components/cards/card"
import { BreadCrumbInterface } from "@/interfaces/base"
import Table from "@/resources/views/payments/parts/table"
import { baseQuery } from "@/utils/query"
import { Search } from "@/resources/views/payments/parts/search/search"
import React, { useMemo, useState } from "react"
import { PaymentTypeEnum } from "@/enums/paymentType"
import { PaymentStatusEnum } from "@/enums/paymentStatus"
import { paymentEditModal } from "@/resources/views/payments/edit"
import { Container as ModalContainer } from "react-modal-promise"
import { useChangeStatusOfPaymentMutation } from "@/connectors/fansbay/requests/paymentRequests"

export type SearchParameters = {
  user_id: number | string | undefined
  currency_id: number | string | undefined
  type: undefined | PaymentTypeEnum
  status: undefined | PaymentStatusEnum
}

export const PaymentList = () => {
  const { t } = useTranslation()
  const [searchParameters, setSearchParameters] = useState<SearchParameters>()
  const [updateStatus] = useChangeStatusOfPaymentMutation()
  const query = useMemo(() => {
    return baseQuery
      .where("user_id", searchParameters?.user_id)
      .where("currency_id", searchParameters?.currency_id)
      .where("status", searchParameters?.status)
      .where("type", searchParameters?.type)
  }, [searchParameters])

  const handleEdit = (id: string) => {
    paymentEditModal({
      id,
    }).then((props) => {
      updateStatus({
        id,
        ...props,
      })
        .unwrap()
        .then(() => t("payments.list.updated"))
    })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs} />
      <Card noPadding>
        <div
          className={
            "border-b border-gray-800 px-6 py-4 text-l font-medium text-neutral-200"
          }
        >
          <span>{t("payments.list.title")}</span>
        </div>
        <div
          className={"flex flex-col gap-y-4 border-b border-gray-800 px-6 py-4"}
        >
          <Search
            onUpdateParameters={(parameters) => setSearchParameters(parameters)}
          />
        </div>
        <Table handleEdit={handleEdit} query={query} />
      </Card>
      <ModalContainer />
    </div>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "payments.breadcrumbs.payments",
    url: "/payments",
  },
]
