import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  ExchangeRateInterface,
  GetExchangeRateResponseInterface,
  GetExchangeRatesResponseInterface,
  SaveExchangeRatePropsInterface,
} from "@/interfaces/exchangeRate"
import { SaveDataWithId } from "@/types/base"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getExchangeRates: builder.query<
      GetExchangeRatesResponseInterface["data"],
      string
    >({
      query: (query) => `/api/panel/exchange-rates${query}`,
      transformResponse: (response: GetExchangeRatesResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }) => ({ type: "ExchangeRate", id } as const),
              ),
              { type: "ExchangeRate", id: "LIST" },
            ]
          : [{ type: "ExchangeRate", id: "LIST" }],
    }),
    getExchangeRate: builder.query<ExchangeRateInterface, string>({
      query: (id) => `/api/panel/exchange-rates/${id}`,
      transformResponse: (response: GetExchangeRateResponseInterface) =>
        response.data,
      providesTags: (result, error, id) => [{ type: "ExchangeRate", id: id }],
    }),
    storeExchangeRate: builder.mutation<void, SaveExchangeRatePropsInterface>({
      query: (body) => ({
        url: "/api/panel/exchange-rates",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "ExchangeRate", id: "LIST" }],
    }),
    updateExchangeRate: builder.mutation<
      void,
      SaveDataWithId<SaveExchangeRatePropsInterface>
    >({
      query: ({ id, ...body }) => ({
        url: `/api/panel/exchange-rates/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "ExchangeRate", id: "LIST" },
        { type: "ExchangeRate", id },
      ],
    }),
    deleteExchangeRate: builder.mutation<void, string>({
      query: (id) => ({
        url: `/api/panel/exchange-rates/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "ExchangeRate", id: "LIST" }],
    }),
  }),
})

export const {
  useGetExchangeRatesQuery,
  useGetExchangeRateQuery,
  useStoreExchangeRateMutation,
  useUpdateExchangeRateMutation,
  useDeleteExchangeRateMutation,
} = requests
