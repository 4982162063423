import { useTranslation } from "react-i18next"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import { Button } from "@/resources/components/buttons/button"
import Card from "@/resources/components/cards/card"
import { BreadCrumbInterface } from "@/interfaces/base"
import Table from "@/resources/views/exchangeRates/parts/table"
import { exchangeRateCreateModal } from "@/resources/views/exchangeRates/create"
import { toast } from "react-toastify"
import { Fragment } from "react"
import { Container as ModalContainer } from "react-modal-promise"
import { exchangeRateEditModal } from "@/resources/views/exchangeRates/edit"

export const ExchangeRateList = () => {
  const { t } = useTranslation()

  const onRecordClick = (id: number) => {
    exchangeRateEditModal({
      id: id.toString(),
    }).then(() => {
      toast.success(t("exchange_rates.edit.success"))
    })
  }

  const onCreateClick = () => {
    exchangeRateCreateModal().then(() => {
      toast.success(t("exchange_rates.create.success"))
    })
  }

  return (
    <Fragment>
      <div className={"flex flex-col gap-y-8"}>
        <BreadCrumbs crumbs={breadcrumbs} />
        <div className={"flex flex-row-reverse"}>
          <Button onClick={onCreateClick} variant={"contained"}>
            {t("exchange_rates.list.create")}
          </Button>
        </div>
        <Card noPadding>
          <div
            className={
              "border-b border-gray-800 px-6 py-4 text-l font-medium text-neutral-200"
            }
          >
            <span>{t("exchange_rates.list.title")}</span>
          </div>
          <Table onRecordClick={onRecordClick} />
        </Card>
      </div>
      <ModalContainer />
    </Fragment>
  )
}

const breadcrumbs: BreadCrumbInterface[] = [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "exchange_rates.breadcrumbs.exchange_rates",
    url: "/exchange-rates",
  },
]
