import Card from "@/resources/components/cards/card"
import { useTranslation } from "react-i18next"
import Table from "@/resources/views/posts/comments/props/table"

type Props = {
  postId: string
}

export const PostCommentList = ({ postId }: Props) => {
  const { t } = useTranslation()

  return (
    <Card noPadding>
      <div
        className={
          "border-b border-gray-800 px-6 py-4 text-l font-medium text-neutral-200"
        }
      >
        <span>{t("posts.details.comments.title")}</span>
      </div>
      <Table postId={postId} />
    </Card>
  )
}
