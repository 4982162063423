import { useSearchMessageFiltersQuery } from "@/connectors/fansbay/requests/messageFilterRequests"
import Select, { Option } from "@/resources/components/inputs/select"
import { useTranslation } from "react-i18next"

type Props = {
  onChange: (value: string | undefined) => void
}

export const SearchByFilter = ({ onChange }: Props) => {
  const { data } = useSearchMessageFiltersQuery()
  const { t } = useTranslation()

  const handleChange = (value: string) => {
    onChange(value === "0" ? undefined : value)
  }

  return (
    <div>
      <Select
        label={t("labels.filter")}
        defaultValue={0}
        onChange={(_, value) => handleChange(value as string)}
      >
        <Option value={0}>{t("default_options.select_filter")}</Option>
        {data?.map((filter) => (
          <Option value={filter.id} key={filter.id}>
            {filter.name}
          </Option>
        ))}
      </Select>
    </div>
  )
}
