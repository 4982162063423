import { TFunction } from "i18next"
import * as yup from "yup"
import {
  SettingDataType,
  UpdateSettingPropsInterface,
} from "@/interfaces/setting"
import { SettingEnum } from "@/enums/setting"

export default class UpdateSettingValidation {
  static rules(
    t: TFunction,
  ): yup.ObjectSchema<UpdateSettingPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      key: yup
        .mixed<SettingEnum>()
        .required(t("validations.field_is_required")),
      data_type: yup
        .mixed<SettingDataType>()
        .required(t("validations.field_is_required")),
      value: yup.mixed().required(t("validations.field_is_required")),
    })
  }

  static defaultValues(): UpdateSettingPropsInterface {
    return {
      key: SettingEnum.USER_NEED_EMAIL_CONFIRMATION,
      data_type: "bool",
      value: false,
    }
  }
}
