import { useFormControlContext } from "@mui/base"

type Props = {
  message?: string | null
  error?: boolean
}

export const FormHelperText = (props: Props) => {
  const formControlContext = useFormControlContext()

  if (props.error || formControlContext?.error) {
    return (
      <div className={"py-1"}>
        <span className={"text-sm text-red-500 first-letter:capitalize"}>
          {props.message}
        </span>
      </div>
    )
  }

  return <></>
}
