import { TFunction } from "i18next"
import * as yup from "yup"
import { SaveRoleInterface } from "@/interfaces/role"

export default class SaveRoleValidation {
  static rules(
    t: TFunction,
  ): yup.ObjectSchema<SaveRoleInterface, yup.AnyObject> {
    return yup.object().shape({
      name: yup.string().required(t("validations.field_is_required")),
      permissions: yup
        .array()
        .of(yup.number().required())
        .required(t("validations.field_is_required")),
    })
  }

  static defaultValues(): SaveRoleInterface {
    return {
      name: "",
      permissions: [],
    }
  }
}
