import { TFunction } from "i18next"
import * as yup from "yup"
import { SaveTaxRatePropsInterface } from "@/interfaces/taxRate"

export default class SaveTaxRateValidation {
  static rules(
    t: TFunction,
  ): yup.ObjectSchema<SaveTaxRatePropsInterface, yup.AnyObject> {
    return yup.object().shape({
      name: yup.string().required(t("validations.field_is_required")),
      rate: yup
        .number()
        .typeError(t("validations.field_is_required"))
        .required(t("validations.field_is_required"))
        .min(1, t("validations.min_value", { value: 1 }))
        .max(100, t("validations.max_value", { value: 100 })),
      country_id: yup
        .number()
        .required(t("validations.field_is_required"))
        .notOneOf([0], t("validations.field_is_required")),
      active: yup.boolean().required(t("validations.field_is_required")),
    })
  }

  static defaultValues(): SaveTaxRatePropsInterface {
    return {
      name: "",
      rate: "",
      country_id: 0,
      active: true,
    }
  }
}
