import React from "react"
import { useTranslation } from "react-i18next"
import { useGetStatisticQuery } from "@/connectors/fansbay/requests/statisticRequests"
import { StatisticResourceEnum } from "@/enums/statisticResource"
import Carbon from "@/utils/carbon"
import { BasicStatistic } from "@/resources/views/dashboard/props/basicStatistic"

const Dashboard = (): React.ReactNode => {
  const { t } = useTranslation()
  const { data: byLastDay } = useGetStatisticQuery({
    from: new Carbon().subDays(1).format("yyyy-MM-dd"),
    to: new Carbon().format("yyyy-MM-dd"),
    resources: [
      StatisticResourceEnum.USERS,
      StatisticResourceEnum.POSTS,
      StatisticResourceEnum.SUBSCRIPTIONS,
      StatisticResourceEnum.PAYMENTS,
      StatisticResourceEnum.TOTAL_EARNED,
      StatisticResourceEnum.USER_VERIFICATIONS,
    ],
  })
  const { data: byLastMonth } = useGetStatisticQuery({
    from: new Carbon().subMonths(1).format("yyyy-MM-dd"),
    to: new Carbon().format("yyyy-MM-dd"),
    resources: [
      StatisticResourceEnum.USERS,
      StatisticResourceEnum.POSTS,
      StatisticResourceEnum.SUBSCRIPTIONS,
      StatisticResourceEnum.PAYMENTS,
      StatisticResourceEnum.TOTAL_EARNED,
      StatisticResourceEnum.USER_VERIFICATIONS,
    ],
  })
  const { data: byLastYear } = useGetStatisticQuery({
    from: new Carbon().subYears(1).format("yyyy-MM-dd"),
    to: new Carbon().format("yyyy-MM-dd"),
    resources: [
      StatisticResourceEnum.USERS,
      StatisticResourceEnum.POSTS,
      StatisticResourceEnum.SUBSCRIPTIONS,
      StatisticResourceEnum.PAYMENTS,
      StatisticResourceEnum.TOTAL_EARNED,
      StatisticResourceEnum.USER_VERIFICATIONS,
    ],
  })

  return (
    <div className={"grid w-full gap-8 lg:grid-cols-3"}>
      <BasicStatistic statistics={byLastDay} />
      <BasicStatistic statistics={byLastMonth} />
      <BasicStatistic statistics={byLastYear} />
    </div>
  )
}

export default Dashboard
