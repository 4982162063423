import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  ChangeStatusOfUserVerificationPropsInterface,
  GetUserVerificationResponseInterface,
  GetUserVerificationsResponseInterface,
  UserVerificationInterface,
} from "@/interfaces/userVerification"
import { SaveDataWithId } from "@/types/base"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getUserVerifications: builder.query<
      GetUserVerificationsResponseInterface["data"],
      string
    >({
      query: (query) => `/api/panel/user-verifications${query}`,
      transformResponse: (response: GetUserVerificationsResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }) => ({ type: "UserVerification", id } as const),
              ),
              {
                type: "UserVerification",
                id: "LIST",
              },
            ]
          : [
              {
                type: "UserVerification",
                id: "LIST",
              },
            ],
    }),
    getUserVerification: builder.query<UserVerificationInterface, string>({
      query: (id) => `/api/panel/user-verifications/${id}`,
      transformResponse: (response: GetUserVerificationResponseInterface) =>
        response.data,
      providesTags: (result, error, id) => [{ type: "UserVerification", id }],
    }),
    changeStatus: builder.mutation<
      void,
      SaveDataWithId<ChangeStatusOfUserVerificationPropsInterface>
    >({
      query: ({ id, ...body }) => ({
        method: "PUT",
        url: `/api/panel/user-verifications/${id}`,
        body,
      }),
      invalidatesTags: (response, error, { id }) => [
        {
          type: "UserVerification",
          id,
        },
        {
          type: "UserVerification",
          id: "LIST",
        },
      ],
    }),
  }),
})

export const {
  useGetUserVerificationQuery,
  useGetUserVerificationsQuery,
  useChangeStatusMutation,
} = requests
