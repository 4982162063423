import React, { useEffect, useState } from "react"
import BaseTable from "@/resources/components/table/table"
import { useTranslation } from "react-i18next"
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { TFunction } from "i18next"
import RowAction from "@/resources/components/table/rowAction"
import { ColumnSetting } from "@/resources/components/table/columnSetting"
import { SettingDataType, SettingInterface } from "@/interfaces/setting"
import { useGetSettingsQuery } from "@/connectors/fansbay/requests/settingRequests"

const columnHelper = createColumnHelper<SettingInterface>()

const columns = (t: TFunction) => [
  columnHelper.accessor("key", {
    id: "key",
    header: t("labels.key"),
    cell: (cell) => t(`settings.key.${cell.getValue()}`),
  }),
  columnHelper.accessor("value", {
    id: "value",
    header: t("labels.value"),
    cell: (cell) => (
      <div>{resolveValue(cell.getValue(), cell.row.original.data_type, t)}</div>
    ),
  }),
  columnHelper.display({
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => <RowAction url={`/settings/${row.original.id}/edit`} />,
  }),
]

const Table = () => {
  const { t } = useTranslation()
  const [data, setData] = useState<SettingInterface[]>([])
  const { data: apiData } = useGetSettingsQuery()

  useEffect(() => {
    if (apiData) {
      setData(apiData)
    }
  }, [apiData])

  const table = useReactTable({
    columns: columns(t),
    data: data,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div className={"flex flex-col"}>
      <div className={"flex flex-row-reverse px-8 py-4"}>
        <ColumnSetting table={table} />
      </div>
      <BaseTable table={table} />
    </div>
  )
}

const resolveValue = (value: any, dataType: SettingDataType, t: TFunction) => {
  switch (dataType) {
    case "bool":
      return value ? t("labels.yes") : t("labels.no")
    case "array":
    case "range":
      return JSON.stringify(value)
    default:
      return value
  }
}

export default Table
