import { create, InstanceProps } from "react-modal-promise"
import React, { Fragment, useEffect } from "react"
import Modal from "@/resources/components/modals/modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"
import { useValidation } from "@hypedevgroup/core"
import ChangeStatusValidation from "@/validations/userVerifications/changeStatusValidation"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormControl } from "@mui/base"
import Select, { Option } from "@/resources/components/inputs/select"
import { FormHelperText } from "@/resources/components/inputs/formHelperText"
import { Input } from "@/resources/components/inputs/input"
import { Button } from "@/resources/components/buttons/button"
import _ from "lodash"
import { ChangeStatusOfUserVerificationPropsInterface } from "@/interfaces/userVerification"
import { UserVerificationStatusEnum } from "@/enums/userVerificationStatus"

type Props = {
  status: UserVerificationStatusEnum
  note: string | null
}

const UserVerificationEdit: React.FC<InstanceProps<unknown> & Props> = (
  props,
) => {
  const { isOpen, onReject, onResolve, status, note } = props
  const { t } = useTranslation()
  const { schema, defaultValues } = useValidation(ChangeStatusValidation, t)

  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<ChangeStatusOfUserVerificationPropsInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    setValue("status", status)
    setValue("note", note)
  }, [setValue, status, note])

  const onSubmit = (data: ChangeStatusOfUserVerificationPropsInterface) => {
    onResolve(_.pickBy(data))
  }

  return (
    <Modal open={isOpen} onClose={onReject}>
      <Modal.Content>
        <Fragment>
          <div className={"flex w-full flex-row items-center justify-between"}>
            <span className={"text-lg font-semibold text-white"}>
              {t("user_verifications.edit.title")}
            </span>
            <FontAwesomeIcon
              onClick={onReject}
              icon={faTimes}
              className={"cursor-pointer text-white"}
            />
          </div>
          <div className={"flex flex-col gap-y-4 py-4"}>
            <Controller
              render={({ field }) => (
                <FormControl error={!!errors.status}>
                  <Select
                    fullWidth
                    label={t("labels.status")}
                    {...field}
                    onChange={(_, value) => field.onChange(value)}
                  >
                    <Option
                      disabled={[
                        UserVerificationStatusEnum.PROCESSING,
                        UserVerificationStatusEnum.APPROVED,
                        UserVerificationStatusEnum.DECLINED,
                      ].includes(field.value)}
                      value={UserVerificationStatusEnum.PENDING}
                    >
                      {t(
                        `user_verifications.status.${UserVerificationStatusEnum.PENDING}`,
                      )}
                    </Option>
                    <Option
                      disabled={[
                        UserVerificationStatusEnum.APPROVED,
                        UserVerificationStatusEnum.DECLINED,
                      ].includes(field.value)}
                      value={UserVerificationStatusEnum.PROCESSING}
                    >
                      {t(
                        `user_verifications.status.${UserVerificationStatusEnum.PROCESSING}`,
                      )}
                    </Option>
                    <Option
                      disabled={[
                        UserVerificationStatusEnum.PENDING,
                        UserVerificationStatusEnum.DECLINED,
                      ].includes(field.value)}
                      value={UserVerificationStatusEnum.APPROVED}
                    >
                      {t(
                        `user_verifications.status.${UserVerificationStatusEnum.APPROVED}`,
                      )}
                    </Option>
                    <Option
                      disabled={[UserVerificationStatusEnum.APPROVED].includes(
                        field.value,
                      )}
                      value={UserVerificationStatusEnum.DECLINED}
                    >
                      {t(
                        `user_verifications.status.${UserVerificationStatusEnum.DECLINED}`,
                      )}
                    </Option>
                  </Select>
                  <FormHelperText message={errors.status?.message} />
                </FormControl>
              )}
              name={"status"}
              control={control}
            />
            <div className={"text-s text-gray-500"}>
              <span>{t("user_verifications.edit.urgent")}</span>
              <ul className={"pt-4"}>
                <li className={"flex gap-x-2"}>
                  <span>
                    {t(
                      `user_verifications.status.${UserVerificationStatusEnum.PENDING}`,
                    )}
                  </span>
                  <span>{"->"}</span>
                  <span>
                    {t(
                      `user_verifications.status.${UserVerificationStatusEnum.PROCESSING}`,
                    )}
                  </span>
                </li>
                <li className={"flex gap-x-2"}>
                  <span>
                    {t(
                      `user_verifications.status.${UserVerificationStatusEnum.PENDING}`,
                    )}
                  </span>
                  <span>{"->"}</span>
                  <span>
                    {t(
                      `user_verifications.status.${UserVerificationStatusEnum.DECLINED}`,
                    )}
                  </span>
                </li>
                <li className={"flex gap-x-2"}>
                  <span>
                    {t(
                      `user_verifications.status.${UserVerificationStatusEnum.PROCESSING}`,
                    )}
                  </span>
                  <span>{"->"}</span>
                  <span>
                    {t(
                      `user_verifications.status.${UserVerificationStatusEnum.APPROVED}`,
                    )}
                  </span>
                </li>
                <li className={"flex gap-x-2"}>
                  <span>
                    {t(
                      `user_verifications.status.${UserVerificationStatusEnum.PROCESSING}`,
                    )}
                  </span>
                  <span>{"->"}</span>
                  <span>
                    {t(
                      `user_verifications.status.${UserVerificationStatusEnum.DECLINED}`,
                    )}
                  </span>
                </li>
              </ul>
            </div>
            <Controller
              render={({ field }) => (
                <FormControl {...field} error={!!errors.note}>
                  <Input
                    multiline
                    rows={5}
                    placeholder={t("placeholders.enter_message")}
                    label={t("labels.message")}
                  />
                  <FormHelperText message={errors.note?.message} />
                </FormControl>
              )}
              name={"note"}
              control={control}
            />
            <div className={"flex w-full pt-4"}>
              <Button
                variant={"contained"}
                className={"w-full bg-green-900"}
                onClick={handleSubmit(onSubmit)}
              >
                {t("buttons.save")}
              </Button>
            </div>
          </div>
        </Fragment>
      </Modal.Content>
    </Modal>
  )
}

export const userVerificationChangeStatus = create(UserVerificationEdit)
