import React, { RefAttributes } from "react"
import BaseAsyncSelect, { AsyncProps } from "react-select/async"
import { GroupBase } from "react-select"
import Select from "react-select/base"

function AsyncSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: AsyncProps<Option, IsMulti, Group> &
    RefAttributes<Select<Option, IsMulti, Group>>,
) {
  return (
    <BaseAsyncSelect
      classNames={{
        control: () =>
          "!border !border-gray-700 !bg-gray-800 !rounded-md !text-neutral-200",
        placeholder: () => "!text-neutral-200",
        dropdownIndicator: () => "!text-neutral-200 !bg-gray-800",
        menu: () => "!bg-gray-800 min-w-[200px]",
        input: () => "!text-neutral-200",
        indicatorSeparator: () => "hidden",
        singleValue: () => "!text-neutral-200",
        option: (state) =>
          state.isFocused ? "!bg-gray-700" : "!text-neutral-200",
        valueContainer: () => "!px-[14px] !py-1 !bg-gray-800 !text-neutral-200",
      }}
      {...props}
    />
  )
}

export default AsyncSelect
