import { TFunction } from "i18next"
import * as yup from "yup"
import { SaveDefaultPlanInterface } from "@/interfaces/defaultPlan"

export default class SaveDefaultPlanValidation {
  static rules(
    t: TFunction,
  ): yup.ObjectSchema<SaveDefaultPlanInterface, yup.AnyObject> {
    return yup.object().shape({
      duration: yup
        .number()
        .typeError(t("validations.field_is_required"))
        .required(t("validations.field_is_required"))
        .min(1, t("validations.min_value", { count: 1 })),
      currency_id: yup
        .number()
        .required(t("validations.field_is_required"))
        .notOneOf([0], t("validations.field_is_required")),
      price: yup
        .number()
        .typeError(t("validations.field_is_required"))
        .required(t("validations.field_is_required"))
        .min(1, t("validations.min_value", { count: 1 })),
    })
  }

  static defaultValues(): SaveDefaultPlanInterface {
    return {
      duration: "",
      currency_id: 0,
      price: "",
    }
  }
}
