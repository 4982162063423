import { createDraftSafeSelector, createSlice } from "@reduxjs/toolkit"
import { AuthStateInterface } from "@/interfaces/auth"
import { RootState } from "@/app/store"
import {
  loginRequest,
  logoutRequestMutation,
} from "@/connectors/fansbay/requests/authRequests"

const initialState: AuthStateInterface = {
  token: null,
  authorized: false,
  permissions: [],
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginRequest.fulfilled, (state, action) => {
      if (action.payload.data.user.permissions.length > 0) {
        state.authorized = true
        state.token = action.payload.data.token
        state.permissions = action.payload.data.user.permissions
        state.user = action.payload.data.user
      }
    })
    builder.addCase(logoutRequestMutation.pending, (state, action) => {
      state = initialState

      return state
    })
  },
})

export default authSlice.reducer

export const getIsAuthorized = createDraftSafeSelector(
  (state: RootState) => state.auth,
  (auth) => auth.authorized,
)

export const getPermissions = createDraftSafeSelector(
  (state: RootState) => state.auth,
  (auth) => auth.permissions,
)
