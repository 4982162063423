import { TFunction } from "i18next"
import * as yup from "yup"
import { ChangeStatusOfPaymentPropsInterface } from "@/interfaces/payment"
import { PaymentStatusEnum } from "@/enums/paymentStatus"

export default class ChangeStatusValidation {
  static rules(
    t: TFunction,
  ): yup.ObjectSchema<ChangeStatusOfPaymentPropsInterface, yup.AnyObject> {
    return yup.object().shape({
      status: yup
        .mixed<PaymentStatusEnum>()
        .required(t("validations.field_is_required")),
    })
  }

  static defaultValues(): ChangeStatusOfPaymentPropsInterface {
    return {
      status: PaymentStatusEnum.PENDING,
    }
  }
}
