import fansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  GetIssueResponseInterface,
  GetIssuesResponseInterface,
  IssueInterface,
  UpdateIssuePropsInterface,
} from "@/interfaces/issue"
import { SaveDataWithId } from "@/types/base"

const requests = fansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getIssues: builder.query<GetIssuesResponseInterface["data"], string>({
      query: (query) => `/api/panel/issues${query}`,
      transformResponse: (response: GetIssuesResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: "Issue", id } as const)),
              { type: "Issue", id: "LIST" },
            ]
          : [{ type: "Issue", id: "LIST" }],
    }),
    getIssue: builder.query<IssueInterface, string>({
      query: (id) => `/api/panel/issues/${id}`,
      transformResponse: (response: GetIssueResponseInterface) => response.data,
      providesTags: (response, error, id) => [{ type: "Issue", id }],
    }),
    updateIssue: builder.mutation<
      void,
      SaveDataWithId<UpdateIssuePropsInterface>
    >({
      query: ({ id, ...body }) => ({
        method: "PUT",
        url: `/api/panel/issues/${id}`,
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Issue", id },
        { type: "Issue", id: "LIST" },
      ],
    }),
    deleteIssue: builder.mutation<void, string>({
      query: (id) => ({
        url: `/api/panel/issues/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Issue", id: "LIST" }],
    }),
  }),
})

export const {
  useGetIssuesQuery,
  useGetIssueQuery,
  useUpdateIssueMutation,
  useDeleteIssueMutation,
} = requests
