import { useAppSelector } from "@/app/hooks"
import { getPermissions } from "@/states/authState"
import { PermissionEnum } from "@/enums/permission"

export const useHasPermission = () => {
  const permissions = useAppSelector(getPermissions)

  return (permission: PermissionEnum): boolean => {
    return permissions.includes(permission)
  }
}
