import { useNavigate, useParams } from "react-router-dom"
import {
  useGetSubscriptionQuery,
  useUpdateSubscriptionMutation,
} from "@/connectors/fansbay/requests/subscriptionRequests"
import { useAssignAttributes, useValidation } from "@hypedevgroup/core"
import UpdateSubscriptionValidation from "@/validations/subscriptions/updateSubscriptionValidation"
import { useTranslation } from "react-i18next"
import { Controller, useForm } from "react-hook-form"
import { UpdateSubscriptionPropsInterface } from "@/interfaces/subscription"
import { yupResolver } from "@hookform/resolvers/yup"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import React from "react"
import Card from "@/resources/components/cards/card"
import { BreadCrumbInterface } from "@/interfaces/base"
import { FormControl } from "@mui/base"
import { Input } from "@/resources/components/inputs/input"
import { FormHelperText } from "@/resources/components/inputs/formHelperText"
import Select, { Option } from "@/resources/components/inputs/select"
import { SubscriptionStatusEnum } from "@/enums/subscriptionStatus"
import { Button } from "@/resources/components/buttons/button"
import { toast } from "react-toastify"

export const SubscriptionEdit = () => {
  const { id } = useParams<{ id: string }>()
  const { data } = useGetSubscriptionQuery(id!)
  const { t } = useTranslation()
  const { schema, defaultValues } = useValidation(
    UpdateSubscriptionValidation,
    t,
  )
  const [updateSubscription] = useUpdateSubscriptionMutation()
  const navigate = useNavigate()

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<UpdateSubscriptionPropsInterface>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  useAssignAttributes(setValue, data)

  const onSubmit = (data: UpdateSubscriptionPropsInterface) => {
    updateSubscription({
      ...data,
      id: id!,
    })
      .unwrap()
      .then(() => {
        toast.success(t("subscriptions.edit.success"))
        navigate("/subscriptions")
      })
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      <div className={"mb-8 flex flex-col gap-8"}>
        <Card className={"flex w-full"}>
          <div className={"flex w-full flex-col gap-16 md:flex-row"}>
            <div className={"md:w-[150px]"}>
              <span>{t("forms.basic_data")}</span>
            </div>
            <div className={"w-full md:w-[400px]"}>
              <div className={"flex flex-col gap-y-8"}>
                <Controller
                  render={({ field }) => (
                    <FormControl {...field} error={!!errors.duration}>
                      <Input
                        type={"number"}
                        placeholder={t("placeholders.duration")}
                        label={t("labels.duration")}
                      />
                      <FormHelperText message={errors.duration?.message} />
                    </FormControl>
                  )}
                  name={"duration"}
                  control={control}
                />
                <Controller
                  render={({ field }) => (
                    <FormControl
                      {...field}
                      error={!!errors.current_period_start}
                    >
                      <Input
                        type={"date"}
                        label={t("labels.current_period_start")}
                      />
                      <FormHelperText
                        message={errors.current_period_start?.message}
                      />
                    </FormControl>
                  )}
                  control={control}
                  name={"current_period_start"}
                />
                <Controller
                  render={({ field }) => (
                    <FormControl {...field} error={!!errors.current_period_end}>
                      <Input
                        type={"date"}
                        label={t("labels.current_period_end")}
                      />
                      <FormHelperText
                        message={errors.current_period_end?.message}
                      />
                    </FormControl>
                  )}
                  control={control}
                  name={"current_period_end"}
                />
                <Controller
                  render={({ field }) => (
                    <FormControl error={!!errors.status}>
                      <Select
                        label={t("labels.status")}
                        fullWidth
                        {...field}
                        onChange={(_, value) => field.onChange(value)}
                      >
                        {Object.values(SubscriptionStatusEnum).map(
                          (status, index) => (
                            <Option key={index} value={status}>
                              {t(`subscriptions.statuses.${status}`)}
                            </Option>
                          ),
                        )}
                      </Select>
                      <FormHelperText message={errors.status?.message} />
                    </FormControl>
                  )}
                  control={control}
                  name={"status"}
                />
              </div>
            </div>
          </div>
        </Card>
        <div>
          <Button variant={"contained"} onClick={handleSubmit(onSubmit)}>
            {t("buttons.save")}
          </Button>
        </div>
      </div>
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "modules.breadcrumbs.payment",
    url: "#",
  },
  {
    title: "subscriptions.breadcrumbs.subscriptions",
    url: "/subscriptions",
  },
  {
    title: "subscriptions.breadcrumbs.edit",
    url: `/subscriptions/${id}/edit`,
  },
]
