import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { BreadCrumbInterface } from "@/interfaces/base"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import React from "react"
import { Tab, TabPanel, Tabs, TabsList } from "@mui/base"
import { Details } from "@/resources/views/users/details/details"
import { Wallets } from "@/resources/views/users/details/wallets"
import { useHasPermission } from "@/utils/hooks/useHasPermission"
import { ButtonLink } from "@/resources/components/buttons/button"
import { PermissionEnum } from "@/enums/permission"

export const UserDetails = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const hasPermission = useHasPermission()

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      <div className={"flex flex-row-reverse gap-x-2"}>
        {hasPermission(PermissionEnum.USERS_STORE) && (
          <ButtonLink variant={"contained"} to={`/users/${id!}/edit`}>
            {t("users.details.edit")}
          </ButtonLink>
        )}
      </div>
      <Tabs
        className={"w-full rounded-md border border-gray-700 bg-gray-900 p-4"}
        defaultValue={"details"}
      >
        <TabsList className={"flex flex-row gap-x-4 text-neutral-200"}>
          <Tab
            autoFocus={false}
            slotProps={{
              root: (props) => ({
                className: props.selected ? "bg-primary-600" : "bg-gray-800",
              }),
            }}
            className={"rounded-md px-6 py-2"}
            value={"details"}
          >
            {t("labels.details")}
          </Tab>
          <Tab
            autoFocus={false}
            slotProps={{
              root: (props) => ({
                className: props.selected ? "bg-primary-600" : "bg-gray-800",
              }),
            }}
            className={"rounded-md px-6 py-2"}
            value={"wallet"}
          >
            {t("labels.wallet")}
          </Tab>
        </TabsList>
        <TabPanel value={"details"}>
          <Details />
        </TabPanel>
        <TabPanel value={"wallet"}>
          <Wallets />
        </TabPanel>
      </Tabs>
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "users.breadcrumbs.module",
    url: "#",
  },
  {
    title: "users.breadcrumbs.users",
    url: "/users",
  },
  {
    title: "users.breadcrumbs.details",
    url: `/users/${id}`,
  },
]
