import React, { ForwardedRef, forwardRef, HTMLProps } from "react"
import classNames from "classnames"

type Props = {
  color: string
  textColor: string
} & HTMLProps<HTMLSpanElement>

export const Badge = forwardRef(
  (props: Props, ref: ForwardedRef<HTMLSpanElement>) => {
    const { color, textColor, children, className, ...rest } = props

    const classes = classNames(
      "text-white px-[10px] py-[2px] rounded-xl text-xs font-medium",
      textColor,
      className,
      color,
    )

    return (
      <span ref={ref} {...rest} className={classes}>
        {children}
      </span>
    )
  },
)
