import { StatisticInterface } from "@/interfaces/statistic"
import { useTranslation } from "react-i18next"
import Card from "@/resources/components/cards/card"
import { StatisticResourceEnum } from "@/enums/statisticResource"
import React, { Fragment } from "react"
import { toIntl } from "@/utils/toIntl"

type Props = {
  statistics?: StatisticInterface
}

export const BasicStatistic: React.FC<Props> = ({ statistics }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  return (
    <Card>
      <div className={"flex flex-col"}>
        <span className={"font-semibold"}>
          {statistics?.from} - {statistics?.to}
        </span>
        <div className={"flex flex-col gap-y-2 py-4"}>
          {statistics?.stats.map((item, index) => (
            <div
              key={index}
              className={"flex flex-col gap-y-2 whitespace-nowrap"}
            >
              {item.resource === StatisticResourceEnum.SUBSCRIPTIONS ? (
                <Fragment>
                  <span>
                    {t(`statistics.resources.subscription_count`)}:{" "}
                    {item.subscription_count}
                  </span>
                  <span>
                    {t(`statistics.resources.subscription_amount`)}:{" "}
                    {toIntl(item.subscription_amount ?? 0, "PLN", language)}
                  </span>
                </Fragment>
              ) : (
                <span>
                  {t(`statistics.resources.${item.resource}`)}: {item.total}
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </Card>
  )
}
